import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form"
import { Form, InputGroup, Button } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { API_BASE_URL, RECAPTCHA_SITE_KEY } from "@utils/constant";
import axios from "axios";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import { RotatingLines } from 'react-loader-spinner'
import ReCAPTCHA from "react-google-recaptcha";
import { triggerConversion } from "@utils/helper";
import Cookies from "js-cookie";

const ThankYou: React.FC<any> = (props) => {
    const { name } = props;

    return (
        <div className="thank-you thank-you-shopify">
            <img src="/assets/images/thank-you.svg" alt="Thank you" />
            <h1>Thank You</h1>
            <p>Hi <strong>{name},</strong></p>
            <p>Thank you for contacting Gokwik.</p>
            <p>We have registered your interest and will get back to you within 72 hours.</p>
        </div>
    );
}

const ShopifyForm: React.FC<any> = (props) => {
    const { data, params } = props;
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, setValue, getValues } = useForm({});
    const recaptchaRef = React.useRef<ReCAPTCHA>();

    const [name, setName] = useState("");


    const [validated, setValidated] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errors, setErrors] = useState<any>({});
    const [formData, setFormData] = useState(null);

    // Analytics
    useEffect(() => {
        const utmSource = new URL(window.location.href).searchParams.get('utm_source');
        if (utmSource && utmSource.trim() !== '') {
            Cookies.set('gokwik_utm_source', utmSource.toLowerCase(), {
                expires: 30,
            });
        }
    }, []);

    const handleRecaptchaChange = (value) => {
        formData.g_recaptcha_token = value;
        axios.post(API_BASE_URL + 'form/shopify', formData)
            .then(function (response) {
                if (response.data.status === "ok") {
                    triggerConversion();
                }
                setLoading(false);
                //console.log(response);
                setErrors({});
                setName(formData?.name);
                setSuccessMessage("submitted");
            })
            .catch(function (error) {
                setLoading(false);
                //setSuccessMessage("yes");
                console.log(error.response.data?.errors['product[]']);
                setErrors(error?.response?.data?.errors);

            });
    };

    const handleRecaptchaSubmit = async () => {
        // Trigger the reCAPTCHA challenge
        recaptchaRef.current.execute();
    };

    const onSubmit: SubmitHandler<any> = async (data) => {
        setLoading(true);
        setSuccessMessage("");

        setFormData(data);
        handleRecaptchaSubmit();

        // data.source = params.get("utm_source")
        // data.medium = params.get("utm_medium")
        // data.campaign = params.get("utm_campaign")
        // data.g_recaptcha_token = recaptchaRef.current.getValue();


        /*axios.post(API_BASE_URL + 'form/shopify', data)
            .then(function (response) {
                if(response.data.status === "ok" && process.env.REACT_APP_ENVIRONMENT === "prod") {
                    triggerConversion();
                }
                setLoading(false);
                //console.log(response);
                setErrors({});
                setName(data?.name);
                setSuccessMessage("submitted");
            })
            .catch(function (error) {
                setLoading(false);
                //setSuccessMessage("yes");
                console.log(error.response.data?.errors['product[]']);
                setErrors(error?.response?.data?.errors);

            });*/

    }

    return (

        <div className="form" id="contact">

            <div className="form-wrapper shopiform-form">
                {successMessage &&
                    <Fade direction="right">
                        <ThankYou name={name} />
                    </Fade>
                }
                {!successMessage &&
                    <Fade direction="right">
                        <div className="col-12 section-header">
                            <h3>Let's <span>Connect</span></h3>
                            <br />
                        </div>
                        <Form onSubmit={handleSubmit(onSubmit)} noValidate validated={validated} id="shopify-contact-form" >
                            <div className="col-12 mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Your Brand URL*"
                                >
                                    <Form.Control
                                        {...register("brand_url")}
                                        className={errors.brand_url ? 'is-invalid' : ''}
                                        type="text"
                                        placeholder="Your Brand URL*"
                                    />

                                    {errors?.brand_url &&
                                        <Form.Control.Feedback type="invalid">{errors?.brand_url[0]}</Form.Control.Feedback>
                                    }
                                </FloatingLabel>
                            </div>

                            <div className="col-12 mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Your Name*"
                                >
                                    <Form.Control
                                        {...register("name")}
                                        className={errors.name ? 'is-invalid' : ''}
                                        type="text"
                                        placeholder="Your Name*"
                                    />
                                    {errors?.name &&
                                        <Form.Control.Feedback type="invalid">{errors?.name[0]}</Form.Control.Feedback>
                                    }
                                </FloatingLabel>

                            </div>

                            <div className="col-12 mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Your Contact Number*"
                                >
                                    <Form.Control
                                        {...register("mobile")}
                                        className={errors.mobile ? 'is-invalid' : ''}
                                        type="text"
                                        maxLength={10}
                                        onKeyUp={(e) => setValue("mobile", getValues('mobile').replace(/[^0-9]/g, ''))}
                                        placeholder="Your Contact Number*"
                                    />

                                    {errors?.mobile &&
                                        <Form.Control.Feedback type="invalid">{errors?.mobile[0]}</Form.Control.Feedback>
                                    }
                                </FloatingLabel>

                            </div>

                            <div className="col-12 mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Your Official Email Id"
                                >
                                    <Form.Control
                                        {...register("email")}
                                        className={errors.email ? 'is-invalid' : ''}
                                        type="text"
                                        placeholder="Your Official Email Id"
                                    />

                                    {errors?.email &&
                                        <Form.Control.Feedback type="invalid">{errors?.email[0]}</Form.Control.Feedback>
                                    }
                                </FloatingLabel>

                            </div>

                            <div className="col-12 mb-3 custom-box">
                                <div className="col-12 mb-3">
                                    <p>Monthly Gross Merchandise Value (GMV) in INR*</p>
                                </div>
                                <div className="col-12 mb-3 check-box-row">
                                    <Form.Check // prettier-ignore
                                        {...register("amount")}
                                        value="1000000"
                                        type="radio"
                                        id="default-checkbox"
                                        label="0-10L"
                                    />
                                    {/* <i class="fa-solid fa-info"></i> */}
                                    {/* <span><span>Fastest and most intelligent checkout solution</span></span> */}
                                </div>
                                <div className="col-12 mb-3 check-box-row">
                                    <Form.Check // prettier-ignore
                                        {...register("amount")}
                                        value="2500000"
                                        type="radio"
                                        id="default-checkbox"
                                        label="10L-25L"
                                    />
                                    {/* <span><span>End-to-end Whatsapp Commerce solution</span></span> */}
                                </div>
                                <div className="col-12 mb-3 check-box-row">
                                    <Form.Check // prettier-ignore
                                        {...register("amount")}
                                        value="10000000"
                                        type="radio"
                                        id="default-checkbox"
                                        label="25L-1CR"
                                    />
                                    {/* <span><span>Simplest Debt Financing Option for Growth</span></span> */}
                                </div>
                                <div className="col-12 mb-3 check-box-row">
                                    <Form.Check // prettier-ignore
                                        {...register("amount")}
                                        value="50000000"
                                        type="radio"
                                        id="default-checkbox"
                                        label="1CR and Above"
                                    />
                                    {/* <span><span>Simplest Debt Financing Option for Growth</span></span> */}
                                </div>

                                {errors?.amount &&
                                    <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>{errors.amount}</Form.Control.Feedback>
                                }
                            </div>

                            <ReCAPTCHA
                                ref={recaptchaRef as React.RefObject<ReCAPTCHA>}
                                sitekey={RECAPTCHA_SITE_KEY}
                                size="invisible"
                                onChange={handleRecaptchaChange}
                            />

                            <button type="submit" className="btn btn-submit" disabled={loading}>
                                Submit
                                <RotatingLines strokeColor="white" strokeWidth="5" animationDuration="0.75" width="18" visible={loading}
                                />
                            </button>




                        </Form>
                    </Fade>
                }
            </div>

        </div>
    );
}
export default ShopifyForm;