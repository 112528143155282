import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { scrollinit } from "@utils/helper";
import parse from 'html-react-parser';
import { renderToString } from "react-dom/server";



const SectionDetails: React.FC<any> = (props)=> {
    const {data} = props;
    
   
   
    return (
        <>
            <div className="blog-section-details" style={{marginBottom:"-40px"}}>
                <div className="container-fluid">
                    <div className="col-12 blog-content" style={{paddingTop:"10px"}}>
                        
                        <h1 dangerouslySetInnerHTML={{__html:data?.title}}></h1>
                    
                       <div dangerouslySetInnerHTML={{__html:data?.content}}></div>
                       
                      
                        
                    </div>
                </div>
            </div>
        </>
    )
}
export default SectionDetails