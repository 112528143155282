import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";


const SectionMerchantExp: React.FC<any> = ({ group, blocks, scrollToForm }) => {
    return (
        <>
            <div className="product-section-merchant">
                <div className="container-fluid">

                    <div className="col-12 section-header">
                        <Fade direction="down">
                            <h3 dangerouslySetInnerHTML={{ __html: group?.title }}></h3>
                            <p>{group?.description}</p>
                        </Fade>
                    </div>


                    <div className="row row-cols-1 row-cols-md-4 row-cols-lg-4 mx-4 items">
                        <Fade cascade damping={0.2}>

                            {blocks?.map((row: any, index) => (
                                <div className="col">
                                    <div className="item">
                                        <div className="img-wrapper">
                                            <img src={row.file} alt={row.title} style={{ marginTop: index === 0 ? `25px` : 'auto' }}/>
                                        </div>
                                        <div className="rightbox">
                                            <Fade cascade damping={0.3}>
                                                <h4>{row.title}</h4>
                                                <p>{row.content}</p>
                                            </Fade>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </Fade>
                    </div>
                    <div className="merc-exp">
                        <Link to="#" onClick={scrollToForm}>
                            <button className="button-cta merchant-exp-btn">I Am Interested</button>
                        </Link>
                    </div>


                </div>
            </div>
        </>
    )
}
export default SectionMerchantExp