import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Fade, Zoom } from "react-awesome-reveal";
import Section from "./section";
import $ from 'jquery';

const SectionFeatures: React.FC<any> = (props) => {
    const { group, blocks } = props;

    return (
        <>
            <div className={"product-features "} id="product-features">


                <div className="container-fluid">

                    <div className="col-12 section-header">
                        <Fade direction="down">
                            <h3 dangerouslySetInnerHTML={{ __html: group?.title }} style={{ textAlign: "center" }}></h3>
                            <p>Elevating customer journey with seamless checkout experience</p>
                        </Fade>
                    </div>

                    <div className="col-12 wrapper flex">

                        <div className="col-12 col-md-6">
                            <Section data={blocks}></Section>
                        </div>

                        <div className="col-12 col-md-6" id="screens">
                            <div className={"img-wrapper"}>
                                <div className="circle">
                                    <div className="inner-circle"></div>
                                </div>
                                <img src="/assets/images/product/kwikcheckout/frame.svg" alt={"Frame"} />

                                {blocks?.map((row: any, index) => (
                                    <div className={(index === 0) ? "item-frame img-frame active" : "item-frame img-frame"} id={"item" + index + "-frame"}>
                                        <img src={row.file} alt={row.title} />
                                    </div>
                                ))}
                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}
export default SectionFeatures