import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import BlurryLoadingImage from "@components/lazyimage";


const SectionOne: React.FC<any> = (props)=> {
    const {partners} = props;


    return (
        <>
            <div className="partners-section-one bg-light">
                <div className="container-fluid">
                   
                        <div className="col-12 section-header">
                            <Fade direction="down">
                                <h3>eCommerce <span>Integrations</span></h3>
                                <p>Simplify your eCommerce operations with our powerful 50+ integrations across 10+ categories.</p>
                            </Fade>
                        </div>
                    

                    {partners?.map((partner: any,index) => (
                    <div className={(index === 0)?"section bg-dark":"section bg-dark secound"} >
                        
                            <div className="col-12 sub-section-header">
                                    <Fade direction="down">
                                        <h4>{partner.category}</h4>
                                    </Fade>
                            </div>
                       
                            <div className="col-12 items">
                                {partner?.data?.map((row: any,index1) => (
                                <div className="item">
                                    <Zoom>
                                    {partner.category == 'Financing Partners' && (
                                    
                                        <a href="https://www.trillionloans.com/policies-codes.html" target="_blank">
                                            
                                        <BlurryLoadingImage preview={row?.file} image={row?.file} alt={row?.title}></BlurryLoadingImage>
                                        </a>
                                    )}
                                    {partner.category != 'Financing Partners' && (
                                        <BlurryLoadingImage preview={row?.file} image={row?.file} alt={row?.title}></BlurryLoadingImage>
                                    )}
                                    </Zoom>
                                </div>
                                ))}
                            </div>

                            <div className="and-more">And More</div>
                           
                    </div>
                    ))}


                </div>
            </div>
        </>
    )
}
export default SectionOne