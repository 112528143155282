import React, { ChangeEvent, useEffect, useState } from "react";
import parse from 'html-react-parser';
import ResponsiveYoutube from "../youtube";
import { API_BASE_URL } from "@utils/constant";
import axios from "axios";

const replace = (domNode) => {
    // console.log("don : ",domNode)
    if (domNode.name === "youtube") {
        return (
            <ResponsiveYoutube id={domNode.attributes[0]?.value}></ResponsiveYoutube>
        );
    }
};

const SectionDetails: React.FC<any> = (props) => {
    const { data } = props;
    const [errors, setErrors] = useState<any>({});
    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [shopOtpSection, toggleOTPSection] = useState(false);
    const [showDownloadPdf, toggleDownloadPDF] = useState(false);
    const [otp, setOTP] = useState(null);
    const [email, setEmail] = useState(null);

    let content;

    if (data?.content != undefined) {
        const reg = /\[youtube id=.*\]/
        const temp = data.content.match(reg);
        if (temp != null) {
            const new_youtube = temp[0].replace("[", "<").replace("]", ">");
            data.content = data.content.replace(temp[0], new_youtube);
        }

        content = parse(data.content, { replace })
    }

    const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setFormData(prevState => {
            return { ...prevState, [name]: value }
        })
    }

    const handleChangeOTP = (event: ChangeEvent<HTMLInputElement>) => {
        setOTP(event.target.value);
    }

    const submitHandler = () => {
        axios.post(API_BASE_URL + 'playbook/subscribe', { ...formData, playbook_id: data.id })
            .then(function (response) {
                setEmail(formData.email);
                setLoading(false);
                setErrors({});
                // toggleOTPSection(true);
                toggleDownloadPDF(true);
                setFormData(null)
            })
            .catch(function (error) {
                setLoading(false);
                setErrors(error?.response?.data?.errors);

            });
    }

    const verifyOTP = () => {
        axios.post(API_BASE_URL + 'playbook/subscribe/verifyotp', { otp, playbook_id: data.id, email })

            .then(function (response) {
                setLoading(false);
                setErrors({});
                toggleOTPSection(false);
                toggleDownloadPDF(true);
                setOTP(null)
            })
            .catch(function (error) {
                setLoading(false);
                setErrors(error?.response?.data?.errors);

            });
    }

    const handleDownload = () => {
        axios.post(API_BASE_URL + 'playbook/attachment', { playbook_id: data.id, email }, {
            responseType: 'blob'
        })

            .then(function (response) {
                setLoading(false);
                toggleDownloadPDF(false);

                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', data.attachment); // Set the desired file name
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(function (error) {
                setLoading(false);
            });
    }

    return (
        <>

            <div className="blog-section-details">
                <div className="container-fluid">
                    <div className="col-12 blog-content playbook-content ">
                        <div className="playbook">
                            <div className="col-12 col-sm-5 playbook-left">
                                {data?.thumbnail && <img className="featured-image" src={data?.thumbnail} alt={data?.alt} /> }
                            </div>
                            <div className="col-12 col-sm-7 playbook-right">
                                <h1 dangerouslySetInnerHTML={{ __html: data?.title }}></h1>
                                {content && content}
                                {/* <p>
                                    In the fast-growing world of Indian eCommerce market, managing return to origin (RTO) rates is crucial for maintaining business profitability and customer satisfaction. GoKwik’s data-driven approach built in its RTO Protection Suite revolutionises how online retails brands can tackle this challenge, especially in today’s time.
                                </p> */}

                            </div>
                        </div>
                        <div className="form-item">
                            {
                                showDownloadPdf ?
                                    <div className="verification-code-inputs">
                                        <button className="btn pri" type="button" onClick={handleDownload} data-lytics="click" data-lytics-category="Website - Landing" data-lytics-action="Click - Download Ebook PDF" data-id="5" id="download-ebook" disabled={loading}>Download</button>
                                        {/* <button onClick={handleDownload}>Download</button> */}
                                    </div>
                                    // <a onClick={handleDownload} href={data?.attachment} target="_blank" download rel="noreferrer">Download</a>
                                    :
                                    shopOtpSection ?
                                        <div className="verification-code-inputs" id="inputs">
                                            {/* <h3>Enter OTP</h3> */}
                                            <input type="text" value={otp} onChange={handleChangeOTP} name="otp" className={errors?.otp && 'field-error'} maxLength={6} required />
                                            {/* <input type="text" name="otp1" maxLength={1} required />
                                                    <input type="text" name="otp2" maxLength={1} required />
                                                    <input type="text" name="otp3" maxLength={1} required />
                                                    <input type="text" name="otp4" maxLength={1} required />
                                                    <input type="text" name="otp5" maxLength={1} required />
                                                    <input type="text" name="otp6" maxLength={1} required /> */}
                                            <button className="btn pri" type="button" data-lytics="click" data-lytics-category="Website - Landing" data-lytics-action="Click - Download Ebook PDF" data-id="5" id="download-ebook" onClick={verifyOTP} disabled={loading}>Verify OTP</button>
                                        </div>
                                        :
                                        <form>
                                            <div className="detail-page-form">
                                                <input type="text" value={formData?.name} onChange={inputChangeHandler} className={errors?.name && 'field-error'} placeholder="Full Name" name="name" id="name" required />

                                                <input type="email" value={formData?.email} onChange={inputChangeHandler} className={errors?.email && 'field-error'} placeholder="Email" name="email" id="email" required />

                                                <input type="text" value={formData?.website} onChange={inputChangeHandler} className={errors?.website && 'field-error'} placeholder="Website address" name="website" id="website" required />
                                                <button type="button" className="btn pri" data-lytics="click" data-lytics-category="Website - Landing" data-lytics-action="Click - Download Ebook PDF" data-id="5" onClick={submitHandler} disabled={loading}>Get Your Copy</button>
                                            </div>
                                        </form>
                            }
                        </div>
                    </div>
                </div>
            </div>




        </>
    )
}
export default SectionDetails