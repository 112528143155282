import React, { useEffect, useRef, useState } from 'react'
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import GrowthForm from "./growth-form";
const SectionThree: React.FC<any> = () => {
    return (
        <>
            <div className="one-kcheckout-client">
                <div className="container-fluid">
                    
                        <div className="row">
                            <div className="col-md-12 client_box">
                                <div className="heading">
                                    <h2>One-Click Checkout Trusted By India’s Best-Selling eCommerce Brands</h2>
                                </div>
                                <div className="grid">
                                    
                                        <div className="">
                                            <img src="/assets/images/one-click-kwikcheckout/1.png"/>
                                        </div>
                                    
                                    
                                        <div className="">
                                            <img src="/assets/images/one-click-kwikcheckout/2.png"/>
                                        </div>
                                    
                                        <div className="">
                                            <img src="/assets/images/one-click-kwikcheckout/3.png"/>
                                        </div>
                                    
                                        <div className="">
                                            <img src="/assets/images/one-click-kwikcheckout/4.png"/>
                                        </div>
                                    
                                        <div className="">
                                            <img src="/assets/images/one-click-kwikcheckout/5.svg"/>
                                        </div>
                                    
                                        <div className="">
                                            <img src="/assets/images/one-click-kwikcheckout/6.png"/>
                                        </div>
                                   
                                    
                                   
                                </div>
                            </div>
                        </div>
                    
                </div>
            </div>
        </>
    )
}
export default SectionThree