import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { scrollinit } from "@utils/helper";
import { API_BASE_URL } from "@utils/constant";
import ReactPaginate from "react-paginate";
import BlurryLoadingImage, { MyLoader } from "@components/lazyimage";

const SectionOne: React.FC<any> = (props)=> {
    const {categories, toggleSearch, searchTxt} = props;
    const [result, setResult] = useState<any>({})
    const [query, setQuery] = useState("")
    const [categoryId, setCategoryId] = useState(0)
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1);

    useEffect(() => {
        if(searchTxt) {
            fetchData(categoryId,1,searchTxt);
        }
    },[toggleSearch])

    function handleSubmit(e) {
        e.preventDefault();
        //scrollResultTab();
        fetchData(categoryId,1,query);
    }
    function scrollResultTab(){
        var myElement = document.getElementById('result-tabs');
        var topPos = myElement.offsetTop;
        window.scrollTo(0, topPos);
    }
    
    function handleQuery(e) {
        const q = e.target.value;
        setQuery(q);
        if(q.length > 2){
            fetchData(categoryId,1,q);
        }
    }
    function handleTabChange(e) {
        setCategoryId(e);
        fetchData(e,1);
    }
    function handlePageClick(e){
        const p = (e.selected+1);
        setPage(p);
        scrollResultTab();
        fetchData(categoryId,p);
    }
    async function fetchData(cat=0,p=1,q="") {
        setResult({});
        let uri = API_BASE_URL+'playbook/'+cat+"/"+p
        if(q!=""){
            const query = new URLSearchParams({q:q}).toString();
            uri= uri+"?"+query
        }

		fetch(uri).then(
			function(response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function(data) {
					setResult(data);
                    setTotalPage(Math.ceil((data?.total)/6));;
				});
			}
		)
		.catch(function(err) {
			console.log('Fetch Error :-S', err);
		});
	}

    useEffect(()=>{
        fetchData();
    },[])

    useEffect(()=>{
        scrollinit();
    },[categories])


    return (
        <>
            <div className="blog-section-one bg-light">
                <div className="container-fluid">

                    <div className="col-12 section-header">
                        <Fade direction="down">
                            <h3>Read More <span>Top Playbooks</span></h3>
                        </Fade>
                    </div>

                    <div className="col-12 tab-one" id="result-tabs" style={{padding:0}}>

                        <div className="paddles">
                            <div className="left-paddle paddle hidden">
                                <i className="fa-solid fa-arrow-left"></i>
                            </div>
                            <div className="right-paddle paddle hidden">
                                <i className="fa-solid fa-arrow-right"></i>
                            </div>

                            <div className="tab-search" id="tab-search">
                                <form onSubmit={handleSubmit}>
                                    <i className="fas fa-search"></i>
                                    <input type="text" onChange={handleQuery}/>
                                </form>
                            </div>
                        </div>


                        <Tabs defaultActiveKey="0" className="scroll-tabs center mb-3" onSelect={handleTabChange}>
                            {categories?.map((category: any,index) => (

                            <Tab key={index} eventKey={category.id} title={category.tab_display}>
                                <div className="col-12 items">
                                {!result?.data && 
                                    <MyLoader/>
                                }
                                <Fade cascade damping={0.2}>
                                {result?.data?.map((row: any,index1) => (
                                    <Link key={index1} to={"/playbook/"+row.slug}>
                                    <div className="item">
                                        <Fade cascade damping={0.2}>
                                            <Zoom>
                                            
                                                <BlurryLoadingImage preview={row?.thumbnail} image={row?.thumbnail} alt={row?.title}></BlurryLoadingImage>
                                            
                                            </Zoom>
                                            <p className="category">{row.category_name} 
                                            {/* <span> - {row.published_date}</span> */}
                                            </p>
                                            <p className="title" dangerouslySetInnerHTML={{ __html: row.title }}></p>
                                            <Link to={"/playbook/"+row.slug}>Learn More</Link>
                                        </Fade>
                                    </div>
                                    </Link>
                                ))}
                                </Fade>
                                </div>


                                {totalPage > 1 && 
                                <div className="col-12 pagination">
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="Next"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    pageCount={totalPage}
                                    previousLabel="Previous"
                                    renderOnZeroPageCount={null}
                                    disabledClassName="disabled"
                                    activeClassName="active"
                                />
                                </div>
                                }
                            </Tab>
                            
                            ))}
                            
                        </Tabs>
                    </div>


                </div>
            </div>
        </>
    )
}
export default SectionOne