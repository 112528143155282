import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import SectionOne from './parts/section-one'
import SectionTwo from './parts/section-two'
import SectionThree from './parts/section-three'
import SectionFour from './parts/section-four'
import SectionSide from './parts/section-side'
import SectionFeatures from './parts/section-features'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import { API_BASE_URL } from '@utils/constant'
import data from './data.json'
// import BannerHome from '@components/banner-home'
import SectionMerchantExp from './parts/section-merchant-exp'
import ShopifyForm from './parts/section-form'
import ShopifyBanner from './parts/banner'
import { Helmet } from "react-helmet";


const Shopify = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})
	/*const [bannerData, setBannerData] = useState<any>({})
	const [isLoadfadeSection, toggleFadeSection] = useState(false);*/

	const scrollToForm = () => {
		const reftp = document.getElementById("shopify-contact-form");
		reftp.scrollIntoView({ behavior: "smooth" });
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
		/*setTimeout(() => {
			toggleFadeSection(true);
		}, 3000);*/
	}, []);


	async function fetchData() {
		fetch(API_BASE_URL + 'page/home').then(
			function (response) {
				if (response.status !== 200) {
					//console.log('Looks like there was a problem. Status Code: ' +
					//response.status);
					return;
				}
				response.json().then(function (data) {
					//console.log(data);
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, []);

	return (
		<>
			<Helmet>
				<title>GoKwik | Grievance Privacy</title>
				<meta name="description" content="GoKwik Grievance Privacy explains how merchants can seek grievance from GoKwik under various scenarios. Read to know more." />
			</Helmet>
			<div className="grievance-policy d-flex" id="start-reading">
				<div className="leftsideb">
					<SectionMerchantExp group={data.merchant.group} data={data.merchant} scrollToForm={scrollToForm}></SectionMerchantExp>
					{/* <SectionOne blocks={data.block1}></SectionOne>
					<SectionTwo blocks={data.block2}></SectionTwo>
					<SectionThree blocks={data.block3} scrollToForm={scrollToForm}></SectionThree>
					<SectionFour blocks={data.block4}></SectionFour> */}
				</div>
				<div className="stickysidebar" >
					<SectionSide blocks={data.merchant.sideBlock.test}></SectionSide>
				</div>
			</div>
			
			<SuccesStory customClass="" category={6}></SuccesStory>
			
		</>
	)
}
export default Shopify