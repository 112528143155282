import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";
import { Link } from "react-router-dom";

const Banner: React.FC<any> = ({ data, arrow = true, animateBanner = false }) => {

    const [reAnimate, setReAnimate] = useState(false);

    const [startSlide, setStartSlide] = useState(false);

    const [delay, setDelay] = useState(1500)
    setTimeout(function () {
        setDelay(0);
        setStartSlide(true)
    }, 0);


    function changeSlider(current) {
        setReAnimate(!reAnimate);
    }

    const settings = {
        autoplay: true,
        autoplaySpeed: 5000,
        fade: false,
        slide: 'div',
        cssEase: 'linear',
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        rtl: false,
        afterChange: (current) => changeSlider(current),
    }

    return (
        <>
            <div className="banner-home banner-checkout">
                <div className="container-fluid">
                    {arrow &&
                        <div className="banner-arrow">
                            <img src="/assets/images/kwikcheckout/sohil.svg" className="arrow" alt="Arrow" width="300" height="320" />
                        </div>
                    } 
                    <div className="top-section">
                         <div className="row display-flex">
                        
                            <div className="col-sm-12 col-md-6">
                                <div className="">
                                    <img src="/assets/images/kwikcheckout/KwikCheckout.svg" />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                            <div className="toplogo">
                                <Link to="/">
                                    <img src="/assets/images/kwikcheckout/gokwik.svg" />
                                </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row display-flex">
                        <div className="col-sm-12 col-md-7">
                            <div className="left kwickcheckc">
                                <Fade cascade damping={0.5}>

                                    <h1 className="b-heading">Convert 40% more shoppers seamlessly</h1>
                                    <p className="home-p">Maximise checkout conversions by delivering the fastest, most rewarding & secured checkout experience for your shoppers.</p>
                                    <div className="check-banner-info">
                                        <img src="/assets/images/kwikcheckout/sohil.svg" />
                                        <p>KwikCheckout bundled with RTO has helped us streamline our checkout process and given our customers the experience of seamless shopping. Our orders went up by 60%. Our RTO massively reduced from 15% to 9%.<span className="check-name">-Sahil Nayar, CEO, Swiss Beauty</span></p>

                                    </div>
                                    </Fade>
                                    <a href="/contact" target="_blank">
                                        <button className="button-cta animate">Get Your FREE Demo</button>
                                    </a>
                                

                            </div>

                        </div>

                        <Fade direction="down" delay={delay + 500} triggerOnce className="col-sm-12 col-md-5 bannerimg">
                            <img src="/assets/images/kwikcheckout/shoppers.svg" />
                        </Fade>
                    </div>


                </div>
            </div>
        </>
    )
}
export default Banner