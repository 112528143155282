import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import SectionOne from './parts/section-one'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import BannerFour from '@components/banner-four'
import data from "./data.json"
import { API_BASE_URL } from '@utils/constant'
import { Helmet } from 'react-helmet'
import IdealPopup from '@pages/product/kwikchat/parts/ideal-popup'

const Podcast = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})
	const [featuredPost, setFeaturedPost] = useState<any>([])

	async function fetchData() {
		fetch(API_BASE_URL + 'page/podcast').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
					getFeaturedPost(data.page.featured)
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, []);

	function getFeaturedPost(row: any) {

		const t = {
			top_heading: "Podcast",
			//category:row?.category_name,
			//date:row?.date,
			heading: row?.title,
			image: "https://img.youtube.com/vi/" + row?.video + "/maxresdefault.jpg",
			uri: "",
			link: "Watch Here",
			video: row?.video
		}
		setFeaturedPost([t]);
	}

	return (
		<>
			{/* <Helmet >
				<title>GoKwik Podcast | Interesting Conversations Between Industry Leaders</title>
				<meta name="description" content="Watch interesting conversations between industry leaders and discover many exciting market tips & tricks to grow and sustain your eCommerce business. Watch Now!" />
			</Helmet> */}
			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'GoKwik Podcast | Interesting Conversations Between Industry Leaders'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "Watch interesting conversations between industry leaders and discover many exciting market tips & tricks to grow and sustain your eCommerce business. Watch Now!"}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>
			<BannerFour data={featuredPost}></BannerFour>
			<SectionOne data={data?.section1}></SectionOne>
			<SuccesStory customClass=""></SuccesStory>
			<SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>
			<IdealPopup
				title={"Unlock VIP Access: Join Our Exclusive Newsletter!"}
				content={"Dive into eCommerce Excellence, Discover What's Latest At GoKwik!"}
				subscribePage="Podcast"
			/>
		</>
	)
}
export default Podcast