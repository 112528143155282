import React, { useEffect, useRef, useState } from 'react'
import './../style.css'
import SectionOne from './../common/section-one'
// import SectionOne from './parts/section-one'
// import SectionTwo from './../common/section-two'
import SectionTwo from './parts/section-two'
//import SectionThree from './../common/section-three'
import SectionThree from './parts/section-three'
import SectionFour from './parts/section-four'
import SectionFive from './parts/faq-section'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import BannerVideo from '@components/banner-video'
import { API_BASE_URL } from '@utils/constant'
import { Helmet } from "react-helmet";


const GrowthFinancing = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})


	async function fetchData() {
		fetch(API_BASE_URL + 'page/growth-financing').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, []);


	const banner = {
		title: "Growth Financing",
		description: "<span>Accelerate And Boost</span> Your Business Growth",
		video: "3qvkZNrwS5o"
	}

	return (
		<>
			{/* <Helmet >
				<title>KwikFinServices - Quick Growth Financing Capital | GoKwik</title>
				<meta name="description" content="Get easy access to growth financing capital to scale your business operations. 50+ brands are supercharging their growth with GoKwik's KwikFinServices." />
			</Helmet> */}
			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'KwikFinServices - Quick Growth Financing Capital | GoKwik'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "Get easy access to growth financing capital to scale your business operations. 50+ brands are supercharging their growth with GoKwik's KwikFinServices."}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>
			<BannerVideo data={result?.page?.banner}></BannerVideo>


			<SectionOne group={result?.groups?.block1} blocks={result?.blocks?.block1}></SectionOne>
			<SectionTwo group={result?.groups?.block2} blocks={result?.blocks?.block2}></SectionTwo>

			<SectionThree bottomTitle={result?.groups?.block4?.title} group={result?.groups?.block3} blocks={result?.blocks?.block3}></SectionThree>
			<SectionFour group={result?.groups?.block4} blocks={result?.blocks?.block4}></SectionFour>

			<SectionFive />
			{/* <SuccesStory customClass="" category={4}></SuccesStory> */}
			<SuccesStory customClass=""></SuccesStory>
			<SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>
		</>
	)
}
export default GrowthFinancing