import React, { useEffect, useRef, useState } from 'react';
import { render } from 'react-dom';
import Scroll from 'react-scroll';
import { Fade, Zoom } from "react-awesome-reveal";
import $ from 'jquery';
import VisibilitySensor from 'react-visibility-sensor';
import { gsap } from "gsap";


const ItemSection: React.FC<any> = ({ onChange, id, data }) => {
  console.log(id)
  return (

    <VisibilitySensor onChange={(e) => {
      if (id !== 3) {
        onChange(e, id)
      }
    }}>
      <div className={(id === 0) ? "item active" : "item"} id={"item" + id}>

        <div className="col-12 leftbox">
          <div className={"mobile-img-frame"}>
            <img src={data.file} alt={data.title} />
          </div>
          {/* <Fade cascade damping={0.5}> */}
          <h4 dangerouslySetInnerHTML={{ __html: data?.title }}></h4>
          <p dangerouslySetInnerHTML={{ __html: data?.content }}></p>
          {/* </Fade> */}
        </div>

      </div>
    </VisibilitySensor>

  )

}


const Section: React.FC<any> = ({ data }) => {
  const [lastItem, setLastItem] = useState<any>(null)

  function onChange(e, item) {
    //console.log("on change....",e,item)
    if (e === false || lastItem === item)
      return;

    //console.log("on change....",e,item)
    setLastItem(item);

    const top = $("#product-features").offset().top;

    const t = $("#item" + item + "-frame");
    const t1 = $("#item" + item);

    //if(window.scrollY < t1.offset().top) {
    //if(top+100 <  t1.offset().top) {

    $(".item-frame").removeClass("active");
    $(".item").removeClass("active");

    t.addClass("active");
    t1.addClass("active");

    const tl = gsap.timeline();
    tl.fromTo(t, { right: -228, duration: 0, opacity: 0 }, { right: 12, duration: 0.5, opacity: 1 });

    //   const tl1 = gsap.timeline();
    //   tl1.fromTo(t, {top:0,position:"unset"}, {top:100,position:"sticky"});

    //   $('html,body').animate({
    //     scrollTop: '+=100px'
    //   })

    //}
  }

  return (
    <>
      {data?.map((row: any, index) => (
        <ItemSection onChange={onChange} id={index} data={row}></ItemSection>
      ))}
    </>
  );
};

export default Section;


