import React, { useRef } from 'react'
import { Link } from "react-router-dom";
import GrowthForm from "./growth-form";
import SectionTwo from './section-two';
import SectionThree from './section-three';
import SectionFour from './section-four';
import SectionFive from './section-five';
const SectionOne: React.FC<any> = () => {
    const params = new URLSearchParams(window.location.search);
    const formRef = useRef<any>('');
    const scrollToForm = () => {
        formRef.current?.scrollIntoView({ behavior: 'smooth' });
    };
    return (
        <>
            <div className="one-click-checkout">
                <div className="container-fluid">
                    <div className="top-section">
                        <div className="row flex-flow">

                            <div className="col-sm-12 col-md-6">
                                <div className="">
                                    <img src="/assets/images/one-click-kwikcheckout/kwikcheckout.svg" />
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <div className="toplogo">
                                    <Link to="/">
                                        <img src="/assets/images/one-click-kwikcheckout/gokwik.svg" />
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row display-flex">
                        <div className="col-sm-12 col-md-7 leftside">
                            <div className="left onekwickcheckc">
                                {/* <Fade cascade damping={0.5}> */}

                                <h1 className="b-heading">Convert 40% More Shoppers While Reducing RTO</h1>
                                <div className="home-p-mobile">
                                    <p>Boost your checkout conversions with the fastest & most secured one-click checkout solution. Effectively reduce RTO by 40% while expanding your COD reach to deeper pincodes of Bharat.</p>
                                </div>
                                <div className="home-p">
                                    <ul>
                                        <li>Boost checkout page conversion rate with the fastest & most secured one-click checkout solution</li>
                                        <li>Effectively reduce RTO by up to 40%</li>
                                        <li>Expand cash-on-delivery reach to deeper pin codes of Bharat</li>
                                    </ul>
                                </div>
                                <div className="check-banner-info">
                                    <img src="/assets/images/one-click-kwikcheckout/siddharth.svg" />
                                    <p>"We saw a 40% month-on-month growth in our checkout conversion rate and 23% reduction in RTO with GoKwik’s fast checkout process solution"<span className="check-name">-Siddharth Dungarwal, Founder, SNITCH</span></p>

                                </div>
                                <div className="mobileview">
                                    <img src="/assets/images/one-click-kwikcheckout/onekwickcheckc.svg" />
                                    <a className='pointer' onClick={scrollToForm}>
                                        <button className="button-cta animate">Get A Free Demo</button>
                                    </a>
                                </div>
                                {/* </Fade> */}
                            </div>
                            <SectionTwo scrollToForm={scrollToForm}/>
                            <SectionThree />
                            <SectionFour scrollToForm={scrollToForm} />
                            <SectionFive formRef={formRef}  />
                        </div>


                        <div className="col-12 col-md-5 rightside">

                            <div className="growth-contact-section">
                                <GrowthForm params={params} />
                            </div>

                        </div>
                    </div>


                </div>
            </div>
        </>
    )
}
export default SectionOne