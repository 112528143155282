import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Fade, Zoom } from "react-awesome-reveal";
import Section from "./section";
import $ from 'jquery';

const SectionFeatures: React.FC<any> = (props)=> {
    const {group,blocks} = props;
   
    return (
        <>
            <div className={"product-features "} id="product-features">
          

                <div className="container-fluid">
                    
                    <div className="col-12 section-header">
                        <Fade direction="down">
                            <h3 dangerouslySetInnerHTML={{ __html: group?.title }}></h3>
                            <p className="description">Data-Backed Intelligence To Curb Return To Origin Right At The Source</p>
                        </Fade>
                    </div>

                    <div className="col-12 wrapper flex">
                        
                        <div className="col-12 col-md-6">
                            <Section data={blocks}></Section>
                        </div>

                        <div className="col-12 col-md-6" id="screens">
                            <div className={"img-wrapper img-wrapper-big"}>
                                <div className="circle">
                                    <div className="inner-circle"></div>
                                </div>
                                <img src="/assets/images/product/cod/frame.svg" alt={"COD Frame"} className="frame"/>

                                <img src="/assets/images/product/cod/1.svg" alt={"COD 1"} className="frame-big"/>


                                {blocks?.map((row:any , index) => (
                                    <>
                                    {(index === 2)?(
                                    <>
                                        <div className={"item-frame img-frame"} id={"item21-frame"}>
                                            <img src={"/assets/images/product/cod/3.1.svg"} alt={row.title} />
                                        </div>
                                        <div className={"item-frame img-frame"} id={"item22-frame"}>
                                            <img src={"/assets/images/product/cod/3.2.svg"} alt={row.title} />
                                        </div>
                                        <div className={"item-frame img-frame"} id={"item23-frame"}>
                                            <img src={"/assets/images/product/cod/3.3.svg"} alt={row.title} />
                                        </div>
                                    </>
                                    ):(
                                        <div className={(index===0)?"item-frame img-frame active":"item-frame img-frame"} id={"item"+index+"-frame"}>
                                            <img src={row.file} alt={row.title} />
                                        </div>
                                    )}

                                    </>
                                ))}
                            </div>

                        </div>
                        
                    </div>
                         
                </div>
            </div>
            </>
    )
}
export default SectionFeatures