import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-awesome-reveal";

const SectionOne: React.FC<any> = ({data})=> {


    return (
        <>
            <div className="section-one">
                {/* <div className="container-fluid"> */}
                    
                {/* </div> */}
            </div>

            <div className="section-one-wrapper">
                <div className="col-12 inner">
                        <div className="col-12 col-sm-5 left">
                            <div className="pic">
                                <Zoom>
                                <img src={data?.file} alt={data?.alt}/>
                                </Zoom>
                            </div>
                        </div>
                        <div className="col-12 col-sm-7 right">
                            <Fade cascade damping={0.2}>
                                <p>{data?.content}</p>
                                <div className="name">{data?.cta}</div>
                                <div className="subname">{data?.cta_text}</div>
                            </Fade>
                        </div>
                    
                </div>
            </div>
        </>
    )
}
export default SectionOne