import React, { useRef } from "react";
import "./style.css";
import SectionOne from "./parts/section-one";
import SectionTwo from "./parts/section-two";
import SectionThree from "./parts/section-three";
import { Helmet } from "react-helmet";


function KwikGlory() {
  const faqRef = useRef(null);

  const scrollToTheForm = () => {
    faqRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title>GoKwik Hall Of Glory | Celebrating D2C Milestones</title>
        <meta
          name="description"
          content="GoKwik Hall Of Glory is a special award programme that celebrates the significant milestones that our merchant brands achieve during their D2C journey with us."
        />
        <meta
          name="keywords"
          content="GoKwik Hall Of Glory, GoKwik, merchant brands, GMV"></meta>
      </Helmet>
      <SectionOne scrollToTheForm={scrollToTheForm} />
      <SectionTwo />
      <SectionThree faqRef={faqRef} />
    </>
  );
}

export default KwikGlory;
