import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import SectionOne from './parts/section-one'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import LatestBlog from '@components/blog'
import BannerThree from '@components/banner-three'
import data from "./data.json"
import { API_BASE_URL } from '@utils/constant'
import NetworkEffect from '@components/network-effect'
import { Helmet } from "react-helmet";
import IdealPopup from '@pages/product/kwikchat/parts/ideal-popup'


const SuccesStoryPage = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})
	const [featuredPosts, setFeaturedPosts] = useState<any>([])

	async function fetchData() {
		fetch(API_BASE_URL + 'page/success-story').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
					getFeaturedPost(data?.page?.featured);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, []);

	function getFeaturedPost(data = []) {
		let temp = [];
		data.map((row: any, index) => {
			const t = {
				top_heading: "Success Story",
				category: row?.category_name,
				date: row?.date,
				heading: row?.title,
				image: row?.featured_image,
				uri: "/success-story/" + row?.slug,
				link: "Read More"
			}
			temp.push(t);
		});
		setFeaturedPosts(temp);
	}

	return (
		<>
			{/* <Helmet >
				<title>Customer Success Stories & Feature Videos | GoKwik</title>
				<meta name="description" content="Learn how GoKwik partner merchants achieve high conversion rate, reduce RTO, and increase business GMV through our real-life success stories and feature videos" />
			</Helmet> */}
			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'Customer Success Stories & Feature Videos | GoKwik'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "Learn how GoKwik partner merchants achieve high conversion rate, reduce RTO, and increase business GMV through our real-life success stories and feature videos"}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>
			<BannerThree data={featuredPosts}></BannerThree>
			<SectionOne categories={result?.page?.categories}></SectionOne>

			<NetworkEffect group={result?.groups?.block7} blocks={result?.blocks?.block7}></NetworkEffect>

			<LatestBlog customClass=""></LatestBlog>


			<SuperChargedBusiness subscribe={true} subscribePage="Success Story" group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>

			<IdealPopup
				title={"Unlock VIP Access: Join Our Exclusive Newsletter!"}
				content={"Dive into eCommerce Excellence, Discover What's Latest At GoKwik!"}
				subscribePage="Success Story"
			/>

		</>
	)
}
export default SuccesStoryPage