import React, { useEffect, useRef, useState } from 'react'
import './../style.css'
import SectionOne from './section-one'
import SectionTwo from './section-two'
import SectionThree from './section-three'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import data from "./../data.json"
import { API_BASE_URL } from '@utils/constant'
import { useParams } from 'react-router-dom'
import LatestBlog from '@components/blog'
import { Helmet } from 'react-helmet'



const SuccessStoryPageDetails = () => {
	const { slug } = useParams()
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})
	const [article, setArticle] = useState<any>({})

	async function fetchData() {
		fetch(API_BASE_URL + 'page/success-story').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					//console.log(data);
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	async function fetchArticle() {
		fetch(API_BASE_URL + 'successstory/' + slug).then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setArticle(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}


	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
		fetchArticle();
	}, []);

	return (
		<>
			{
				(article && article.meta_title) &&
				<Helmet>
					{article.meta_title && <title>
						{article.meta_title}
					</title>
					}
					{article.meta_description && <meta name="description" content={article.meta_description} />}
					{article.meta_keyword && <meta name="keywords" content={article.meta_keyword}></meta>}

					{/* Facebook and Linkedin */}
					<meta property="og:url" content={window.location.href} />
					<meta property="og:type" content="website" />
					<meta property="og:title" content={article?.meta_title} />
					<meta property="og:description" content={article?.meta_description} />
					<meta property="og:image" content={article?.featured_image} />

					{/* Twitter */}
					{/* <meta name="twitter:card" content="summary_large_image" /> */}
					<meta property="twitter:domain" content="gokwik.co" />
					<meta property="twitter:url" content={window.location.href} />
					<meta name="twitter:title" content={article?.meta_title} />
					<meta name="twitter:description" content={article?.meta_description} />
					<meta name="twitter:image" content={article?.featured_image} />
				</Helmet>
			}
			<SectionOne data={article}></SectionOne>
			<SectionTwo data={article}></SectionTwo>
			<SectionThree data={article}></SectionThree>
			<LatestBlog customClass=""></LatestBlog>
			<SuperChargedBusiness subscribe={true} subscribePage={`Success Story: ${article?.title}`} group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>

		</>
	)
}
export default SuccessStoryPageDetails