import React, { useEffect, useState, useRef } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form"
import { Form, InputGroup, Button } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Fade } from "react-awesome-reveal";
import axios from "axios";
import { RotatingLines } from 'react-loader-spinner'
import { API_BASE_URL, RECAPTCHA_SITE_KEY } from "@utils/constant";
import ReCAPTCHA from "react-google-recaptcha";

const ThankYou: React.FC<any> = (props) => {
    const { name } = props;

    return (
        <div className="thank-you">
            <img src="/assets/images/thank-you.svg" alt="Thank you" />
            <h1>Thank You</h1>
            <p>Hi <strong>{name},</strong></p>
            <p>Thank you for providing the necessary details regarding your concern. We appreciate your prompt response.</p>
            <p>Please be assured that we are actively looking into the matter and will strive to provide you with a resolution within the next 72 hours. Your patience during this process is highly valued.</p>
        </div>
    );
}

const CustomInput = React.forwardRef((props: any, ref) => {

    return <FloatingLabel
        className="purchase-date"
        controlId="floatingInput"
        label="Purchase Date"
    >
        <Form.Control
            {...props} ref={ref}
            type="text"
            readOnly={true}
            placeholder="Purchase Date"
        />

    </FloatingLabel>
});



const CustomerForm: React.FC<any> = (props) => {
    const { data, params } = props;
    const inputRef = useRef(null);
    const [isFirstForm, setIsFirstForm] = useState(true);
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, setValue, getValues } = useForm({});

    const [name, setName] = useState("");
    const [startDate, setStartDate] = useState("");


    const [validated, setValidated] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errors, setErrors] = useState<any>({});
    const [formData, setFormData] = useState(null);

    const recaptchaRef = React.useRef<ReCAPTCHA>();

    const handleRecaptchaSubmit = async () => {
        // Trigger the reCAPTCHA challenge
        recaptchaRef.current.execute();
    };

    const handleRecaptchaChange = (value) => {
        formData.g_recaptcha_token = value;
        axios.post(API_BASE_URL + 'form/customer', formData)
            .then(function (response) {
                setLoading(false);
                //console.log(response);
                setErrors({});
                setName(formData?.name);
                setSuccessMessage("submitted");
                setFormData(null)
            })
            .catch(function (error) {
                setLoading(false);
                //setSuccessMessage("yes");
                setErrors(error?.response?.data?.errors);

            });
    };

    const onSubmit: SubmitHandler<any> = async (data) => {
        //console.log("data : ",data)
        if (startDate) {
            data.purchase_date = startDate;
        }
        setLoading(true);
        setSuccessMessage("");

        data.source = params.get("utm_source")
        data.medium = params.get("utm_medium")
        data.campaign = params.get("utm_campaign")
        // data.g_recaptcha_token = (recaptchaRef.current as any)?.getValue();
        // console.log(recaptchaRef.current)

        /*axios.post(API_BASE_URL + 'form/customer', data)
            .then(function (response) {
                setLoading(false);
                //console.log(response);
                setErrors({});
                setName(data?.name);
                setSuccessMessage("submitted");
            })
            .catch(function (error) {
                setLoading(false);
                //setSuccessMessage("yes");
                console.log(error.response.data?.errors['product[]']);
                setErrors(error?.response?.data?.errors);

            });*/

        setFormData(data);
        handleRecaptchaSubmit();

    }

    return (

        <div className="form">

            <div className="form-wrapper">
                {successMessage &&
                    <Fade direction="right">
                        <ThankYou name={name} />
                    </Fade>
                }

                {!successMessage &&
                    <Fade direction="right">
                        <Form onSubmit={handleSubmit(onSubmit)} noValidate validated={validated} name="customer" >

                            <div className="col-12 mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Your Full Name*"
                                >
                                    <Form.Control
                                        {...register("name")}
                                        className={errors.name ? 'is-invalid' : ''}
                                        type="text"
                                        placeholder="Your Full Name*"
                                    />

                                    {errors?.name &&
                                        <Form.Control.Feedback type="invalid">{errors?.name[0]}</Form.Control.Feedback>
                                    }
                                </FloatingLabel>

                            </div>

                            <div className="col-12 mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Your Mobile Number*"
                                >
                                    <Form.Control
                                        {...register("mobile")}
                                        className={errors.mobile ? 'is-invalid' : ''}
                                        type="text"
                                        maxLength={10}
                                        onKeyUp={(e) => setValue("mobile", getValues('mobile').replace(/[^0-9]/g, ''))}
                                        placeholder="Your Mobile Number*"
                                    />

                                    {errors?.mobile &&
                                        <Form.Control.Feedback type="invalid">{errors?.mobile[0]}</Form.Control.Feedback>
                                    }
                                </FloatingLabel>

                            </div>

                            <div className="col-12 mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Your Email Id*"
                                >
                                    <Form.Control
                                        {...register("email")}
                                        className={errors.email ? 'is-invalid' : ''}
                                        type="text"
                                        placeholder="Your Email Id"
                                    />

                                    {errors?.email &&
                                        <Form.Control.Feedback type="invalid">{errors?.email[0]}</Form.Control.Feedback>
                                    }
                                </FloatingLabel>

                            </div>

                            <div className="col-12 mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Order ID/ Transaction ID/ UTR*"
                                >
                                    <Form.Control
                                        {...register("order_id")}
                                        className={errors.order_id ? 'is-invalid' : ''}
                                        type="text"
                                        placeholder="Your Brand URL*"
                                    />

                                    {errors?.order_id &&
                                        <Form.Control.Feedback type="invalid">{errors?.order_id[0]}</Form.Control.Feedback>
                                    }
                                </FloatingLabel>
                            </div>

                            <div className="col-12 mb-3 flex">
                                <div className="col-sm-12 col-md-6 pe-1">
                                    <DatePicker
                                        selected={startDate}
                                        onChange={(date) => setStartDate(date)}
                                        customInput={<CustomInput {...register("purchase_date")} inputRef={inputRef} />}
                                    />
                                    {/* <span className="info">Date accepted up to 1/8/22</span> */}
                                    {errors?.purchase_date &&
                                        <Form.Control.Feedback type="invalid">{errors?.purchase_date[0]}</Form.Control.Feedback>
                                    }
                                </div>

                                <div className="col-sm-12 col-md-6 ps-1">
                                    <FloatingLabel
                                        className="amount"
                                        controlId="floatingInput"
                                        label="Amount"
                                    >
                                        <Form.Control
                                            {...register("amount")}
                                            className={errors.amount ? 'is-invalid' : ''}
                                            type="text"
                                            onKeyUp={(e) => setValue("amount", getValues('amount').replace(/[^0-9]/g, ''))}
                                            placeholder="Amount*"
                                        />

                                        {errors?.amount &&
                                            <Form.Control.Feedback type="invalid">{errors?.amount[0]}</Form.Control.Feedback>
                                        }
                                    </FloatingLabel>
                                </div>
                            </div>

                            <div className="col-12 mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Describe the issue?"
                                >
                                    <Form.Control
                                        as="textarea"
                                        style={{ height: '100px' }}
                                        {...register("description")}
                                        className={errors.description ? 'is-invalid' : ''}
                                        type="text"
                                        placeholder="Your Brand URL*"
                                    />

                                    {errors?.description &&
                                        <Form.Control.Feedback type="invalid">{errors?.description[0]}</Form.Control.Feedback>
                                    }
                                </FloatingLabel>
                            </div>

                            <ReCAPTCHA
                                ref={recaptchaRef as React.RefObject<ReCAPTCHA>}
                                sitekey={RECAPTCHA_SITE_KEY}
                                size="invisible"
                                onChange={handleRecaptchaChange}
                            // onChange={onChange}
                            />

                            <button type="submit" className="btn btn-submit" disabled={loading}>
                                Submit
                                <RotatingLines strokeColor="white" strokeWidth="5" animationDuration="0.75" width="18" visible={loading}
                                />
                            </button>

                        </Form>
                    </Fade>
                }
            </div>




        </div>
    );
}
export default CustomerForm;