import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { scrollinit } from "@utils/helper";
import ResponsiveYoutube from "../youtube";



const SectionOne: React.FC<any> = (props)=> {
    const {data} = props;



    return (
        <>
            <div className="success-story-details-one">
                <div className="container-fluid">
                    <div className="col-12 container">
                        <div className="row col-12">
                            <div className="col-md-6">
                            <p className="category">{data?.category_name} <span>- {data?.published_date}</span></p>
                            <h1 dangerouslySetInnerHTML={{__html:data?.title}}></h1>
                            <p className="short-description" dangerouslySetInnerHTML={{__html:data?.description}}></p>
                            </div>
                            <div className="col-md-6">
                                <img className="featured-image" src={data?.featured_image}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SectionOne