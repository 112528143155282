import React,{useEffect,useRef,useState} from 'react'
import './style.css'
import SectionOne from './parts/section-one'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import BannerFour from '@components/banner-four'
import BannerFive from '@components/banner-five'
import data from "./data.json"
import { API_BASE_URL } from '@utils/constant'
import { Helmet } from "react-helmet"
import IdealPopup from '@pages/product/kwikchat/parts/ideal-popup'

const ProductExplainer = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})

	async function fetchData() {
		fetch(API_BASE_URL+'page/product-explainer').then(
			function(response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function(data) {
					setResult(data);
				});
			}
		)
		.catch(function(err) {
			console.log('Fetch Error :-S', err);
		});
	}

	useEffect(() => {
        if(wasCalled.current) return;
        wasCalled.current = true;
        fetchData();
    }, []);
	

	return (
		<>
			{/* <Helmet >
				<title>GoKwik Product Explainers | Uncover Features & Benefits</title>
				<meta name="description" content="Discover GoKwik's revolutionary solutions like fast checkout, RTO, etc through our product explainers. Uncover features, benefits, and transform your journey."/>
			</Helmet> */}
			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'GoKwik Product Explainers | Uncover Features & Benefits'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "Discover GoKwik's revolutionary solutions like fast checkout, RTO, etc through our product explainers. Uncover features, benefits, and transform your journey."}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>
			<SectionOne banner={result?.page?.banner} categories={result?.page?.categories}></SectionOne>
			<SuccesStory customClass=""></SuccesStory>
			<SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>
			<IdealPopup
				title={"Unlock VIP Access: Join Our Exclusive Newsletter!"}
				content={"Dive into eCommerce Excellence, Discover What's Latest At GoKwik!"}
				subscribePage="Product Explainer"
			/>
		</>
	)
}
export default ProductExplainer