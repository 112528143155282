import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";



const SectionOne: React.FC<any> = ({ scrollToTheForm }) => {

    return (
        <>
            <div className="section-one-banner">
                <div className="container-fluid">



                    <div className="row display-flex align-items-center">
                        <div className="col-sm-12 col-md-6 left">

                            <h1>GoKwik Hall Of Glory</h1>
                            <p className="home-p shopbannerdesp">Celebrating significant milestones of our merchant brands in their D2C journey with us.</p>
                            {/* <Link to="#" onClick={scrollToTheForm}> */}
                            <button className="button-cta pointer" onClick={scrollToTheForm}>Tell Me More</button>
                            {/* </Link> */}

                        </div>

                        <div className="col-sm-12 col-md-6">

                            <img src="/assets/images/glory/gokwik-glory.svg" width="100%" alt="" />

                        </div>

                    </div>



                </div>

            </div>
        </>
    )
}
export default SectionOne