import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";


const SectionMerchantExp: React.FC<any> = ({ group, blocks, scrollToForm }) => {
    return (
        <>
            <div className="terms-section">
                <div className="container-fluid">

                    <div className="col-12 section-header">
                        <Fade direction="down">
                            <h3 dangerouslySetInnerHTML={{ __html: group?.title }}></h3>
                            <p>{group?.description}</p>
                        </Fade>
                    </div>


                    {blocks?.map((row: any, index) => (
                    <div className="row row-cols-8 row-cols-md-8 row-cols-lg-8 mx-8 items">
                        <Fade cascade damping={0.2}>

                                <div className="col">
                                    <div className="item">
                                        <div className="rightbox">
                                            <Fade cascade damping={0.3}>
                                                <h4 dangerouslySetInnerHTML={{ __html: row.title }}></h4>
                                                <p dangerouslySetInnerHTML={{ __html: row.content }}></p>
                                            </Fade>
                                        </div>
                                    </div>
                                </div>

                        </Fade>
                    </div>
                            ))}


                </div>
            </div>
        </>
    )
}
export default SectionMerchantExp