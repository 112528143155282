import React, { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Form } from 'react-bootstrap';
import { SubmitHandler, useForm } from "react-hook-form";
import { API_BASE_URL } from "@utils/constant";
import axios from "axios";
import { RotatingLines } from "react-loader-spinner";

interface IProps {
    redirectPath?: string;
    title: string;
    content: string;
    subscribePage?: string;
}
const IdealPopup: React.FC<any> = memo(function IdealPopup(props: IProps) {
    const { redirectPath, title, content, subscribePage } = props
    const [isShowPopup, togglePopup] = useState(false);
    const { register, handleSubmit } = useForm({});
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState<any>({});
    const [isFormSubmitted, setFormSubmit] = useState(false);

    useEffect(() => {
        handlePopup()
    }, [])

    const handlePopup = () => {
        setTimeout(() => {
            togglePopup(true);
        }, 10000)
    }

    const onClosePopup = () => {
        togglePopup(false);
    }

    const onSubmit: SubmitHandler<any> = async (data) => {
        setLoading(true);
        data.subscribe_page = subscribePage;

        axios.post(API_BASE_URL + 'form/subscribe', data)
            .then(function (response) {
                setFormSubmit(true)
                setLoading(false);
                setErrors({});
                setTimeout(() => {
                    setFormSubmit(false)
                    onClosePopup()
                }, 3000)
            })
            .catch(function (error) {
                setErrors(error?.response?.data?.errors);
                setLoading(false);
            });

    }

    return (
        <>

            {/* <Modal size="lg" className="idealpopup"
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                    centered show={isShowPopup} onHide={onClosePopup}>
                    <Modal.Dialog className="modal-custom">
                        <div className="cross-icon">
                            <button className="close-btn" onClick={onClosePopup}>X</button>
                        </div>
                        <div className="parag-content">
                            <h1>{title}</h1>
                            <p>{content}</p>
                            {
                                redirectPath ?
                                    <Link to={redirectPath}>
                                        <button>Download Playbook</button>
                                    </Link>
                                    :
                                    (
                                        <React.Fragment>
                                            <Form onSubmit={handleSubmit(onSubmit)} validated={false} className="subscribe-form">

                                                <input type="text" className={errors?.name && 'field-error'}  {...register("name")} placeholder="Your Name" required />
                                                <input type="email" className={errors?.email && 'field-error'} {...register("email")} placeholder="Your Email" required />

                                                <button type="submit" disabled={loading}>
                                                    Submit
                                                    <RotatingLines strokeColor="white" strokeWidth="5" animationDuration="0.75" width="18" visible={loading}
                                                    />
                                                </button>
                                            </Form>
                                        </React.Fragment>
                                    )
                            } 
                        </div>
                    </Modal.Dialog>
                </Modal> */}
            {
                isShowPopup &&
                <div className="ideal-popup-sticky-cta -hidden -dark" id="ideal-popup-sticky-cta" data-banner-state="expanded" data-form-state="disabled" data-sticky-state="hidden">
                    <div className="ideal-popup-sticky-cta-static">
                        <div className="cross-icon">
                            <button className="close-btn" onClick={onClosePopup}>X</button>
                        </div>
                        <div className="ideal-popup-sticky-cta-expanded-content" id="ideal-popup-sticky-cta-expanded-content">
                            <div className="parag-content">
                                <h1>{title}</h1>
                                <p>{content}</p>
                                {
                                    redirectPath ?
                                        <Link to={redirectPath}>
                                            <button>Download Playbook</button>
                                        </Link>
                                        :
                                        (
                                            isFormSubmitted ? <h3>Thank You!</h3> :
                                                <Form onSubmit={handleSubmit(onSubmit)} validated={false}>
                                                    <div className="subscribe-form">
                                                        <input type="text" className={errors?.name && 'field-error'}  {...register("name")} placeholder="Your Name" required />
                                                        <input type="email" className={errors?.email && 'field-error'} {...register("email")} placeholder="Your Email" required />
                                                       
                                                    </div> 
                                                   
                                                    <input type="text" className={errors?.brand_url && 'field-error'}  {...register("brand_url")} placeholder="Your Brand URL" required />
                                                    <button type="submit" disabled={loading}>
                                                        Subscribe
                                                        <RotatingLines strokeColor="white" strokeWidth="5" animationDuration="0.75" width="18" visible={loading}
                                                        />
                                                    </button>
                                                   
                                                </Form>
                                        )
                                }
                            </div>

                        </div>
                    </div>

                </div>
            }
            {/* <div className="ideal-popup-sticky-cta -hidden -dark" id="ideal-popup-sticky-cta" data-banner-state="expanded" data-form-state="disabled" data-sticky-state="hidden">

                <div className="ideal-popup-sticky-cta-static">
                    <div className="cross-icon">
                        <button className="close-btn" onClick={onClosePopup}>X</button>
                    </div>

                    <div className="ideal-popup-sticky-cta-expanded-content" id="ideal-popup-sticky-cta-expanded-content">
                        <div className="parag-content">
                            <h1>Unlock VIP Access: Join Our Exclusive Newsletter!</h1>
                            <p>Dive into eCommerce Excellence, Discover What's Latest At GoKwik!</p>
                            <Form className="subscribe-form">
                                <input type="text" className={errors?.name && 'field-error'}  {...register("name")} placeholder="Your Name" required />
                                <input type="email" className={errors?.email && 'field-error'} {...register("email")} placeholder="Your Email" required />
                            </Form>
                            <p>submit msg </p>
                            <a href="/playbook/explore-a-bouquet-of-exclusive-discounts-offers-with-kwikcheckout">
                                <button data-faitracker-form-bind="true">Download Playbook</button></a>
                        </div>

                    </div>
                </div>

            </div> */}

        </>
    )
})
export default IdealPopup
