import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { scrollinit } from "@utils/helper";
import ResponsiveYoutube from "../youtube";
import parse from 'html-react-parser';
import $ from 'jquery';


const replace = (domNode) => {
    //console.log("don : ",domNode)
    if (domNode.name === "youtube") {
      return (
        <ResponsiveYoutube id={domNode.attributes[0]?.value}></ResponsiveYoutube>
      );
    }
  };


const SectionTwo: React.FC<any> = (props)=> {
    const {data} = props;

    let content;
    let content2;

    if(data?.content != undefined){
       
        const reg = /\[youtube id=.*\]/
        const temp = data.content.match(reg);
        if(temp != null){
            const new_youtube = temp[0].replace("[", "<").replace("]", ">");
            data.content = data.content.replace(temp[0],new_youtube);
        }
        content = parse(data.content, { replace })
    }

    if(data?.content2 != undefined){
        const reg = /\[youtube id=.*\]/
        const temp = data.content2.match(reg);
        if(temp != null){
            const new_youtube = temp[0].replace("[", "<").replace("]", ">");
            data.content2 = data.content2.replace(temp[0],new_youtube);
        }
        content2 = parse(data.content2, { replace })
    }

    useEffect(()=>{
        $(window).scroll(function(){
            const top = $(".success-story-details-two").offset().top;
            const latestBlogTop = $(".latest-blog").offset().top;
            const height =  $('.success-story-rightpoints').innerHeight();
            const scrollTop = $(this).scrollTop();

            if (scrollTop > top && scrollTop < latestBlogTop-height) {
                $('.success-story-rightpoints').addClass('fixed');
            } else {
                $('.success-story-rightpoints').removeClass('fixed');
            }
        });
        return () => { 
            $(window).off('scroll')
        }
    },[]);


    return (
        <>
            <div className="success-story-details-two">
                <div className="container-fluid">

                    <div className="row col-12 container">
                        <div className="col-12 col-md-9 container success-story-content">
                            {content && content}
                        
                        </div>


                        <div className="col-12 col-md-3">
                            <div className="success-story-rightpoints">
                                {content2 && content2}
                            </div>
                        </div>

                    </div>


                </div>
            </div>
        </>
    )
}
export default SectionTwo