import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";


const SectionFunnel: React.FC<any> = ({block})=> {
  
    return (
        <>
            <div className="product-funnel">
                <div className="container-fluid">

                    <div className="col-12 section-header">
                        <Fade direction="down">
                            <h3 dangerouslySetInnerHTML={{ __html: block?.title }}></h3>
                        </Fade>
                    </div>
                   
                    <div className="img-wrapper">
                        <Zoom>
                            <img src={block?.file} alt={block?.title}/>
                        </Zoom>
                    </div>
                    
                </div>
            </div>
            </>
    )
}
export default SectionFunnel