import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";


const SectionSolutions: React.FC<any> = ({ group, blocks })=> {
   
    return (
        <>
            <div className="product-solutions">
                <div className="container-fluid">

                    <div className="col-12 section-header">
                        <Fade direction="down">
                            <h3 dangerouslySetInnerHTML={{ __html: group?.title }}></h3>
                            <p>{group?.description}</p>
                        </Fade>
                    </div>
                   
                    <div className="col-12 wrapper">

                    {blocks?.map((row: any, index) => (

                        (index % 2 == 0)?(
                            <div className="row item">
                                <div className="col-12 col-md-6 leftbox">
                                    <Fade cascade damping={0.5}>
                                        <h4>{row.title}</h4>
                                        <p>{row.content}</p>
                                    </Fade>
                                </div>

                                <div className="col-12 col-md-6 rightbox img-wrapper">
                                    <Zoom>
                                    <img src={row.file} alt={row.title}/>
                                    </Zoom>
                                </div>
                            </div>
                        ):(
                            <div className="row item">
                                <div className="col-12 col-md-6 leftbox img-wrapper">
                                    <Zoom>
                                    <img src={row.file} alt={row.title}/>
                                    </Zoom>
                                </div>

                                <div className="col-12 col-md-6 rightbox">
                                    <Fade cascade damping={0.5}>
                                        <h4>{row.title}</h4>
                                        <p>{row.content}</p>
                                    </Fade>
                                </div>

                            </div>
                        )
                        
                        
                    ))}  


                    </div>
                </div>

                <div className="col-12 feature-wrapper">
                    <div className="inner-wrapper">
                        <Fade cascade damping={0.5}>
                            <p>The Shopper <span>Journey</span></p>
                            <h4>GoKwik Network Effect</h4>
                            <p>A state-of-the-art ML model based on a risk intelligence framework that evolves to promise secure, and real-time RTO management.</p>
                            <img src="/assets/images/product/cod/shopper-journey.png"  style={{ width: "100%" }}/>
                        </Fade>
                    </div>
                </div>
            </div>
            </>
    )
}
export default SectionSolutions