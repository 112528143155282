import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import Slider from "react-slick";
import BlurryLoadingImage from "@components/lazyimage";


const settings = {
    autoplay: false,
    autoplaySpeed: 3000,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 5.5,
    slidesToScroll: 1,
    speed: 500,
    responsive: [
        {
            breakpoint: 1140,
            settings: {
                slidesToShow: 3.5,
                slidesToScroll: 3,
                initialSlide: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 720,
            settings: {
                slidesToShow: 2.5,
                slidesToScroll: 2,
                initialSlide: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 420,
            settings: {
                slidesToShow: 1.5,
                slidesToScroll: 1,
                initialSlide: 1,
                infinite: true,
                dots: false
            }
        }
    ]
  };

const SectionThree: React.FC<any> = (props)=> {
    const {data,block,achievements} = props;


    const [delay,setDelay] = useState(500)
    let zIndex= 1000;

    document.querySelectorAll('.item').forEach(function(element) {
        element.setAttribute('style', 'z-index:'+zIndex--);
    });

    setTimeout(function(){
        setDelay(0)
    }, 1500);


    return (
        <>
            <div className="section-three">
                <div className="container-fluid">
                    <div className="col-12 inner">
                            <Fade direction="down">
                            <h2 className="section-heading" dangerouslySetInnerHTML={{__html:block?.title}}></h2>
                                <p className="section-desc">{block?.content}</p>
                            </Fade>
                    </div>
                </div>


                <div className="col-12 bg slider">
            
                  <Slider {...settings}>
                  {achievements?.map((row: any) => (
                    <Link to={row.linkedin} target="_blank">
                        <div className="item" style={{"zIndex":zIndex--}}>
                            {/* <img src={row.file} alt={row.name}/> */}
                            <Zoom>
                            <BlurryLoadingImage preview={row?.file} image={row?.file} alt={row?.name}></BlurryLoadingImage>
                            </Zoom>
                            <p><img src="/assets/images/about/linkedin.svg" alt="Linked in"/>{row.name}</p>
                        </div>
                    </Link>
                 ))}
                  </Slider>
                </div>
                     
                
            </div>
        </>
    )
}
export default SectionThree