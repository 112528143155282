import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";
import BlurryLoadingImage from "@components/lazyimage";



const SectionFour: React.FC<any> = (props)=> {
    const {data,group,blocks,teams} = props;
    return (
        <>
            <div className="section-four">
                <div className="container-fluid">
                    <div className="col-12 inner">
                            <Fade direction="down">
                                <h2 className="section-heading" dangerouslySetInnerHTML={{__html:group?.title}}></h2>
                                <p className="section-desc">{group?.description}</p>
                            </Fade>
                    </div>
                

                    <Fade direction="down">
                    <div className="col-12 logos">
                        
                        {blocks?.map((row:any , index) => (
                            <div className="item">
                                <BlurryLoadingImage preview={row?.file} image={row?.file} alt={row?.title}></BlurryLoadingImage>
                                {/* <img src={row.file} alt={row.title}/> */}
                            </div>
                        ))}
                        
                    </div>
                    </Fade>


                    <div className="col-12 team">
                        <Fade cascade damping={0.1}>
                        {teams?.map((row: any) => (
                           
                           
                            <div className="item">
                                <Fade cascade damping={0.2}>
                                <BlurryLoadingImage preview={row?.file} image={row?.file} alt={row?.name}></BlurryLoadingImage>
                                {/* <img src={row.file} alt={row.name}/> */}
                                <p className="name">{row.name}</p>
                                <p className="designation">{row.designation}</p>
                                </Fade>
                            </div>
                            

                         ))}
                        </Fade>
                    </div>

                </div>
                
            </div>
        </>
    )
}
export default SectionFour;