import { useEffect, useRef, useState } from "react";
import "../styles/style.css";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { API_BASE_URL, RECAPTCHA_SITE_KEY } from "@utils/constant";
import { RotatingLines } from "react-loader-spinner";
import axios from "axios";
import { triggerConversion } from "@utils/helper";
import { Fade } from "react-awesome-reveal";
import ThankYou from "@pages/contact/parts/thank-you";

const SectionThree = ({ formRef }) => {
  const params = new URLSearchParams(window.location.search);
  const {
    register,
    handleSubmit
  } = useForm();
  const recaptchaRef = useRef<ReCAPTCHA>();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState(null);
  const [formHasSubmitedSucessfully, setFormHasSubmitedSucessfully] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [name, setName] = useState("");

  // Analytics
  useEffect(() => {
    const utmSource = new URL(window.location.href).searchParams.get(
      "utm_source"
    );
    if (utmSource && utmSource.trim() !== "") {
      Cookies.set("gokwik_utm_source", utmSource.toLowerCase(), {
        expires: 30,
      });
    }
  }, []);


  const handleRecaptchaChange = async (value: any) => {
    formData.g_recaptcha_token = value;
    formData.form_type = "one_click_checkoutapp";
    formData.product = ["KwikCheckout and RTO"];

    axios
      .post(API_BASE_URL + "form/brand", formData)
      .then(function (response) {
        if (
          response.data.status === "ok"
        ) {
          triggerConversion();
          // @ts-ignore: Unreachable code error
          faitracker.call("track", "$form_submitted", { '$email': formData.email, '$first_name': formData.name, '$last_name': formData.name, '$company_name': formData.brand_url });
        }

        setLoading(false);
        setErrors({});
        setFormHasSubmitedSucessfully(true);
        setName(formData?.name);
      })
      .catch(function (error) {
        setLoading(false);
        setErrors(error?.response?.data?.errors);
      });
  };

  const handleRecaptchaSubmit = async () => {
    // Trigger the reCAPTCHA challenge
    recaptchaRef.current.execute();
  };

  const onFormSubmit = (data: any) => {
    setLoading(true);

    data.source = params.get("utm_source");
    data.medium = params.get("utm_medium");
    data.campaign = params.get("utm_campaign");

    setFormData(data);
    handleRecaptchaSubmit();
  };

  return (
    <>
      <div className="turbocharge-form-section" ref={formRef}>
        <div className="form-title">
          <h2>Turbocharge Your D2C Business</h2>
          <p>
            Install Kwik COD app and discover unstoppable growth potential
          </p>
        </div>
        {formHasSubmitedSucessfully ? (
          <Fade direction="right">
            <ThankYou name={name} />
          </Fade>
        ) : (
          <div className="form" >
            <div className="form-wrapper">
              <div>
                <form
                  name="brand"
                  className="onclick-checkoutapp-form"
                  data-faitracker-form-bind="true"
                  onSubmit={handleSubmit(onFormSubmit)}
                >
                  <div className="col-12 mb-3">
                    <div className="form-floating">
                      <input
                        name="name"
                        placeholder="Your Full Name*"
                        type="text"
                        id="floatingInput"
                        className="form-control"
                        required
                        {...register("name")}
                      />
                      <label>Your Full Name*</label>
                    </div>
                    {errors?.name ? (
                      <p className="error-field">{errors?.name[0]}</p>
                    ) : null}
                  </div>
                  <div className="col-12 mb-3">
                    <div className="form-floating">
                      <input
                        name="mobile"
                        placeholder="Your Mobile Number*"
                        type="number"
                        id="floatingInput"
                        className="form-control kwik-mob-number"
                        min="1000000000"
                        max="9999999999"
                        required
                        {...register("mobile")}
                      />
                      <label>Your Mobile Number*</label>
                    </div>
                    {errors?.mobile ? (
                      <p className="error-field">{errors?.mobile[0]}</p>
                    ) : null}
                  </div>
                  <div className="col-12 mb-3">
                    <div className="form-floating">
                      <input
                        name="email"
                        placeholder="Your Email Id*"
                        type="email"
                        id="floatingInput"
                        className="form-control"
                        required
                        {...register("email")}
                      />
                      <label>Your Email Id*</label>
                    </div>
                    {errors?.email ? (
                      <p className="error-field">{errors?.email[0]}</p>
                    ) : null}
                  </div>
                  <div className="col-12 mb-3">
                    <div className="form-floating">
                      <input
                        name="brand_url"
                        placeholder="Your Brand URL*"
                        type="text"
                        id="floatingInput"
                        className="form-control"
                        pattern="(https:\/\/www\.|http:\/\/www\.|https:\/\/|http:\/\/)?[a-zA-Z0-9]{2,}(\.[a-zA-Z0-9]{2,})(\.[a-zA-Z0-9]{2,})?"
                        required
                        {...register("brand_url")}
                      />
                      <label>Your Brand URL*</label>
                    </div>
                    {errors?.brand_url ? (
                      <p className="error-field">{errors?.brand_url[0]}</p>
                    ) : null}
                  </div>
                  <ReCAPTCHA
                    ref={recaptchaRef as React.RefObject<ReCAPTCHA>}
                    sitekey={RECAPTCHA_SITE_KEY}
                    size="invisible"
                    onChange={handleRecaptchaChange}
                  />
                  <button
                    type="submit"
                    className="btn btn-submit"
                    data-faitracker-form-bind="true"
                    disabled={loading}
                  >
                    Book a Demo
                    <RotatingLines
                      strokeColor="white"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="18"
                      visible={loading}
                    />
                  </button>
                </form>

              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default SectionThree;
