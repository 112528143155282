import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { Fade, Zoom, Slide, Rotate } from "react-awesome-reveal";
import { Link } from 'react-router-dom';
import './style.css'
import Subscribe from '@components/subscribe';


const NetworkEffect: React.FC<any> = ({ group, blocks }) => {
    return (
        <>
            <div className="network-wrap network-effect">
                <div className="container-fluid">
                    <div className="row">

                        <div className="col-12 section-header">
                            <Fade direction="down">
                                <h3>{group?.title}</h3>
                                <p>{group?.description}</p>
                            </Fade>
                        </div>
                        
                        <Fade direction='down'>
                        <div className="col-12 items">
                            {blocks?.map((row: any, index) => (
                                <div className="item">
                                        <div className="leftbox">
                                            <Zoom>
                                                <img src={row.file} alt={row.title}/>
                                            </Zoom>
                                        </div>
                                        <div className="rightbox">
                                            <Fade cascade damping={0.5}>
                                                <h4>{row.title}</h4>
                                                <span>{row.content}</span>
                                            </Fade>
                                        </div>
                                </div>
                            ))}  
                        </div>
                        </Fade>

                    </div>
                </div>

               

            </div>

        </>
    )
}
export default NetworkEffect