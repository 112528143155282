import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import SectionOne from './parts/section-one'
import SectionTwo from './parts/section-two'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import BannerFive from '@components/banner-five'
import data from "./data.json"
import { API_BASE_URL } from '@utils/constant'
import SectionThree from './parts/section-three'
import { Helmet } from "react-helmet";
import { convertDangerousHtmlToChildren } from '@utils/helper'

declare global {
	interface Window {
		gtag: any;
	}
}

const Contact = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})

	async function fetchData() {
		fetch(API_BASE_URL + 'page/contact').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();

		window.gtag('event', 'conversion');
	}, []);

	// const convertDangerousHtmlToChildren = node => {
	// 	if (node.props && node.props.dangerouslySetInnerHTML) {
	// 		return {
	// 			...node,
	// 			props: {
	// 				...node.props,
	// 				dangerouslySetInnerHTML: undefined,
	// 				children: node.props.dangerouslySetInnerHTML.__html,
	// 			},
	// 		};
	// 	}
	// 	return node;
	// };

	return (
		<>
			<Helmet >
				{/* <title>GoKwik Contact | Boost Conversions. Reduce RTOs. Unlock Growth</title>
				<meta name="description" content="Contact us today and learn how GoKwik solutions, integrations, and tech stack can help you boost conversions, reduce RTO and increase GMV effectively." /> */}
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'GoKwik Contact | Boost Conversions. Reduce RTOs. Unlock Growth'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "Contact us today and learn how GoKwik solutions, integrations, and tech stack can help you boost conversions, reduce RTO and increase GMV effectively."}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
				{convertDangerousHtmlToChildren(<script
					type="text/javascript"
					dangerouslySetInnerHTML={{
						__html: 'function gtag_report_conversion(n){return gtag("event","conversion",{send_to:"AW-10804082963/WsSBCKfMu4IDEJPy5J8o",event_callback:function(){void 0!==n&&(window.location=n)}}),!1}',
					}}
				></script>)}
			</Helmet>
			<SectionOne></SectionOne>
			<SectionTwo group={result?.groups?.block1} blocks={result?.blocks?.block1}></SectionTwo>
			<SectionThree data={result?.page?.faqs}></SectionThree>
			<SuccesStory customClass=""></SuccesStory>
			<SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>

		</>
	)
}
export default Contact