import React, { useEffect, useRef, useState } from 'react'
import './../style.css'
import SectionOne from './parts/section-one'
import SectionFeatures from './parts/section-features'
import SectionSolutions from './parts/section-solution'
import SectionWorks from './parts/section-works'
import SectionKAI from './parts/section-kai'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import BannerVideo from '@components/banner-video'
import { API_BASE_URL } from '@utils/constant'
import data from './data.json'
import { Helmet } from 'react-helmet'
import IdealPopup from '../kwikchat/parts/ideal-popup'


const KwikAdvantage = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})


	async function fetchData() {
		fetch(API_BASE_URL + 'page/smartcod').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, []);

	return (
		<>
			{/* <Helmet >
				<title>GoKwik Smart COD Suite | Complete Protection Against RTO</title>
				<meta name="description" content="Reduce RTO by upto 40%, increase COD serviceability by 300%, uplift prepaid conversions by 12% and manage business RTO with GoKwik Smart COD Suite. Get it Now!" />
			</Helmet> */}
			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'GoKwik Smart COD Suite | Complete Protection Against RTO'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "Reduce RTO by upto 40%, increase COD serviceability by 300%, uplift prepaid conversions by 12% and manage business RTO with GoKwik Smart COD Suite. Get it Now!"}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>

			<BannerVideo data={result?.page?.banner}></BannerVideo>

			<div className="product-section-one">
				<SectionOne group={result?.groups?.block1} blocks={result?.blocks?.block1}></SectionOne>
				<SectionFeatures blockss={data} group={result?.groups?.block2} blocks={result?.blocks?.block2}></SectionFeatures>

			</div>

			<SectionSolutions group={result?.groups?.block3} blocks={result?.blocks?.block3}></SectionSolutions>

			<SectionWorks group={result?.groups?.block4} blocks={result?.blocks?.block4}></SectionWorks>
			<SectionKAI group={result?.groups?.block5} blocks={result?.blocks?.block5}></SectionKAI>

			<SuccesStory customClass="" category={6}></SuccesStory>
			<SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>

			<IdealPopup
				title={"Learn How To Expand Your COD Presence While Reducing RTO"}
				content={"Use Risk-based Interventions To Expand PAN India COD Serviceability"}
				redirectPath={"/playbook/start-cod-with-gokwik"}
			/>
		</>
	)
}
export default KwikAdvantage