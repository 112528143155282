export const BASE_PATH = "/";
const projectEnv = "prod"; // dev, prod, local
let _API_BASE_URL;
let _IMAGE_PATH_URL;
let _RECAPTCHA_SITE_KEY;
switch (projectEnv) {
    case "dev":
        _API_BASE_URL = "https://admin.gokwik.androappstech.in/api/"
        _IMAGE_PATH_URL = "https://admin.gokwik.androappstech.in"
        // _RECAPTCHA_SITE_KEY = "6LeDdgopAAAAAKKEtqeSXFpnC7ukjmFQLn9AAbli";
        _RECAPTCHA_SITE_KEY = "6LcRq70pAAAAAE6YhfOnQE7IXzCzoh9YrAJljjw0";
        break;

    case "prod":
        _API_BASE_URL = "https://panel.gokwik.co/api/"
        _IMAGE_PATH_URL = "https://panel.gokwik.co"
        _RECAPTCHA_SITE_KEY = "6LevLQcpAAAAAPaeVKSQ-4nBZ9eoEESuJEvs26gT";
        break;

    case "local":
        _API_BASE_URL = "http://localhost/gokwik/api/"
        _IMAGE_PATH_URL = "http://localhost/gokwik"
        _RECAPTCHA_SITE_KEY = "6LcRq70pAAAAAE6YhfOnQE7IXzCzoh9YrAJljjw0";
        break;

    default:
        break;
}

export const API_BASE_URL = _API_BASE_URL;
export const IMAGE_PATH_URL = _IMAGE_PATH_URL;
export const RECAPTCHA_SITE_KEY = _RECAPTCHA_SITE_KEY;
export const WEBSITE_LINK = "https://www.gokwik.co";
// dev details
// export const API_BASE_URL = process.env.REACT_APP_BASE_URL+"/api/"
// export const IMAGE_PATH_URL = process.env.REACT_APP_BASE_URL;
// export const API_BASE_URL = "https://admin.gokwik.androappstech.in/api/"
// export const IMAGE_PATH_URL = "https://admin.gokwik.androappstech.in"
// export const RECAPTCHA_SITE_KEY = "6LeDdgopAAAAAKKEtqeSXFpnC7ukjmFQLn9AAbli";

// local
// export const API_BASE_URL = "http://localhost/gokwik/api/"
// export const IMAGE_PATH_URL = "http://localhost/gokwik"
// export const RECAPTCHA_SITE_KEY = "6LeDdgopAAAAAKKEtqeSXFpnC7ukjmFQLn9AAbli";

// prod details
// export const API_BASE_URL = "https://panel.gokwik.co/api/"
// export const IMAGE_PATH_URL = "https://panel.gokwik.co"
// export const RECAPTCHA_SITE_KEY = "6LevLQcpAAAAAPaeVKSQ-4nBZ9eoEESuJEvs26gT";

// export const API_BASE_URL = process.env.REACT_APP_PUBLIC_URL+"/api/"
// export const IMAGE_PATH_URL = process.env.REACT_APP_PUBLIC_URL
// export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_SITE_KEY;

// export const API_BASE_URL = "https://panel.gokwik.co/api/";
// export const IMAGE_PATH_URL = "https://panel.gokwik.co";

//export const API_BASE_URL = "http://138.197.99.209/api/"

//export const API_BASE_URL = "http://localhost/gokwik/admin/api/"