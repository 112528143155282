import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import SectionOne from './parts/section-one'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import BannerFour from '@components/banner-four';
import { API_BASE_URL } from '@utils/constant';
import { Helmet } from 'react-helmet'
import IdealPopup from '@pages/product/kwikchat/parts/ideal-popup'

const BlogPage = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})
	const [featuredPosts, setFeaturedPosts] = useState<any>([])

	async function fetchData() {
		fetch(API_BASE_URL + 'page/blog').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
					getFeaturedPost(data?.page?.featured);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, []);

	function getFeaturedPost(data = []) {
		let temp = [];
		data.map((row: any, index) => {
			const t = {
				top_heading: "Blogs",
				category: row?.category_name,
				date: row?.published_date,
				heading: row?.title,
				image: row?.featured_image,
				uri: "/blog/" + row?.slug,
				link: "Read More"
			}
			temp.push(t);
		});
		setFeaturedPosts(temp);
	}

	return (
		<>
			{/* <Helmet >
				<title>GoKwik Blogs | Everything Checkout, RTO, And WhatsApp Commerce</title>
				<meta name="description" content="Read the GoKwik blog to get latest tips & industry best practices to increase conversions, reduce RTO, boost customer engagement, and unlock business growth" />
			</Helmet> */}
			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'GoKwik Blogs | Everything Checkout, RTO, And WhatsApp Commerce'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "Read the GoKwik blog to get latest tips & industry best practices to increase conversions, reduce RTO, boost customer engagement, and unlock business growth"}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>
			<BannerFour data={featuredPosts}></BannerFour>
			<SectionOne categories={result?.page?.categories}></SectionOne>
			<SuccesStory customClass=""></SuccesStory>
			<SuperChargedBusiness subscribe={true} subscribePage="Blogs" group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>
			<IdealPopup
				title={"Unlock VIP Access: Join Our Exclusive Newsletter!"}
				content={"Dive into eCommerce Excellence, Discover What's Latest At GoKwik!"}
				subscribePage="Blogs"
			/>

		</>
	)
}
export default BlogPage