import React from "react";
import 'react-circular-progressbar/dist/styles.css';


const SectionFour: React.FC<any> = () => {
    return (
        <>
            <div className="drive-section-five">
                <div className="container-fluid">
                    

                    <div className="col-md-12">
                        <div className="sec-width">
                            <div className="row">
                                <div className="col-md-7 left-side">
                                    <div className="css-xl4503">
                                        <div className="pt-4">
                                            <div className="title">
                                                <h3><span>Pre-fill 85% of shopper addresses</span></h3>
                                            </div>
                                            <div className="descr">
                                                <p>No need to fill lengthy forms. With the largest pool of 100 million+ shoppers, upto 85% of the addresses are prefilled resulting in faster movement from address to payments page.</p>
                                                <ul>
                                                    <li>Reduce drop-offs at address stage by 20%</li>
                                                    <li>Makes the checkout process faster and easier</li>
                                                    <li>Boost customer satisfaction</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 right-side">
                                   <img src="/assets/images/kwikcheckout/shopper-addresses.svg" />
                                </div>

                            </div>
                            
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default SectionFour;