import $ from 'jquery';
import Cookies from 'js-cookie';

export function scrollinit() {
    var scrollDuration = 10;
    var leftPaddle = document.getElementsByClassName('left-paddle');
    var rightPaddle = document.getElementsByClassName('right-paddle');
    var tabWidth = $('.scroll-tabs').innerWidth();

    var getMenuPosition = function () {
        return $('.scroll-tabs').scrollLeft();
    };


    var getScrollWidth = function () {
        var tabs = $('.scroll-tabs');

        var containerEl = tabs[0];
        var scrollbarWidth = containerEl.scrollWidth;
        return scrollbarWidth;
    };

    var menuPosition = getMenuPosition();
    var scrollWidth = getScrollWidth();
    var totalScroll = (tabWidth + menuPosition);

    if (scrollWidth > totalScroll) {
        $('.scroll-tabs').addClass("enable")
        $(rightPaddle).removeClass('hidden');
    }
    else {
        $('.scroll-tabs').removeClass("enable")
    }


    var onScroll = function () {
        //console.log("resize ..on scroll")
        var menuPosition = getMenuPosition();
        var scrollWidth = getScrollWidth();
        var totalScroll = (tabWidth + menuPosition);

        if (menuPosition > 0 && scrollWidth > totalScroll) {
            $(leftPaddle).removeClass('hidden');
            $(rightPaddle).removeClass('hidden');
        }
        else if (menuPosition == 0 && scrollWidth > totalScroll) {
            $(leftPaddle).addClass('hidden');
            $(rightPaddle).removeClass('hidden');
        }
        else if (menuPosition > 0 && scrollWidth < totalScroll) {
            $(leftPaddle).removeClass('hidden');
            $(rightPaddle).addClass('hidden');
        }
    };

    $(window).resize(function () {

        try {
            onScroll();
        }
        catch (err) {
            console.log("scroll errror");
        }
    });

    // finally, what happens when we are actually scrolling the menu
    $('.scroll-tabs').on('scroll', function () {
        onScroll();
    });

    $(rightPaddle).on('click', function () {
        var menuPosition = getMenuPosition();
        var scrollWidth = getScrollWidth();
        var totalScroll = (tabWidth + menuPosition);

        if (scrollWidth < totalScroll + 400) {
            console.log("cap 500")
            $('.scroll-tabs').animate({ scrollLeft: getMenuPosition() + 400 }, scrollDuration);
        }
        else {
            $('.scroll-tabs').animate({ scrollLeft: getMenuPosition() + 250 }, scrollDuration);
        }

    });
    $(leftPaddle).on('click', function () {
        $('.scroll-tabs').animate({ scrollLeft: getMenuPosition() - 250 }, scrollDuration);
    });
}

export function fbScript() {
    // Add Facebook Pixel script dynamically to the document
    var getFbScript = document.getElementById('FBscript');
    if (!getFbScript) {
        const script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('id', 'FBscript');
        script.innerHTML = `fbq('track', 'Lead');`;
        document.body.appendChild(script);
    }
};

export function googleAnalytics() {
    // Add Google Analytics script dynamically to the document
    var getGoogleScript = document.getElementById('GoogleScript');
    if (!getGoogleScript) {
        const script = document.createElement('script');
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('id', 'GoogleScript');
        script.innerHTML = `gtag_report_conversion();`;
        document.body.appendChild(script);
    }
};

export function triggerConversion() {
    // Check the gokwikUtmSource cookie and trigger the appropriate script
    const gokwikUtmSource = Cookies.get('gokwik_utm_source');
    if (gokwikUtmSource === 'fb' || gokwikUtmSource === 'facebook') {
        fbScript();
    } else if (gokwikUtmSource === 'google') {
        googleAnalytics();
    }
};

export function stripHtml(html) {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    let imageName = tmp.textContent || tmp.innerText || "";
    return imageName.trim();
}

export const convertDangerousHtmlToChildren = node => {
    if (node.props && node.props.dangerouslySetInnerHTML) {
        return {
            ...node,
            props: {
                ...node.props,
                dangerouslySetInnerHTML: undefined,
                children: node.props.dangerouslySetInnerHTML.__html,
            },
        };
    }
    return node;
};