import React, { useEffect, useRef, useState } from 'react'
import './../style.css'
import SectionOne from './../common/section-one'
import SectionTwo from './../common/section-two'

import SectionFeatures from './parts/section-features'

import SectionFunnel from './../common/section-funnel'

import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import BannerVideo from '@components/banner-video'
import { API_BASE_URL } from '@utils/constant'
import data from './data.json'
import { Helmet } from 'react-helmet'
import IdealPopup from '../kwikchat/parts/ideal-popup'
import SectionThree from '../common/section-three'
import SectionFour from './parts/section-four'

const KwikCheckout = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})


	async function fetchData() {
		fetch(API_BASE_URL + 'page/kwikcheckout').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, []);

	return (
		<>
			{/* <Helmet >
				<title>GoKwik KwikCheckout | Boost Conversions With One-click Checkout</title>
				<meta name="description" content="Grow your eCommerce business and witness 2X shopper conversions, upto 3X GMV boost and 100% payment success rate. Get GoKwik KwikCheckout Today!" />
			</Helmet> */}
			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'GoKwik KwikCheckout | Boost Conversions With One-click Checkout'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "Grow your eCommerce business and witness 2X shopper conversions, upto 3X GMV boost and 100% payment success rate. Get GoKwik KwikCheckout Today!"}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>
			<BannerVideo data={result?.page?.banner}></BannerVideo>

			<SectionOne group={result?.groups?.block1} blocks={result?.blocks?.block1}></SectionOne>

			<div className="product-section-two">
				<SectionTwo group={result?.groups?.block2} blocks={result?.blocks?.block2}></SectionTwo>
				<SectionFunnel block={result?.blocks?.block3}></SectionFunnel>
				<SectionFeatures blockss={data} group={result?.groups?.block4} blocks={result?.blocks?.block4}></SectionFeatures>

			</div>
			
			<SectionFour />

			<SuccesStory customClass="" category={2}></SuccesStory>
			<SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>
			<IdealPopup
				title={"Explore GoKwik's D2C Discount Engine & Boost Your Brand's Growth"}
				content={"Increase AOV, Prepaid Share Of Wallet, Repeat Purchases & Overall GMV"}
				redirectPath={"/playbook/explore-a-bouquet-of-exclusive-discounts-offers-with-kwikcheckout"}
			/>
		</>
	)
}
export default KwikCheckout