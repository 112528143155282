import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";
import Slider from "react-slick";
import "./style.css";
import { API_BASE_URL } from "@utils/constant";
import BlurryLoadingImage from "@components/lazyimage";


const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    infinite: false,
    slidesToShow: 4.2,
    slidesToScroll: 1,
    initialSlide: 1,
    swipeToSlide: true,
    speed: 500,
    // variableWidth: true,
    draggable: true,
    rtl:false,
    dots: false,
    responsive: [
        {
            breakpoint: 1150,
            settings: {
                slidesToShow: 3.5,
                slidesToScroll: 3,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3.2,
                slidesToScroll: 2,
            }
        },
        {
            breakpoint: 860,
            settings: {
                slidesToShow: 2.8,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2.5,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 576,
            settings: {
                slidesToShow: 2.2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 460,
            settings: {
                slidesToShow: 1.6,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 420,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },
    ]
  };



const LatestBlog: React.FC<{customClass?:string}> = ({customClass})=> {
    const [result, setResult] = useState<any>({})

    async function fetchData(cat=0,p=1,q="") {

        let uri = API_BASE_URL+'blog/'+cat+"/"+p
        if(q!=""){
            const query = new URLSearchParams({q:q}).toString();
            uri= uri+"?"+query
        }

		fetch(uri).then(
			function(response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function(data) {
					setResult(data);
				});
			}
		)
		.catch(function(err) {
			console.log('Fetch Error :-S', err);
		});
	}

    useEffect(()=>{
        fetchData();
    },[])


    return (
        <>
            <div className={customClass?"latest-blog "+customClass:"latest-blog"}>
                <div className="container-fluid">
                    <div className="col-12 inner">
                            <Fade direction="down">
                                <h2 className="section-heading">Blogs</h2>
                            </Fade>
                    </div>
                

                    <div className="col-12 items">
                        <Slider {...settings}>
                        {result?.data?.map((row: any,index1) => (
                            // <Fade direction='right'>
                            
                            <div className="item" key={row.slug}>
                                <Fade cascade damping={0.3}>
                                    {/* <img src={row?.featured_image}/> */}
                                    <Link to={"/blog/"+row.slug}>
                                        <BlurryLoadingImage preview={row?.featured_image} image={row?.featured_image} alt={row?.title}></BlurryLoadingImage>
                                        <p dangerouslySetInnerHTML={{ __html: row.title }}></p>
                                    </Link>
                                    <Link to={"/blog/"+row.slug}>Read More</Link>
                                </Fade>
                            </div>
                           
                            // </Fade>
                        ))}  
                        </Slider>
                    </div>

                </div>
                
            </div>
        </>
    )
}
export default LatestBlog;