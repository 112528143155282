import React, { useEffect, useRef, useState } from 'react'
import './style.css'

import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import { API_BASE_URL } from '@utils/constant'
import data from './data.json'
// import BannerHome from '@components/banner-home'
import SectionMerchantExp from './parts/section-merchant-exp'
import { Helmet } from "react-helmet"



const Shopify = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})
	/*const [bannerData, setBannerData] = useState<any>({})
	const [isLoadfadeSection, toggleFadeSection] = useState(false);*/

	const scrollToForm = () => {
		const reftp = document.getElementById("shopify-contact-form");
		reftp.scrollIntoView({ behavior: "smooth" });
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
		/*setTimeout(() => {
			toggleFadeSection(true);
		}, 3000);*/
	}, []);


	async function fetchData() {
		fetch(API_BASE_URL + 'page/home').then(
			function (response) {
				if (response.status !== 200) {
					//console.log('Looks like there was a problem. Status Code: ' +
					//response.status);
					return;
				}
				response.json().then(function (data) {
					//console.log(data);
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, []);

	return (
		<>
			{/* <Helmet >
				<title>GoKwik | Cookie Policy</title>
				<meta name="description" content="GoKwik Cookie Privacy explains what cookie data Gokwik collects and how it uses it to enhance user experience, product performance and more." />
			</Helmet> */}

			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'GoKwik | Cookie Policy'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "GoKwik Cookie Privacy explains what cookie data Gokwik collects and how it uses it to enhance user experience, product performance and more."}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>

			<div className="cookie-backg">
				<SectionMerchantExp group={data.merchant.group} blocks={data.merchant.blocks} scrollToForm={scrollToForm}></SectionMerchantExp>

			</div>
			
			<SuccesStory customClass="" category={6}></SuccesStory>
			
		</>
	)
}
export default Shopify