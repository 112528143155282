import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import Slider from "react-slick";
import "./style.css"
import BlurryLoadingImage from "@components/lazyimage";


const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    fade: false,
    slide: 'div',
    cssEase: 'linear',
    dots: true,
    arrows:true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    rtl:false,
}


interface Props{
    id: string;
    small_heading: string;
    heading: string;
    description: string;
    button: string;
    image: string;
    uri: string;
}


const BannerThree: React.FC<any> = ({data}) => {
    const navigate = useNavigate();

    function urlTo(row){
        navigate(row.uri);
    }
    return (
        <>
            <div className="banner-three">
                <div className="container-fluid">
                
                <Slider {...settings} className="slick-wrapper">

                    {data?.map((row:any,index) => (
                        
                        <div onClick={() => urlTo(row)}>
                            <div className="row display-flex wrapper">
                                <div className="col-md-6 left">
                                    <h1 dangerouslySetInnerHTML={{ __html: row.heading }}></h1>
                                    <p className="description">{row.description}</p>
                                </div>

                               
                                <div className="col-md-6 rightbox">
                                    <Zoom>
                                        {/* <BlurryLoadingImage preview={row?.image} image={row?.image} alt={row?.heading}></BlurryLoadingImage> */}
                                        <img src={row.image} width="100%" alt={row.heading} style={{ width: "100%" }} /> 
                                    </Zoom>
                                </div>
                            </div>
                        </div>
                       
                    ))}

                  
                </Slider>
                
                </div>
            </div>
        </>
    )
}
export default BannerThree