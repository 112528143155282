import React, { useEffect, useState } from 'react'
import ModalVideo from 'react-modal-video'
import { Fade, Slide } from "react-awesome-reveal";
import Slider from "react-slick";
import data from "./data.json";
import "./style.css"

const duration = 500;

const settings = {
    autoplay: true,
    autoplaySpeed: 5000,
    fade: false,
    slide: 'div',
    cssEase: 'linear',
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 1,


    responsive: [
        {
            breakpoint: 1340,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                initialSlide: 1,
                infinite: true,
                dots: false
            }
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 1,
                infinite: true,
                dots: false,
            }
        },
        {
            breakpoint: 800,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 1,
                infinite: true,
                dots: false,
            }
        },
        {
            breakpoint: 700,
            settings: {
                slidesToShow: 1.6,
                slidesToScroll: 1,
                initialSlide: 1,
                infinite: true,
                dots: false,
            }
        },
        {
            breakpoint: 650,
            settings: {
                slidesToShow: 1.2,
                slidesToScroll: 1,
                initialSlide: 1,
                infinite: true,
                dots: false,
            }
        },
        {
            breakpoint: 530,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                infinite: true,
                dots: false,
            }
        },
    ]
};

const Experts: React.FC<any> = ({setVideoOpen,data}) => {
   
    return (
        <>
          
            <Slider {...settings} className="col-12 experts">
                {data?.map((row:any,index) => (

                    <div key={index}>

                        {row.video ? (

                            <div className="card" style={{ display: "flex" }}>
                                <Fade direction='right' delay={30}>
                                    <div className="card-img" onClick={() => setVideoOpen(row.video)}>
                                        <div className="play-icon"><img src="/assets/images/youtube-play.png" width="76" height="76" alt='Youtube Play'/></div>
                                        {/* <a onClick={() => setVideoOpen(row.video)}>
                                            <img src="/assets/images/video-sim.png" width="100%" alt="" />
                                        </a> */}
                                        
                                        <img src={"https://img.youtube.com/vi/"+row.video+"/mqdefault.jpg"} width="282" height="159" alt="MQ Default" />
                                      
                                    </div>

                                    <div className="flex-between">
                                    <Fade cascade damping={0.3}>
                                        <div className="profile-pic">
                                            <img src={row.file} alt={row.name} width="54" height="54" />
                                        </div>

                                        <div className="jhon">
                                            <h5>{row.name}</h5>
                                            <p>{row.designation}</p>
                                        </div>
                                    </Fade>
                                    </div>
                                </Fade>
                            </div>

                        ) : (

                            <div className="card">
                                <Fade direction='right' delay={0}>
                                    <div className="card-img">
                                        {/* <img src="/assets/images/Composite.png" className="img-fluid slider-img" /> */}
                                        <span className="css-sprite-Composite img-fluid slider-img"></span>
                                    </div>
                                    <p className="slider-p">{row.testimonial}</p>

                                    <div className="flex-between">
                                        
                                        <div className="profile-pic">
                                            <img src={row.file} alt={row.name} width="54" height="54" />
                                        </div>

                                        <div className="jhon">
                                            <h5>{row.name}</h5>
                                            <p>{row.designation}</p>
                                        </div>

                                    </div>
                                </Fade>
                            </div>

                        )}

                    </div>

                ))}
                
            </Slider>

        </>
    )
}
export default Experts