import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { Fade, Zoom, Slide, Rotate } from "react-awesome-reveal";
import { Link } from 'react-router-dom';
import './style.css'
import { Form } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { RotatingLines } from  'react-loader-spinner'
import { API_BASE_URL } from "@utils/constant";
import axios from "axios";
import { useForm, SubmitHandler } from "react-hook-form"


const Subscribe: React.FC<any> = ({ group, blocks, subscribePage }) => {

    const [isFirstForm, setIsFirstForm] = useState(true);
    const [loading,setLoading] = useState(false);
    const { register, handleSubmit, setValue,getValues } = useForm({});

    const [name,setName] = useState("");

    const [validated, setValidated] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errors, setErrors] = useState<any>({});

    const onSubmit: SubmitHandler<any> = async (data) => {
      
        setLoading(true);
        setSuccessMessage("");
        data.subscribe_page = subscribePage;
        

        axios.post(API_BASE_URL+'form/subscribe', data)
          .then(function (response) {
            setLoading(false);
            //console.log(response);
            setErrors({});
            setName(response?.data?.name);
            setSuccessMessage("submitted");
          })
          .catch(function (error) {
            setLoading(false);
            setErrors(error?.response?.data?.errors);
          });

    }


    return (
        <>
            <div className="subscribe">
                <Fade direction='down'>
                <div className="col-12 wrapper">
                    <Form onSubmit={handleSubmit(onSubmit)} validated={validated}>
                        <div className="item">
                            
                            
                            {successMessage && 
                                <h3>Thank You!</h3>
                            }
                            {!successMessage && 
                            <>
                                <h3>Subscribe To Our Newsletter</h3>
                                <div className="popup-item subscribe-form">
                                    <input type="text" className={errors?.name && 'field-error'}  {...register("name")} placeholder="Your Name" required/>
                                    <input type="email" className={errors?.email && 'field-error'}  {...register("email")} placeholder="Your Email" required/>
                                    <input type="text" className={errors?.brand_url && 'field-error'}  {...register("brand_url")} placeholder="Your Brand URL" required />
                                    <button type="submit"  disabled={loading}>
                                        Submit
                                        <RotatingLines strokeColor="white" strokeWidth="5" animationDuration="0.75" width="18" visible={loading}
                                    />
                                    </button>
                                </div>
                            </>
                            }
                        </div>
                    </Form>
                    
                </div>
                </Fade>
            </div>
        </>
    )
}
export default Subscribe