import React, { useEffect, useRef, useState } from 'react'
import '../api-docs/style.css'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import { API_BASE_URL } from '@utils/constant'
import { Helmet } from "react-helmet"
// import MainContent from './main'
import Banner from './banner'


const APIDocs = () => {
    const wasCalled = useRef(false);
    const [result, setResult] = useState<any>({})

    async function fetchData() {
        fetch(API_BASE_URL + 'page/apidoc').then(
            function (response) {
                if (response.status !== 200) {
                    return;
                }
                response.json().then(function (data) {
                    setResult(data);
                });
            }
        )
            .catch(function (err) {
                console.log('Fetch Error :-S', err);
            });
    }

    useEffect(() => {
        if (wasCalled.current) return;
        wasCalled.current = true;
        fetchData();
    }, []);

    return (
        <>
            <Helmet>
                <title>
                    {result?.page?.meta_title ? result?.page?.meta_title : 'GoKwik API Documentation | GoKwik Docs'}
                </title>
                <meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "Welcome to GoKwik API Docs. Find help about GoKwik products and and APIs to help you start using our products quickly."}`} />
                {
                    result?.page?.meta_keyword &&
                    <meta name="keywords" content={result?.page?.meta_keyword}></meta>
                }
            </Helmet>
            {/* <MainContent /> */}
            <Banner data={result?.page?.banner} />

            <SuccesStory customClass=""></SuccesStory>
            <SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>

        </>
    )
}
export default APIDocs