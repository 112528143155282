import React from "react";
import 'react-circular-progressbar/dist/styles.css';


const SectionEight: React.FC<any> = () => {
    return (
        <>
            <div className="drive-section-five robust">
                <div className="container-fluid">


                    <div className="col-md-12">
                        <div className="sec-width">
                            <div className="row">
                                <div className="col-md-6 left-side">
                                    <div className="css-xl4503">
                                        <div className="pt-4">
                                            <div className="title">
                                                <h3><span>Get insightful dashboard and
                                                    seamless reporting</span></h3>
                                            </div>
                                            <div className="descr">
                                                <p>Understand your customers' purchasing behaviour to make informed decisions</p>
                                                <ul>
                                                    <li>Real-time checkout conversion rates</li>
                                                    <li>Total orders processed & total units sold</li>
                                                    <li>Average order value updates</li>
                                                    <li>Overview of sales over time</li>
                                                    <li>Real-time RTO insights, cash to prepaid conversions, AWB status & more.</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 right-side">
                                    <img src="/assets/images/kwikcheckout/seamless-reporting.svg" />
                                </div>
                                <div className="protect">
                                    <div className="row protect-align">
                                        <div className="col-md-9">
                                            <div className="content">
                                                <h4>Enable robust RTO protection & 3X your COD GMV</h4>
                                                <p>Intent based intelligent checkout & RTO solution to boost profit margins</p>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <a href="/contact" target="_blank" data-faitracker-form-bind="true">Get Your FREE Demo</a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default SectionEight;