import React, { useEffect, useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form"
import { Form, InputGroup, Button } from 'react-bootstrap';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import { API_BASE_URL, RECAPTCHA_SITE_KEY } from "@utils/constant";
import axios from "axios";
import { Fade } from "react-awesome-reveal";
import { RotatingLines } from 'react-loader-spinner'
import ReCAPTCHA from "react-google-recaptcha";
import Cookies from "js-cookie";
import { triggerConversion } from "@utils/helper";
import ThankYou from "@pages/contact/parts/thank-you";

const GrowthForm: React.FC<any> = (props) => {
    const { params } = props;
    const [loading, setLoading] = useState(false);
    const { register, handleSubmit, setValue, getValues } = useForm({});
    const recaptchaRef = React.useRef<ReCAPTCHA>();

    const [name, setName] = useState("");


    const [validated, setValidated] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errors, setErrors] = useState<any>({});
    const [formData, setFormData] = useState<any>(null);

    // Analytics
    useEffect(() => {
        const utmSource = new URL(window.location.href).searchParams.get('utm_source');
        if (utmSource && utmSource.trim() !== '') {
            Cookies.set('gokwik_utm_source', utmSource.toLowerCase(), {
                expires: 30,
            });
        }
    }, []);

   

    const handleRecaptchaChange = (value) => {
        formData.g_recaptcha_token = value;
        formData.form_type = "one_click_checkout";
        formData.product = ["KwikCheckout and RTO"];
        axios.post(API_BASE_URL + 'form/brand', formData)
            .then(function (response) {
                if(response.data.status === "ok") {
                    triggerConversion();
                }
                // console.log(response)
                setLoading(false);
                //console.log(response);
                setErrors({});
                setName(formData?.name);
                setSuccessMessage("submitted");
            })
            .catch(function (error) {
                setLoading(false);
                setErrors(error?.response?.data?.errors);

            });
    };

    const handleRecaptchaSubmit = async () => {
        // Trigger the reCAPTCHA challenge
        recaptchaRef.current.execute();
    };

    const onSubmit: SubmitHandler<any> = async (data) => {
        //console.log("data : ",data)
        setLoading(true);
        setSuccessMessage("");

        data.source = params.get("utm_source")
        data.medium = params.get("utm_medium")
        data.campaign = params.get("utm_campaign")

        setFormData(data);
        handleRecaptchaSubmit();

    }

    return (

        <div className="form">
           
            <div className="form-wrapper">
                <div className="form-title">
                    <h2>Unlock 10X growth</h2>
                    <p>Talk to our experts</p>
                </div>
                {successMessage &&
                    <Fade direction="right">
                        <ThankYou name={name} />
                    </Fade>
                }
                {!successMessage &&
                    <Fade direction="right">
                        <Form onSubmit={handleSubmit(onSubmit)} noValidate validated={validated} name="brand" >


                            <div className="col-12 mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Your Full Name*"
                                >
                                    <Form.Control
                                        {...register("name")}
                                        className={errors?.name ? 'is-invalid' : ''}
                                        type="text"
                                        placeholder="Your Full Name*"
                                    />
                                    {errors?.name &&
                                        <Form.Control.Feedback type="invalid">{errors?.name[0]}</Form.Control.Feedback>
                                    }
                                </FloatingLabel>

                            </div>

                            <div className="col-12 mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Your Mobile Number*"
                                >
                                    <Form.Control
                                        {...register("mobile")}
                                        className={errors?.mobile ? 'is-invalid' : ''}
                                        type="tel"
                                        maxLength={10}
                                        onKeyUp={(e) => setValue("mobile", getValues('mobile').replace(/[^0-9]/g, ''))}
                                        placeholder="Your Mobile Number*"
                                    />

                                    {errors?.mobile &&
                                        <Form.Control.Feedback type="invalid">{errors?.mobile[0]}</Form.Control.Feedback>
                                    }
                                </FloatingLabel>

                            </div>

                            <div className="col-12 mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Your Email Id"
                                >
                                    <Form.Control
                                        {...register("email")}
                                        className={errors?.email ? 'is-invalid' : ''}
                                        type="text"
                                        placeholder="Your Email Id"
                                    />

                                    {errors?.email &&
                                        <Form.Control.Feedback type="invalid">{errors?.email[0]}</Form.Control.Feedback>
                                    }
                                </FloatingLabel>

                            </div>

                            <div className="col-12 mb-3">
                                <FloatingLabel
                                    controlId="floatingInput"
                                    label="Your Brand URL*"
                                >
                                    <Form.Control
                                        {...register("brand_url")}
                                        className={errors?.brand_url ? 'is-invalid' : ''}
                                        type="text"
                                        placeholder="Your Brand URL*"
                                    />

                                    {errors?.brand_url &&
                                        <Form.Control.Feedback type="invalid">{errors?.brand_url[0]}</Form.Control.Feedback>
                                    }
                                </FloatingLabel>
                            </div>

                            
                            <ReCAPTCHA
                                ref={recaptchaRef as React.RefObject<ReCAPTCHA>}
                                sitekey={RECAPTCHA_SITE_KEY}
                                size="invisible"
                                onChange={handleRecaptchaChange}
                            />

                            <button type="submit" className="btn btn-submit" disabled={loading}>
                                Submit
                                <RotatingLines strokeColor="white" strokeWidth="5" animationDuration="0.75" width="18" visible={loading}
                                />
                            </button>

                        </Form>
                    </Fade>
                }
            </div>

        </div>
    );
}
export default GrowthForm;