import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import Slider from "react-slick";
import SectionThree from "./section-three";
import BlurryLoadingImage from "@components/lazyimage";

const settings = {
    autoplay: true,
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 2.35,
    slidesToScroll: 1,
    speed: 500,
    responsive: [
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                initialSlide: 1,
                infinite: true,
                dots: false
            }
        },
    ]
  };

const SectionTwo: React.FC<any> = (props)=> {
    const {data,group,blocks,achievements,children} = props;

    const [delay,setDelay] = useState(500)

    setTimeout(function(){
        setDelay(0)
    }, 1500);


    return (
        <>
           
            <div className="section-two">
                <div className="container-fluid">
                    <div className="col-12 inner">
                            <Fade direction="down">
                                <h2 className="section-heading" dangerouslySetInnerHTML={{__html:group?.title}}></h2>
                                <p className="section-desc">{group?.description}</p>
                            </Fade>
                    </div>
                </div>
                <div className="col-12 slider">
               
                    <Slider {...settings}>
                    {blocks?.block2?.map((row:any , index) => (
                        <div className="box-slider">
                            <div className="item">
                                <Zoom>
                                <BlurryLoadingImage preview={row?.file} image={row?.file} alt={row?.title}></BlurryLoadingImage>
                                </Zoom>
                                {/* <img src={row.file} alt={row.title}/> */}
                                <p>{row.title}</p>
                            </div>
                        </div>
                    ))}
                        
                    </Slider>
                </div>
                     
                <SectionThree block={blocks?.block3} achievements={achievements}></SectionThree>
            </div>
        </>
    )
}
export default SectionTwo