import React, { useRef } from "react";
import "./styles/style.css";
import SectionOne from "./parts/section-one";
import SectionTwo from "./parts/section-two";
import SectionThree from "./parts/section-three";
import { Helmet } from "react-helmet";
import { API_BASE_URL, WEBSITE_LINK } from "@utils/constant";
import axios from "axios";


function KwikCheckoutCod() {
  const formRef = useRef(null);

  const scrollToTheForm = () => {
    formRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const params = new URLSearchParams(window.location.search);

  function trackTriggerData(triggerButton: number) {
    let data: any = {
      trigger_button: triggerButton,
      source: params.get("utm_source"),
      medium: params.get("utm_medium"),
      campaign: params.get("utm_campaign")
    }

    axios
      .post(API_BASE_URL + 'checkoutapp/track', data)
      .then(function (response) {
      })
      .catch(function (error) {
      });
  }

  const redirectToShopify = (triggerButton: number) => {
    trackTriggerData(triggerButton)
    let url = 'https://apps.shopify.com/kwikcheckout'; // Replace with your desired URL
    if (window.location.search) {
      url += window.location.search;
    }
    window.open(url, '_blank');
  };

  return (
    <>
      <Helmet>
        <title>Boost Shopify Sales with Advanced Cash on Delivery App</title>
        <meta
          name="description"
          content="Boost your Shopify store with our advanced COD app. Hide/enable COD, verify intent at checkout, & increase prepaid conversions. Best COD King & Payfy alternative."
        />
        <meta
          name="keywords"
          content="cash on delivery app, advanced cash on delivery, cod shopify, add cash on delivery, shopify cash on delivery app, enable cod in shopify, pay on delivery shopify, cod king"></meta>

        <link rel="canonical" href={`${WEBSITE_LINK}/kwikcheckoutapp`} />

      </Helmet>
      <SectionOne scrollToTheForm={scrollToTheForm} redirectToShopify={redirectToShopify} />
      <SectionTwo scrollToTheForm={scrollToTheForm} redirectToShopify={redirectToShopify} />
      <SectionThree formRef={formRef} />
    </>
  );
}

export default KwikCheckoutCod;
