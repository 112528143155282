import React from "react";
import 'react-circular-progressbar/dist/styles.css';


const SectionSix: React.FC<any> = (props) => {
    return (
        <>
            <div className="drive-section-five">
                <div className="container-fluid">


                    <div className="col-md-12">
                        <div className="sec-width">
                            <div className="row">
                                <div className="col-md-7 left-side">
                                    <div className="css-xl4503">
                                        <div className="pt-4">
                                            <div className="title">
                                                <h3><span>Get end-to-end RTO protection
                                                    via Smart COD Suite</span></h3>
                                            </div>
                                            <div className="descr">
                                                <p>Implement a fast and easy checkout process with RTO safeguarding</p>
                                                <ul>
                                                    <li>Enable COD confirmation & verification prompts</li>
                                                    <li>Hide COD for high-risk users</li>
                                                    <li>Enable industry's first COD - Prepaid split payment option (Partial COD payment)</li>
                                                    <li>Provide additional prepaid discounts for high & medium risk shoppers</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 right-side">
                                    <img src="/assets/images/kwikcheckout/Smart-COD-Suite.svg" />
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default SectionSix;