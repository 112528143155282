import React, { useEffect, useState } from 'react';
import { RotatingLines } from 'react-loader-spinner'

export const MyLoader = () => (
  <>
    <RotatingLines
      strokeColor="white"
      strokeWidth="5"
      animationDuration="0.75"
      width="96"
      visible={true}
    />
  </>
)
export const MySmallLoader = () => (
  <>
    <RotatingLines
      strokeColor="white"
      strokeWidth="5"
      animationDuration="0.75"
      width="15"
      visible={true}
    />
  </>
)

export const PageLoader = () => {
  return (
    <div className="loader-container">
      <div className="sound-wave">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

const BlurryLoadingImage = ({
  preview,
  image,
  alt = "",
  imageStyleClass = "",
  divStyleClass = "",
  bgColor = 'transparent',
  onClick = null,
}) => {
  const [currentImage, setCurrentImage] = useState(preview);
  const [loading, setLoading] = useState(true);

  const fetchImage = (src) => {
    const loadingImage = new Image();
    loadingImage.src = src;
    loadingImage.onload = () => {
      setCurrentImage(loadingImage.src);
      setLoading(false);
    };
  };

  useEffect(() => {
    fetchImage(image);
  }, [preview]);

  return (

    <div className={divStyleClass} style={{ overflow: 'hidden' }}>
      <img
        onClick={onClick}
        style={{
          filter: `${loading ? 'blur(20px)' : ''}`,
          transition: '1s filter linear',
          // maxWidth: '100%',
          background: bgColor,
        }}
        src={currentImage}
        alt={alt}
        className={imageStyleClass}
      />
    </div>

  );
};

export default BlurryLoadingImage;

