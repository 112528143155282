import React, { useEffect,useRef, useState } from 'react';
import { render } from 'react-dom';
import Scroll from 'react-scroll';
import { Fade, Zoom } from "react-awesome-reveal";
import $ from 'jquery';
import VisibilitySensor from 'react-visibility-sensor';
import { gsap } from "gsap";


const ItemSection:React.FC<any> = ({onChange,id,data}) => {

    return (
        
        <VisibilitySensor onChange={(e) => onChange(e,id)}>
          <div className={(id===0)?"item active":"item"} id={"item"+id}>

              <div className="col-12 leftbox">
                <div className={"mobile-img-frame"}>
                    <img src={data.file} alt={data.title} />
                </div>
                
                {/* <Fade cascade damping={0.5}> */}
                    <h4 dangerouslySetInnerHTML={{__html:data?.title}}></h4>
                    <p dangerouslySetInnerHTML={{__html:data?.content}}></p>
                {/* </Fade> */}

                  
              </div>
            
          </div>
        </VisibilitySensor>
        
    )
    
}


const Section: React.FC<any> = ({data})=> {
  const [lastItem, setLastItem] = useState<any>(null)
  
  let timeout1,timeout2,timeout3,timeout4;
  function onChange(e,item){
    if(timeout1){
      clearTimeout(timeout1);
    }
    if(timeout2){
      clearTimeout(timeout2);
    }
    if(timeout3){
      clearTimeout(timeout3);
    }
    if(timeout4){
      clearTimeout(timeout4);
    }
    
    // console.log("on change....",e,item)
    if(e===false || lastItem === item)
        return;

    
    //console.log("on change....",e,item)
    setLastItem(item);
    
    if(item == 0){
      // $(".img-wrapper").addClass("img-wrapper-big");
      $(".img-wrapper").removeClass("img-wrapper-big");
    }
    else{
      $(".img-wrapper").removeClass("img-wrapper-big");
    }

    const top = $("#product-features").offset().top; 

    //if(window.scrollY < t1.offset().top) {
     //if(top+100 <  t1.offset().top) {

      $(".item-frame").removeClass("active");
      $(".item").removeClass("active");

      
      if(item === 4){
        const t1 = $("#item4");
        t1.addClass("active");

        const frame1 = $("#item-s1-frame"); 
        const frame2 = $("#item-s2-frame"); 
        const frame3 = $("#item-s3-frame"); 
        const frame4 = $("#item-s4-frame");
        const frame5 = $("#item-s5-frame");

        const tl = gsap.timeline();

        frame1.addClass("active");
        tl.fromTo(frame1, {right: -228,duration:0,opacity:0}, {right:12,duration:0.5,opacity:1});

        timeout1 = setTimeout(()=>{
          $(".item-frame").removeClass("active");
          frame2.addClass("active");
          tl.fromTo(frame2, {right: -228,duration:0,opacity:0}, {right:12,duration:0.5,opacity:1});  
        },2000);

        timeout2 = setTimeout(()=>{
          $(".item-frame").removeClass("active");
          frame3.addClass("active");
          tl.fromTo(frame3, {right: -228,duration:0,opacity:0}, {right:12,duration:0.5,opacity:1});
        },4000);

        timeout3 = setTimeout(()=>{
          $(".item-frame").removeClass("active");
          frame4.addClass("active");
          tl.fromTo(frame4, {right: -228,duration:0,opacity:0}, {right:12,duration:0.5,opacity:1});
        },6000);

        timeout4 = setTimeout(()=>{
          $(".item-frame").removeClass("active");
          frame5.addClass("active");
          tl.fromTo(frame5, {right: -228,duration:0,opacity:0}, {right:12,duration:0.5,opacity:1});
        },8000);

      }
      else{
        const t = $("#item"+item+"-frame"); 
        const t1 = $("#item"+item);

        t.addClass("active");
        t1.addClass("active");
  

        const tl = gsap.timeline();
        tl.fromTo(t, {right: -228,duration:0,opacity:0}, {right:12,duration:0.5,opacity:1});  
      }
  }

    return (
      <>
        {data?.map((row:any , index) => (
          <ItemSection onChange={onChange} id={index} data={row}></ItemSection>
        ))}
      </>
    );
};

export default Section;


