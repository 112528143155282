import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { scrollinit } from "@utils/helper";
import ResponsiveYoutube from "../youtube";
import parse from 'html-react-parser';
import { renderToString } from "react-dom/server";


import {
    FacebookShareButton,
    InstapaperShareButton,
    LinkedinShareButton,
    TwitterShareButton
} from "react-share";



const replace = (domNode) => {
    // console.log("don : ",domNode)
    if (domNode.name === "youtube") {
        return (
            <ResponsiveYoutube id={domNode.attributes[0]?.value}></ResponsiveYoutube>
        );
    }
};

const SectionDetails: React.FC<any> = (props) => {
    const { data } = props;
    let content;

    const share_url = window.location.href;

    if (data?.content != undefined) {
        // console.log("content : ", data)
        // const reg = /\[youtube id=.*\]/
        const reg = /\[youtube id="([^"]+)"\]/;
        const temp = data.content.match(reg);
        if (temp != null) {
            const new_youtube = temp[0].replace("[", "<").replace("]", ">");
            data.content = data.content.replace(temp[0], new_youtube);
        }

        content = parse(data.content, { replace })
    }



    return (
        <>
            <div className="blog-section-details">
                <div className="container-fluid">
                    <div className="col-12 blog-content">
                        <p className="category">{data?.category_name} <span>- {data?.published_date}</span></p>
                        <h1 dangerouslySetInnerHTML={{ __html: data?.title }}></h1>

                        <ul className="social">
                            {/* <li><a href="https://www.facebook.com/GoKwikCo" target="_blank"><i className="fa-brands fa-square-facebook"></i></a></li> */}
                            {/* <li><a href="https://www.instagram.com/gokwik.co" target="_blank"><i className="fa-brands fa-instagram"></i></a></li> */}
                            {/* <li><a href="https://www.linkedin.com/company/gokwik" target="_blank"><i className="fa-brands fa-linkedin"></i></a></li>
                            <li><a href="https://twitter.com/gokwik" target="_blank"><i className="fa-brands fa-x-twitter"></i></a></li> */}
                            <li>
                                <FacebookShareButton
                                    url={share_url}
                                    title={data?.title}>
                                    <i className="fa-brands fa-square-facebook"></i>
                                </FacebookShareButton>
                            </li>
                            <li>
                                <LinkedinShareButton
                                    url={share_url}
                                    title={data?.title}>
                                    <i className="fa-brands fa-linkedin"></i>
                                </LinkedinShareButton>
                            </li>

                            <li>
                                <TwitterShareButton
                                    url={share_url}
                                    title={data?.title}
                                >
                                    <i className="fa-brands fa-x-twitter"></i>
                                </TwitterShareButton>
                            </li>
                        </ul>

                        <img className="featured-image" src={data?.featured_image} alt={data?.alt} />

                        {content && content}
                        <div className="col-12">
                            <div style={{ float: 'right', marginBottom: '20px' }}>
                                {/* <span>Author</span> */}
                                {(data?.show_author === 1 && data?.author_name) && <h5>Author: {data?.author_name}</h5>}
                            </div>
                        </div>
                        <div className="col-12 blog-share">
                            <p>Enjoying this article? Share it with the world!</p>
                            <ul className="social">
                                <li>
                                    <FacebookShareButton
                                        url={share_url}
                                        title={data?.title}>
                                        <i className="fa-brands fa-square-facebook"></i>
                                    </FacebookShareButton>
                                </li>

                                {/* <li>
                                    <InstapaperShareButton
                                        url={share_url}
                                        title={data?.title}
                                    >
                                        <i className="fa-brands fa-instagram"></i>
                                    </InstapaperShareButton>
                                </li> */}

                                <li>
                                    <LinkedinShareButton
                                        url={share_url}
                                        title={data?.title}>
                                        <i className="fa-brands fa-linkedin"></i>
                                    </LinkedinShareButton>
                                </li>

                                <li>
                                    <TwitterShareButton
                                        url={share_url}
                                        title={data?.title}
                                    >
                                        <i className="fa-brands fa-x-twitter"></i>
                                    </TwitterShareButton>
                                </li>
                            </ul>
                        </div>




                    </div>
                </div>
            </div>
        </>
    )
}
export default SectionDetails