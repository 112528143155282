import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';


const ThankYou: React.FC<any> = (props) => {
    const { name } = props;
   
    return (
        <div className="thank-you">
            <img src="/assets/images/thank-you.svg" alt="Thank you"/>
            <h1>Thank You</h1>
            <p>Hi <strong>{name},</strong></p>
            <p>Thank you for contacting Gokwik.</p>
            <p>We have registered your interest and will get back to you within 72 hours.</p>
        </div>
    );
}
export default ThankYou;