import React from "react";
import { Fade, Slide } from "react-awesome-reveal";
import BlurryLoadingImage from "@components/lazyimage";

const Banner: React.FC<any> = ({data}) => {

    return (
        <>
            <div className="banner-home banner-five">
                <div className="container-fluid">

                <Slide direction="left" delay={10} triggerOnce>

                        <div className="row display-flex">
                        <Fade direction="left" delay={100} className="col-md-6" triggerOnce>
                                <div className="col-12 left">
                                    <Fade cascade damping={0.5}>
                                        <h1>API Docs</h1>
                                        <p className="home-p">Unlock powerful integrations. Submit your queries to integration@gokwik.co and get started with our API docs.</p>
                                    </Fade>
                                </div>
                            </Fade>
                           
                        </div>

                    </Slide>
                </div>

                {data && 
                <Fade direction='down' delay={1000} className="fixed-right-img">
                    <BlurryLoadingImage preview={data?.file} image={data?.file} alt={data?.title}></BlurryLoadingImage>
                </Fade>
                }
            </div>
        </>
    )
}
export default Banner