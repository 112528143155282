import React, { useEffect, useState } from "react";
const ResponsiveYoutube:React.FC<any> = ({ id }) => {

    return (
      <div
        className="video"
        style={{
          position: "relative",
          paddingBottom: "56.25%" /* 16:9 */,
          paddingTop: 25,
          height: 0,
          marginBottom: 40,
          marginTop: 40
        }}
      >
        <iframe
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%"
          }}
          src={`https://www.youtube.com/embed/${id}`}
          frameBorder="0"
        />
      </div>
    );
  };

  export default ResponsiveYoutube