import BannerFive from '@components/banner-five'
import { Helmet } from "react-helmet";


const InvestorPage = () => {
	return (
		<>
			<Helmet >
				<title>GoKwik Investors | Backing Superfast GoKwik's Growth</title>
				<meta name="description" content="GoKwik is backed by many leading investors that are helping us scale high and quicker, and in the right direction." />
			</Helmet>
			<BannerFive data={{ title: "Coming Soon ..." }}></BannerFive>
		</>
	)
}
export default InvestorPage