
import "../styles/style.css";


const SectionTwo = ({ scrollToTheForm, redirectToShopify }) => {
    return (
        <>
            <div className="checkooutcod-section-two">
                <div className="container-fluid">

                    <div className="col-12 inner">
                        <h2 className="section-heading">Install and start using COD suite in 60 seconds!</h2>
                        <p>Enable COD app on Shopify and access smart features from Day 1</p>
                    </div>
                    <div className="col-md-12">
                        <div className="sec-width">
                            <div className="row">
                                <div className="col-md-7 left-side">
                                    <div className="css-xl4503">
                                        <div className="pt-4">
                                            <div className="title">
                                                <h3><span>Smart COD interventions</span></h3>
                                            </div>
                                            <div className="descr">

                                                <ul>
                                                    <li>Offer/hide COD on 10+ parameters</li>
                                                    <li>Verify intent using COD captcha, COD fees, COD OTP and confirmation prompt</li>
                                                    <li>Add shipping fees based on weight, price, product, state & more</li>
                                                    <li>Customize COD by renaming/ reordering shipping methods</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 right-side">
                                    <img src="/assets/images/kwik-checkout-cod/smartcod.svg" />
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-7 left-side">
                                    <div className="css-xl4503">
                                        <div className="pt-4">
                                            <div className="title">
                                                <h3><span>Offer exclusive discounts</span></h3>
                                            </div>
                                            <div className="descr">

                                                <ul>
                                                    <li>Buy X Get Y discounts</li>
                                                    <li>Tiered discounts on product quantity, order value etc</li>
                                                    <li>Capping based discounts</li>
                                                    <li>Free gifts with products</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 right-side">
                                    <img src="/assets/images/kwik-checkout-cod/offer-exclusive.svg" />
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div className="smart-cod-offer">
                    <div className="container-fluid">
                        <div className="offer-img">
                            <img src="/assets/images/kwik-checkout-cod/banner_custom.svg" />

                        </div>
                        <div className="smart-cod-container">
                            <div className="row">
                                <div className="col-md-12 smart-cod-text text-center">
                                    {/* <div className="smart-cod-onheader">EXCLUSIVE OFFER</div> */}
                                    <h2 className="smart-cod-paragraph">
                                        COD orders completely free for first 3 months
                                    </h2>
                                    <p>Reduce RTO and achieve a 50% uplift in prepaid orders.</p>
                                    <button className="smart-cod-offer-button" onClick={() => redirectToShopify(2)}>Free Install on {'  '}<img src="/assets/images/kwik-checkout-cod/shopify.png" alt="" width={20} /> {' '}Shopify</button>
                                </div>
                                {/* <div className="col-md-5 smart-cod-button-container">

                                    <button className="smart-cod-offer-button" onClick={() => redirectToShopify(2)}>Free Install on {'  '}<img src="/assets/images/kwik-checkout-cod/shopify.png" alt="" width={20} /> {' '}Shopify</button>
                                    <button className="smart-cod-offer-button" onClick={scrollToTheForm}>Book a Free Demo</button>
                                </div> */}
                            </div>
                        </div>
                    </div>

                </div>

                <div className="container-fluid">
                    <div className="col-12 inner">
                        <h2 className="section-heading">Upgrade to boost conversions with one-click checkout suite</h2>
                        <p>Complete KYC to access all features of the KwikCheckout app</p>
                    </div>

                    <div className="col-md-12">
                        <div className="one-click-checkout-suite">
                            <div className="row">
                                <div className="col-md-7 left-side">
                                    <div className="css-xl4503">
                                        <div className="pt-4">
                                            <div className="title">
                                                <h3><span>Convert up to 40% more shoppers while reducing RTO</span></h3>
                                            </div>
                                            <h4 className="py-4">Implement a fast and easy checkout process with RTO safeguarding with shopify cash on delivery app </h4>
                                            <div className="descr">

                                                <ul>
                                                    <li>Discounts on all prepaid payment methods</li>
                                                    <li>Risk based RTO features during & after checkouts</li>
                                                    <li>Offer partial COD to reduce RTO</li>
                                                    <li>Offer bulk and prepaid discounts</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 right-side">
                                    <img src="/assets/images/kwik-checkout-cod/shopper-rto.svg" />
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="one-click-checkout-suite">
                            <div className="row reverse-mobile-section">
                                <div className="col-md-5 right-side">
                                    <img src="/assets/images/kwik-checkout-cod/payment-suit.svg" />
                                </div>
                                <div className="col-md-7 left-side">
                                    <div className="css-xl4503">
                                        <div className="pt-4">
                                            <div className="title">
                                                <h3><span>Drive trust with
                                                    extensive & secured payment suite</span></h3>
                                            </div>
                                            <h4 className="py-4">Provide a secure & frictionless payment experience for your shoppers with advanced cash on delivery app</h4>
                                            <div className="descr">

                                                <ul>
                                                    <li>Extensive payment options</li>
                                                    <li>Personalised recommendations</li>
                                                    <li>100% payment assurance</li>
                                                    <li>Exclusive partnerships to provide unique card offers</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                </div>

            </div>
            <div className="smart-cod-offer">
                <div className="container-fluid">
                    <div className="smart-cod-container cod-botom">
                        <div className="row">
                            <div className="col-md-12 smart-cod-text">
                                {/* <div className="smart-cod-onheader">EXCLUSIVE OFFER</div> */}
                                <h2 className="smart-cod-paragraph">
                                    Unlock the power of smart COD with a limited period offer
                                </h2>
                                <p>COD orders completely free for first 3 months</p>
                                <button className="smart-cod-offer-button" onClick={() => redirectToShopify(3)}>Free Install on {'  '}<img src="/assets/images/kwik-checkout-cod/shopify.png" alt="" width={20} />{'  '} Shopify</button>
                            </div>
                            {/* <div className="col-md-5 smart-cod-button-container">

                                <button className="smart-cod-offer-button" onClick={() => redirectToShopify(3)}>Free Install on {'  '}<img src="/assets/images/kwik-checkout-cod/shopify.png" alt="" width={20} />{'  '} Shopify</button>
                                <button className="smart-cod-offer-button" onClick={scrollToTheForm}>Book a Free Demo</button>
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SectionTwo;