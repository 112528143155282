import { API_BASE_URL } from "@utils/constant";
import axios from "axios";
import moment from "moment";
import { ChangeEvent, useEffect, useRef, useState } from "react"
import { Fade } from "react-awesome-reveal";
import { useLocation } from "react-router-dom";

const ThankYou: React.FC<any> = (props) => {
    const { name } = props;

    return (
        <div className="thank-you">
            <h1>Thank You</h1>
            <p>Hi <strong>{name},</strong></p>
            <p>Thank you for showing Interest. We will send out more details shortly!</p>
        </div>
    );
}

export const EventProgram = ({events}: any) => {
    // const [events, setEvents] = useState([]);
    const [showEventForm, setEventForm] = useState(true);
    const [formData, setFormData] = useState(null);
    const [errors, setErrors] = useState<any>({});
    const [shopOtpSection, toggleOTPSection] = useState(false);
    const [otp, setOTP] = useState(null);
    const [email, setEmail] = useState(null);
    const [loading, setLoading] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [name, setName] = useState(null);
    const location = useLocation();

    const scrollableRef = useRef<any>();

    /*const getEvents = () => {
        axios.get(API_BASE_URL + 'event/program')
            .then(function (response) {
                setEvents(response.data)
            })
            .catch(function (error) {
                console.log("Error!!")
            });
    }*/

    const handleEventForm = (event: any) => {
        if (event.status) {
            setEventForm(true);
            setSelectedEvent(event)
        }
    }

    const inputChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        if(name === "mobile") {
            const mobile = value.replace(/[^0-9]/g, '');
            setFormData(prevState => {
                return { ...prevState, [name]: mobile }
            })
        }else {
            setFormData(prevState => {
                return { ...prevState, [name]: value }
            })
        }
    }

    const handleChangeOTP = (event: ChangeEvent<HTMLInputElement>) => {
        setOTP(event.target.value);
    }

    const submitHandler = (event: any) => {
        event.preventDefault();
        axios.post(API_BASE_URL + 'event-program/subscribe', { ...formData, program_id: selectedEvent?.id })
            .then(function (response) {
                setEmail(formData.email);
                setLoading(false);
                setErrors({});
                setName(formData?.name)
                // toggleOTPSection(true);
                setFormData(null)
                setFormSubmitted(true);
            })
            .catch(function (error) {
                setLoading(false);
                setErrors(error?.response?.data?.errors);

            });
    }

    const verifyOTP = () => {
        axios.post(API_BASE_URL + 'event-program/subscribe/verifyotp', { otp, program_id: selectedEvent?.id, email })
            .then(function (response) {
                setLoading(false);
                setErrors({});
                toggleOTPSection(false);
                setOTP(null)
            })
            .catch(function (error) {
                setLoading(false);
                setErrors(error?.response?.data?.errors);

            });
    }

    useEffect(() => {
        if(events) {
            setTimeout(()=>{
                console.log(location)
                if(location.hash == "#kwikmixer") {
                    scrollToKwikmixer();
                }
            },1000)
            const event = events.find((event: any) => event.status === 1);
            setSelectedEvent(event);
        }
    }, [events])

    const scrollToKwikmixer = () => {
        const scrollableElement = scrollableRef.current;
        if (scrollableElement) {
          // Use scrollIntoView with behavior 'smooth' to achieve smooth scrolling
          scrollableElement.scrollIntoView({ behavior: 'smooth' });
        }
      };

    useEffect(() => {
        // getEvents();
    }, [])
    return (
        <div className="col-12 tab-one" ref={scrollableRef} id="kwikmixer">
            <div className="row">
                {
                    events && events.map((event: any) => {
                        return (
                            <div className="col-md-6 kwik-img" key={event.id}>
                                <div className="kwik-gallery">
                                    <div className={`gallery-item ${event.status === 1 ? 'gallery-item-active' : 'gallery-item-upcoming'}`} onClick={() => handleEventForm(event)}>
                                        <img src={event.file} alt={event?.alt} />
                                        <div className={event.status === 1 ? 'gallery-item-caption' : 'gallery-item-caption-grey'}>
                                            <div>
                                                <h2>{event.city}</h2>
                                                {
                                                    event.status === 1 ?
                                                    <p>{moment(event.date).format('Do MMM, YYYY')}</p>
                                                    : <p>Coming Soon...</p>
                                                }
                                            </div>
                                            {/* <a className="vimeo-popup" href="https://vimeo.com/3653567"></a> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
                {/* <div className="col-md-6 kwik-img">
                    <div className="kwik-gallery">
                        <div className="gallery-item">
                            <img src={"https://img.youtube.com/vi/JIRdFIG_ywg/maxresdefault.jpg"} alt="" />
                            <div className="gallery-item-caption">
                                <div>
                                    <h2>Surat</h2>
                                    <p>16th Feb, 2024</p>
                                </div>
                                <a className="vimeo-popup" href="https://vimeo.com/3653567"></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 kwik-img">
                    <div className="kwik-gallery">
                        <div className="gallery-item">
                            <img src={"https://img.youtube.com/vi/JIRdFIG_ywg/maxresdefault.jpg"} alt="" />
                            <div className="gallery-item-caption-grey">
                                <div>
                                    <h2>Surat</h2>
                                    <p>16th Feb, 2024</p>
                                </div>
                                <a className="vimeo-popup" href="https://vimeo.com/3653567"></a>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
            {
                showEventForm &&
                <Fade direction="down">
                    <div className="form-item">
                        {
                            shopOtpSection ?
                                <div className="verification-code-inputs" id="inputs">
                                    {/* <h3>Enter OTP</h3> */}
                                    <input type="text" value={otp} onChange={handleChangeOTP} name="otp" className={errors?.otp && 'field-error'} maxLength={6} required />
                                    <button className="btn pri" type="button" data-lytics="click" data-lytics-category="Website - Landing" data-lytics-action="Click - Download Ebook PDF" data-id="5" id="download-ebook" onClick={verifyOTP} disabled={loading}>Verify OTP</button>
                                </div>
                                :
                                <form onSubmit={submitHandler}>
                                    {
                                        formSubmitted ? <ThankYou name={name} /> :
                                            <div className="detail-page-form">
                                                <input type="text" value={formData?.name || ""} onChange={inputChangeHandler} className={errors?.name && 'field-error'} placeholder="Full Name" name="name" id="name" required />

                                                <input type="email" value={formData?.email || ""} onChange={inputChangeHandler} className={errors?.email && 'field-error'} placeholder="Email" name="email" id="email" required />

                                                <input type="tel" value={formData?.mobile || ""} maxLength={10} onChange={inputChangeHandler} className={errors?.mobile && 'field-error'} placeholder="Mobile" name="mobile" id="mobile" required />

                                                <input type="text" value={formData?.website || ""} onChange={inputChangeHandler} className={errors?.website && 'field-error'} placeholder="Website address" name="website" id="website" required />
                                                <button type="submit" className="btn primary-button" data-id="5" disabled={loading}>Count Me In</button>
                                            </div>
                                    }

                                </form>
                        }
                    </div>
                </Fade>
            }

        </div>
    )
}