import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { API_BASE_URL } from '@utils/constant';
import SuccesStory from '@components/succes-story';
import SuperChargedBusiness from '@components/supercharged-business';
import SectionOne from './parts/section-one';
import './style.css'
declare global {
	interface Window {
		gtag: any;
	}
}

const OneClickCheckout = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})


	async function fetchData() {
		fetch(API_BASE_URL + 'page/oneclickcheckout').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();

		window.gtag('event', 'conversion');
	}, []);

	const convertDangerousHtmlToChildren = node => {
		if (node.props && node.props.dangerouslySetInnerHTML) {
			return {
				...node,
				props: {
					...node.props,
					dangerouslySetInnerHTML: undefined,
					children: node.props.dangerouslySetInnerHTML.__html,
				},
			};
		}
		return node;
	};

	return (
		<>
			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'Boost conversions with 1-click checkout. Reduce RTO. Expand'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "Enhance checkout conversions with GoKwik's one-click solution. Cut RTO by 40% & expand COD coverage to deeper pincodes of India."}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword ? result?.page?.meta_keyword : 'One-click checkout, 1-click checkout, RTO, reduce RTO'}></meta>
				}

				{convertDangerousHtmlToChildren(<script
					type="text/javascript"
					dangerouslySetInnerHTML={{
						__html: 'function gtag_report_conversion(n){return gtag("event","conversion",{send_to:"AW-10804082963/WsSBCKfMu4IDEJPy5J8o",event_callback:function(){void 0!==n&&(window.location=n)}}),!1}',
					}}
				></script>)}
			</Helmet>

          

			<>
			<SectionOne />
			
			</>

			{/* <SuccesStory customClass="" category={6}></SuccesStory>
			<SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness> */}
		</>
	)
}
export default OneClickCheckout