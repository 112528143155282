import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";


const SectionMerchantExp: React.FC<any> = ({ group, data, scrollToForm }) => {
    return (
        <>
            <div className="terms-section">
                <div className="container-fluid">

                    <div className="col-12 section-header">
                        <Fade direction="down">
                             <p>{group?.date}</p>
                            <h3 dangerouslySetInnerHTML={{ __html: group?.title }}></h3>
                        </Fade>
                    </div>


                    {/* {blocks?.map((row: any, index) => ( */}
                    <div className="row row-cols-8 row-cols-md-8 row-cols-lg-8 mx-8 items" id="introduction">
                        <Fade cascade damping={0.2}>

                                <div className="col">
                                    <div className="item">
                                        <div className="">
                                            <Fade cascade damping={0.3}>
                                                <h4 dangerouslySetInnerHTML={{ __html: data.introduction.title }}></h4>
                                                <p dangerouslySetInnerHTML={{ __html: data.introduction.content }}></p>
                                                
                                                <p dangerouslySetInnerHTML={{ __html: data.introduction.subHeading }}></p>
                                                {data.introduction.test?.map((subPoints: any, index) => (
                                                    <p dangerouslySetInnerHTML={{ __html: subPoints.point }}></p>
                                                    ))}
                                            </Fade>
                                        </div>
                                    </div>
                                </div>

                        </Fade>
                    </div>
                    
                    <div className="row row-cols-8 row-cols-md-8 row-cols-lg-8 mx-8 items" id="objective">
                        <Fade cascade damping={0.2}>

                                <div className="col">
                                    <div className="item">
                                        <div className="">
                                            <Fade cascade damping={0.3}>
                                                <h4 dangerouslySetInnerHTML={{ __html: data.objective.title }}></h4>
                                                <p dangerouslySetInnerHTML={{ __html: data.objective.content }}></p>
                                                
                                                <p dangerouslySetInnerHTML={{ __html: data.objective.subHeading }}></p>
                                                {data.objective.test?.map((subPoints: any, index) => (
                                                    <p dangerouslySetInnerHTML={{ __html: subPoints.point }}></p>
                                                    ))}
                                            </Fade>
                                        </div>
                                    </div>
                                </div>

                        </Fade>
                    </div>
                    <div className="row row-cols-8 row-cols-md-8 row-cols-lg-8 mx-8 items" id="scope">
                        <Fade cascade damping={0.2}>

                                <div className="col">
                                    <div className="item">
                                        <div className="">
                                            <Fade cascade damping={0.3}>
                                                <h4 dangerouslySetInnerHTML={{ __html: data.scope.title }}></h4>
                                                <p dangerouslySetInnerHTML={{ __html: data.scope.content }}></p>
                                                
                                                <p dangerouslySetInnerHTML={{ __html: data.scope.subHeading }}></p>
                                                {data.scope.test?.map((subPoints: any, index) => (
                                                    <p dangerouslySetInnerHTML={{ __html: subPoints.point }}></p>
                                                    ))}
                                            </Fade>
                                        </div>
                                    </div>
                                </div>

                        </Fade>
                    </div>
                    <div className="row row-cols-8 row-cols-md-8 row-cols-lg-8 mx-8 items"  id="support">
                        <Fade cascade damping={0.2}>

                                <div className="col">
                                    <div className="item">
                                        <div className="">
                                            <Fade cascade damping={0.3}>
                                                <h4 dangerouslySetInnerHTML={{ __html: data.support.title }}></h4>
                                                <p dangerouslySetInnerHTML={{ __html: data.support.content }}></p>
                                                
                                                <p dangerouslySetInnerHTML={{ __html: data.support.subHeading }}></p>
                                                {data.support.test?.map((subPoints: any, index) => (
                                                    <p dangerouslySetInnerHTML={{ __html: subPoints.point }}></p>
                                                    ))}
                                            </Fade>
                                        </div>
                                    </div>
                                </div>

                        </Fade>
                    </div>
                    <div className="row row-cols-8 row-cols-md-8 row-cols-lg-8 mx-8 items process-flow" id="processFlow">
                        <Fade cascade damping={0.2}>

                                <div className="col">
                                    <div className="item">
                                        <div className="">
                                            
                                            <Fade cascade damping={0.3}>
                                            {/* {blocks?.map((row: any, index) => ( */}
                                                    <div className="rightbox">
                                                        <Fade cascade damping={0.5}>
                                                            <h4>{data.processFlow.title}</h4>
                                                            <span>{data.processFlow.content}</span>
                                                        </Fade>
                                                    </div>
                                                    <div className="item">
                                                        <div className="leftbox">
                                                            <img src={data.processFlow.file} alt={data.processFlow.title}/>
                                                        </div>
                                                    </div>
                                            {/* ))}   */}
                                            </Fade>
                                        </div>
                                    </div>
                                </div>

                        </Fade>
                    </div>
                    
                    <div className="product-stats shopify-advantage" id="escalationMatrix">
                        <Fade direction='down'>
                            <div className="col-12">
                        
                            <Fade cascade damping={0.3}>
                            {/* {blocks?.map((row: any, index) => ( */}
                                    <div className="rightbox">
                                        <Fade cascade damping={0.5}>
                                            <h4>{data.escalationMatrix.title}</h4>
                                            <span>{data.escalationMatrix.content}</span>
                                            <p  dangerouslySetInnerHTML={{ __html: data.escalationMatrix?.table }}></p>
                                        </Fade>
                                    </div>
                            {/* ))}   */}
                            </Fade>
                            
                            </div>
                        </Fade>
                    </div>
                    <div className="row row-cols-8 row-cols-md-8 row-cols-lg-8 mx-8 items" id="record">
                        <Fade cascade damping={0.2}>

                                <div className="col">
                                    <div className="item">
                                        <div className="rightbox">
                                            <Fade cascade damping={0.3}>
                                                <h4 dangerouslySetInnerHTML={{ __html: data.record.title }}></h4>
                                                <p dangerouslySetInnerHTML={{ __html: data.record.content }}></p>
                                                
                                                <p dangerouslySetInnerHTML={{ __html: data.record.subHeading }}></p>
                                                {data.record.test?.map((subPoints: any, index) => (
                                                    <p dangerouslySetInnerHTML={{ __html: subPoints.point }}></p>
                                                    ))}
                                            </Fade>
                                        </div>
                                    </div>
                                </div>

                        </Fade>
                    </div>
                    <div className="product-stats annexure-process" id="annexure">
                        <Fade direction='down'>
                            <div className="col-12">
                        
                            <Fade cascade damping={0.3}>
                                    <div className="rightbox">
                                        <Fade cascade damping={0.5}>
                                            <h4>{data.annexure.title}</h4>
                                            {data.annexure.description?.map((row: any, index) => (
                                                <div >
                                                <p dangerouslySetInnerHTML={{ __html: row?.data }}></p>
                                                </div>
                                            ))}
                                        </Fade>
                                    </div>
                            </Fade>
                            
                            </div>
                        </Fade>
                    </div>
                            {/* ))} */}


                </div>
            </div>
        </>
    )
}
export default SectionMerchantExp
