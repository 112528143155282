import React from 'react'
import { Fade } from "react-awesome-reveal";
const SectionFive: React.FC<any> = ({ formRef }: any) => {
    return (
        <>
        <div className="peoplesay-section-five">
           <div className="container-fluid">

                <div className="col-12 peopleheader">
                    <Fade direction="down">
                        <h3>Hear From Brands Like You Why They Chose One-Click Checkout By GoKwik</h3>
                        <p>Their Words, Not Ours</p>
                    </Fade>
                </div>


                <div className="row row-cols-1 row-cols-md-2 mx-4 items secound">
                    <Fade cascade damping={0.3}>
                
                        <div className="col">
                            <div className="item">
                                
                                <div className="img-info">
                                    <img src="/assets/images/one-click-kwikcheckout/Archit-Agarwal.svg" width={40}/>
                                    <p><span className="check-name">Archit Agarwal, Founder & <br/>CEO, Sanfe</span></p>
                                    <img className="icon" src="/assets/images/one-click-kwikcheckout/sanfe.png" />
                                </div>
                                
                                <div className="detail-desp">
                                    <Fade cascade damping={0.5}>
                                        <h4>Improved Checkout Conversion Rate By 36%</h4>
                                        <p>With GoKwik’s express checkout process, we’re seeing a 36% month-on-month growth in our checkout conversion rate. Our ROAS has also improved by 26% and RTO rate has come down to ~10%.</p>
                                    </Fade>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col">
                            <div className="item">
                                
                                <div className="img-info">
                                    <img src="/assets/images/one-click-kwikcheckout/Siddharth-d.svg" width={40}/>
                                    <p><span className="check-name">Siddharth Darda, <br/>Founder, Tistabene</span></p>
                                    <img className="icon" src="/assets/images/one-click-kwikcheckout/tista.png" />
                                </div>
                                
                                <div className="detail-desp">
                                    <Fade cascade damping={0.5}>
                                        <h4>84.6% Uplift In Prepaid <br/>Conversions</h4>
                                        <p>By leveraging GoKwik’s seamless checkout process, our prepaid order conversions have increased by 84.6%. Meanwhile, our RTO rate has also significantly reduced.</p>
                                    </Fade>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col">
                            <div className="item">
                                
                                <div className="img-info">
                                    <img src="/assets/images/one-click-kwikcheckout/Hitesh.svg" width={40}/>
                                    <p><span className="check-name">Hitesh Dhingra, Founder & <br/>Managing Director, TMC</span></p>
                                    <img className="icon" src="/assets/images/one-click-kwikcheckout/theman.png" />
                                </div>
                                
                                <div className="detail-desp">
                                    <Fade cascade damping={0.5}>
                                        <h4>25% Increase In Checkout <br/> Conversions</h4>
                                        <p>Within a short span, GoKwik helped us uplift our checkout conversion rate by 25%  and increase prepaid transactions by 40% which has translated to faster cash flow movement and low RTO rate.</p>
                                    </Fade>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col">
                            <div className="item">
                                
                                <div className="img-info">
                                    <img src="/assets/images/one-click-kwikcheckout/Pritish.svg" width={40}/>
                                    <p><span className="check-name">Pritish Vartak, AGM, Growth <br/>Marketing, Pilgrim</span></p>
                                    <img className="icon" src="/assets/images/one-click-kwikcheckout/pilgrim.png" />
                                </div>
                                
                                <div className="detail-desp">
                                    <Fade cascade damping={0.5}>
                                        <h4>Clocked 75K+ Orders, ~92% More Than Previous Flash Sale!</h4>
                                        <p>Thanks to KwikCheckout, Pilgrim’s Birthday Flash Sale went smoothly. We saw about ~3 lakh checkout order hits, & clocked in 75000+ orders in 5 days, double numbers compared to our previous Flash Sale.</p>
                                    </Fade>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col">
                            <div className="item">
                                
                                <div className="img-info">
                                    <img src="/assets/images/one-click-kwikcheckout/Taran.svg" width={40}/>
                                    <p><span className="check-name">Taran Chhabra, Co-founder,  <br/>Neeman's</span></p>
                                    <img className="icon" src="/assets/images/one-click-kwikcheckout/neeman.png" />
                                </div>
                                
                                <div className="detail-desp">
                                    <Fade cascade damping={0.5}>
                                        <h4>Increased Prepaid Transactions By <br/>30%</h4>
                                        <p>Thanks to GoKwik's one-click checkout, Neeman’s successfully increased its prepaid conversions by 30%. Not only this, our RTO percentage also reduced from 22.44% to 9.93% in just 4 months!</p>
                                    </Fade>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col">
                            <div className="item">
                                
                                <div className="img-info">
                                    <img src="/assets/images/one-click-kwikcheckout/Kapil.svg" width={40}/>
                                    <p><span className="check-name">Kapil Gupta, Founder, Ruhe <br/>India</span></p>
                                    <img className="icon" src="/assets/images/one-click-kwikcheckout/ruhe.png" />
                                </div>
                                
                                <div className="detail-desp">
                                    <Fade cascade damping={0.5}>
                                        <h4>40.41% Increase In Checkout Conversions</h4>
                                        <p>From a 40.41% increase in checkout conversions to a massive 15% uplift in our business GMV, KwikCheckout has been a game-changer for us. Our overall prepaid order conversion has also seen a 37.10% jump ever since.</p>
                                    </Fade>
                                </div>
                            </div>
                            
                        </div>
                    
                    </Fade>
                </div>
                <div className='back-end-content'>
                    <h5><b>Copy to included (back-end)</b></h5>
                    <p>We can add the headline "Get GoKwik: #1 Razorpay Magic Checkout Alternative for Shopify." somewhere here-it shouldn't be visible as discussed.</p>
                    <p>We can add the headline "Get GoKwik: #1 Shiprocket Checkout Alternative for Shopify. Get Faster & Smoother Checkouts with Transparent Pricing." somewhere here-it shouldn't be visible as discussed.</p>
                    <p>If there is a space we can add "Book a demo to learn more." or "Book a demo."- demo related KWs have low relevance.</p>
                </div>
                <div className="kwik-adv" ref={formRef}>
                    <div className="kwik-content">
                        <h4>Supercharge Your Shopify D2C Business</h4>
                        <p>With One-Click Checkout Solution That Actually Converts</p>
                    </div>
                    {/* <a className='pointer' data-faitracker-form-bind="true">Speak with our experts</a> */}
                </div>
            </div>
        </div>
        </>
    )
}
export default SectionFive