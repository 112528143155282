import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import BlurryLoadingImage from "@components/lazyimage";


const SectionOne: React.FC<any> = (props)=> {
    const {apidocs} = props;


    return (
        <>
            <div className="apidoc-section-one">
                
                   

                    {apidocs?.map((apidoc: any,index) => (
                    <div className={(index === 0)?"section bg-dark":"section secound"} >
                            <div className="container-fluid">
                                <div className="col-12 sub-section-header">
                                        <Fade direction="down">
                                            <h4 dangerouslySetInnerHTML={{ __html: apidoc?.category }}></h4>
                                            <p>{apidoc?.description}</p>
                                        </Fade>
                                </div>
                            </div>
                       
                            
                            <div className="col-12 items">
                                {apidoc?.data?.map((row: any,index1) => (
                                <div className="item">
                                    <Fade cascade damping={0.2}>
                                        <div className="img-wrapper">
                                            <Zoom>
                                                {/* <img src={row.file} alt={row.title}/> */}
                                                <BlurryLoadingImage preview={row?.file} image={row?.file} alt={row?.title}></BlurryLoadingImage>
                                            </Zoom>
                                        </div>
                                        
                                        <p><span>{row.title}</span></p>
                                        {row.description && 
                                            <p>{row.description}</p>
                                        }
                                        <Link to={row.cta}>{row.cta_text}</Link>
                                    </Fade>
                                </div>
                                ))}
                            </div>
                    </div>
                    ))}


            </div>
        </>
    )
}
export default SectionOne