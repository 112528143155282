import React, { useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'
import { API_BASE_URL } from '@utils/constant';
import SectionTwo from './parts/section-two';
import SectionFive from './parts/section-five';
import SuccesStory from '@components/succes-story';
import SuperChargedBusiness from '@components/supercharged-business';
import SectionThree from './parts/section-three';
import './style.css'
import Banner from './parts/banner';
import SectionFour from './parts/section-four';
import SectionSeven from './parts/section-seven';
import SectionEight from './parts/section-eight';
import SectionNine from './parts/section-nine';
import SectionTen from './parts/section-ten';
import SectionEleven from './parts/section-eleven';
import SectionSix from './parts/section-six';
import SectionTwelve from './parts/section-twelve';

declare global {
	interface Window {
		gtag: any;
	}
}

const KwikCheckoutPage = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})


	async function fetchData() {
		fetch(API_BASE_URL + 'page/home').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();

		window.gtag('event', 'conversion');
	}, []);

	const convertDangerousHtmlToChildren = node => {
		if (node.props && node.props.dangerouslySetInnerHTML) {
			return {
				...node,
				props: {
					...node.props,
					dangerouslySetInnerHTML: undefined,
					children: node.props.dangerouslySetInnerHTML.__html,
				},
			};
		}
		return node;
	};

	return (
		<>
			<Helmet>
				<title>
					One Click Checkout
				</title>
				<meta name="description" content="KwikCheckout is the one click checkout solution that increases conversions with RTO reduction" />
				<meta name="keywords" content="one click checkout, checkout, D2C checkout"></meta>

				{convertDangerousHtmlToChildren(<script
					type="text/javascript"
					dangerouslySetInnerHTML={{
						__html: 'function gtag_report_conversion(n){return gtag("event","conversion",{send_to:"AW-10804082963/WsSBCKfMu4IDEJPy5J8o",event_callback:function(){void 0!==n&&(window.location=n)}}),!1}',
					}}
				></script>)}
			</Helmet>
			<Banner arrow={false} data={result?.page?.banner}></Banner>

			<>
				{/* <SectionOne data={result?.blocks?.block1}></SectionOne> */}


				<SectionTwo />
				<SectionThree />
				<SectionFour />
				<SectionFive />
				<SectionTwelve />
				<SectionSix />
				<SectionSeven />
				<SectionEight />
				
				<SectionTen />
				<SectionEleven testimonials={result?.page?.testimonials} block={result?.blocks?.block7} careereye={result?.blocks?.block9}></SectionEleven>

			</>

			<SuccesStory customClass="" category={6}></SuccesStory>
			<SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>
		</>
	)
}
export default KwikCheckoutPage