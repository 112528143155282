import React from 'react'
import './style.css'
import SectionOne from './parts/section-one'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import BannerFive from '@components/banner-five'
import data from "./data.json"

const Page404 = () => {
	 
	return (
		<>
			<SectionOne></SectionOne>
			<SuccesStory></SuccesStory>
			<SuperChargedBusiness></SuperChargedBusiness>
		</>
	)
}
export default Page404