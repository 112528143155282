import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import { API_BASE_URL } from '@utils/constant'
import ModalVideo from 'react-modal-video'
import { Fade, Zoom, Slide, Rotate } from "react-awesome-reveal";
import Experts from '@components/experts'


const SectionThree: React.FC<any> = (props)=> {
    const {data,group,block,achievements,testimonials} = props;
    const [videoOpen, setVideoOpen] = useState('')

    return (
        <>
            <React.Fragment>
                <ModalVideo channel='youtube' autoplay isOpen={videoOpen} videoId={videoOpen} onClose={() => setVideoOpen('')} />
            </React.Fragment>
            <div className="brands-section-three">
                <div className="container-fluid">
                    <div className="col-12 inner">
                            <Fade direction="down">
                                <h2 className="section-heading" dangerouslySetInnerHTML={{__html:block?.title}}></h2>
                                <p className="section-desc">{block?.content}</p>
                            </Fade>
                    </div>
                </div>
                
                <div id="recipeCarousel" className="carousel slide" data-bs-ride="carousel" style={{ position: "relative" }}>
                    <div className="col-12 carousel-inner pt-3" role="listbox">
                        <Slide direction='right' delay={10}>
                            <Experts setVideoOpen={setVideoOpen} data={testimonials}></Experts>
                        </Slide>
                    </div>
                </div>
                


            </div>
        </>
    )
}
export default SectionThree