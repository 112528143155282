import React from "react";



const SectionTen: React.FC<any> = () => {
    return (
        <>

            <div className="checkout-conversion-section-four">
                <div className="container-fluid">


                    <div className="col-md-12">
                        <div className="section-width">
                            <div className="row">
                                <div className="col-md-8 left-side">
                                    <div className="css-xl4503">
                                        <div className="pt-4">
                                            <div className="title">
                                                <h3>Snitch sees a 40% increase in checkout conversion rate with KwikCheckout</h3>
                                            </div>
                                            <div className="descr">
                                                <a target="_blank" href="https://www.gokwik.co/success-story/snitch-sees-a-40-increase-in-checkout-conversion-rate-with-gokwik">
                                                    <button data-faitracker-form-bind="true">Read Case Study Now</button>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 right-side text-center">
                                    <img src="/assets/images/kwikcheckout/checkout-conversion.svg" />
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}
export default SectionTen;