import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import ModalVideo from 'react-modal-video'
import Slider from "react-slick";
import "./style.css"
import BlurryLoadingImage from "@components/lazyimage";


const settings = {
    autoplay: true,
    center:true,
    autoplaySpeed: 5000,
    fade: false,
    slide: 'div',
    cssEase: 'linear',
    dots: true,
    arrows:true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    rtl:false,
}



const BannerFour: React.FC<any> = ({data}) => {
    const [videoOpen, setVideoOpen] = useState('')
    const navigate = useNavigate();
   

    function urlTo(row){
        if(row.video){
            setVideoOpen(row.video);
        }else{
            navigate(row.uri);
        }
    }


    return (
        <>
            <React.Fragment>
                <ModalVideo channel='youtube' autoplay isOpen={videoOpen} videoId={videoOpen} onClose={() => setVideoOpen('')} />
            </React.Fragment>
            <div className="banner-four">
                <div className="container-fluid">
                <Slider {...settings} className="slick-wrapper">

                    {data?.map((row:any,index) => (
                        
                        <div key={index} onClick={() => urlTo(row)}>
                            <div className="row col-12 display-flex wrapper">
                                <div className="col-sm-12 col-md-6 left">
                                    <Fade cascade damping={0.2}>
                                        <p className="page-heading">{row.top_heading}</p>
                                        {row.category && 
                                            <p className="category">{row.category} <span> - {row.date}</span></p>
                                        }
                                        <h1 dangerouslySetInnerHTML={{ __html: row.heading }}></h1>

                                        {row.video?(
                                            <Link to={row.uri} onClick={() => setVideoOpen(row.video)}>{row.link}</Link>
                                        ):(
                                            <Link to={row.uri}>{row.link}</Link>
                                        )}
                                    </Fade>
                                        
                                </div>

                                <div className="col-sm-12 col-md-6 rightbox">
                                    <Zoom>
                                    {row.video?(
                                       
                                        // <img onClick={() => setVideoOpen(row.video)} className="video" src={row.image} width="100%" alt={row.heading} /> 
                                        <BlurryLoadingImage onClick={() => setVideoOpen(row.video)} preview={row?.image} image={row?.image} alt={row?.heading}></BlurryLoadingImage>
                                    ):(
                                        <BlurryLoadingImage preview={row?.image} image={row?.image} alt={row?.heading}></BlurryLoadingImage>
                                        // <img src={row.image} width="100%" alt={row.heading} /> 
                                    )}
                                    </Zoom>
                                </div>
                            </div>
                        </div>
                    ))}

                </Slider>
                </div>
            </div>
        </>
    )
}
export default BannerFour