import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { Fade, Zoom, Slide, Rotate } from "react-awesome-reveal";
import './style.css'
import data from "./data.json";
import { Link } from 'react-router-dom';
import Subscribe from '@components/subscribe';


const SuperChargedBusiness: React.FC<any> = ({ group, blocks, subscribe, subscribePage }) => {

    const getClass = (index) => {
        if (index === 0) {
            return 'item topleft';
        }
        else if (index === 1) {
            return 'item topright';
        }
        else if (index === 2) {
            return 'item bottomleft';
        }
        else if (index === 3) {
            return 'item bottomright';
        }
        else {
            return 'item'
        }
    }
    return (
        <>
            <div className="supercharge-business">

                <div className="container-fluid">
                    <div className="row">

                        <Slide direction="down" delay={1}>
                            <h2 dangerouslySetInnerHTML={{ __html: group?.title }}></h2>
                        </Slide>

                        <div className="grid-row">
                            <div className="grid-items pb-5">

                                {blocks?.map((row: any, index) => (
                                    <div key={index}>
                                        {index % 2 === 0 ? (
                                            <Fade direction='left' key={index}>
                                                <Link to={row?.cta}>
                                                    <div className={getClass(index)} key={index}>
                                                        <div className="content">
                                                            <Fade cascade damping={0.3}>
                                                                <span>{row.title}</span>
                                                                <h4 dangerouslySetInnerHTML={{ __html: row.content }}></h4>
                                                                <Link to={row?.cta}>{row?.cta_text} <i className="fa-solid fa-chevron-right"></i></Link>
                                                            </Fade>
                                                        </div>
                                                        <div className="img">
                                                            <Zoom>
                                                                <img src={row.file} alt={row.title} />
                                                            </Zoom>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Fade>

                                        ) : (
                                            <Fade direction="right" key={index}>
                                                <Link to={row?.cta}>
                                                    <div className={getClass(index)} key={index}>

                                                        <div className="img">
                                                            <Zoom>
                                                                <img src={row.file} alt={row.title} />
                                                            </Zoom>
                                                        </div>

                                                        <div className="content">
                                                            <Fade cascade damping={0.3}>
                                                                <span>{row.title}</span>
                                                                <h4 dangerouslySetInnerHTML={{ __html: row.content }}></h4>
                                                                <Link to={row?.cta}>{row?.cta_text} <i className="fa-solid fa-chevron-right"></i></Link>
                                                            </Fade>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Fade>
                                        )}

                                    </div>

                                ))}

                            </div>

                        </div>
                    </div>





                </div>




                {subscribe &&
                    <Subscribe subscribePage={subscribePage}></Subscribe>
                }



            </div>
        </>
    )
}
export default SuperChargedBusiness