import React from "react";
import 'react-circular-progressbar/dist/styles.css';


const SectionFive: React.FC<any> = () => {
    return (
        <>
            <div className="drive-section-five">
                <div className="container-fluid">


                    <div className="col-md-12">
                        <div className="sec-width">
                            <div className="row">
                                <div className="col-md-7 left-side">
                                    <div className="css-xl4503">
                                        <div className="pt-4">
                                            <div className="title">
                                                <h3><span>Drive trust with <br />
                                                    extensive & secured payment suite</span></h3>
                                            </div>
                                            <div className="descr">
                                                <p>Provide a secure & frictionless payment experience for your shoppers.</p>
                                                <ul>
                                                    <li>Extensive payment options</li>
                                                    <li>Personalised recommendations</li>
                                                    <li>Best-in-class success rate</li>
                                                    <li>Exclusive partnerships for prepaid discounts</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 right-side">
                                    <img src="/assets/images/kwikcheckout/payment-suite.svg" />
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default SectionFive;