import React, { useEffect, useRef, useState } from 'react'
import './../style.css'
import SectionDetails from './section-details'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import data from "./../data.json"
import { API_BASE_URL } from '@utils/constant'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'




const BlogPageDetails = () => {
	const { slug } = useParams()
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})
	const [playbook, setPlaybook] = useState<any>({})

	async function fetchData() {
		fetch(API_BASE_URL + 'page/playbook').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					//console.log(data);
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	async function fetchPlaybookDetails() {
		fetch(API_BASE_URL + 'playbook/' + slug).then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setPlaybook(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}


	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
		fetchPlaybookDetails();
	}, []);

	return (
		<>
			{
				(playbook && playbook.meta_title) &&
				<Helmet>
					{playbook.meta_title && <title>
						{playbook.meta_title}
					</title>
					}
					{playbook.meta_description && <meta name="description" content={playbook.meta_description} />}
					{playbook.meta_keyword && <meta name="keywords" content={playbook.meta_keyword}></meta>}
				</Helmet>
			}
			<SectionDetails data={playbook}></SectionDetails>
			<SuccesStory customClass=""></SuccesStory>
			<SuperChargedBusiness subscribe={true} subscribePage={`Playbook: ${playbook?.title}`} group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>

		</>
	)
}
export default BlogPageDetails