import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import ModalVideo from 'react-modal-video'
import { API_BASE_URL } from "@utils/constant";
import ReactPaginate from "react-paginate";
import BlurryLoadingImage, { MyLoader } from "@components/lazyimage";

const SectionOne: React.FC<any> = (props)=> {
    const [videoOpen, setVideoOpen] = useState('')
    const {data,categories,banner} = props;
    const [result, setResult] = useState<any>({})
    const [query, setQuery] = useState("")
    const [categoryId, setCategoryId] = useState(0)
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState(1)
    
    function handleSubmit(e) {
        e.preventDefault();
        scrollResultTab();
        fetchData(categoryId,1,query);
    }
    function scrollResultTab(){
        var myElement = document.getElementById('result-tabs');
        var topPos = myElement.offsetTop;
        window.scrollTo(0, topPos);
    }
    
    function handleQuery(e) {
        const q = e.target.value;
        setQuery(q);
        if(q.length > 2){
            fetchData(categoryId,1,q);
        }
    }
    function handleTabChange(e) {
        setCategoryId(e);
        fetchData(e,1);
    }
    function handlePageClick(e){
        const p = (e.selected+1);
        setPage(p);
        scrollResultTab();
        fetchData(categoryId,p);
    }
    async function fetchData(cat=0,p=1,q="") {
        setResult({});
        let uri = API_BASE_URL+'podcast/'+cat+"/"+p
        if(q!=""){
            const query = new URLSearchParams({q:q}).toString();
            uri= uri+"?"+query
        }

		fetch(uri).then(
			function(response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function(data) {
					setResult(data);
                    setTotalPage(Math.ceil((data?.total)/6));

				});
			}
		)
		.catch(function(err) {
			console.log('Fetch Error :-S', err);
		});
	}

    useEffect(()=>{
        fetchData();
    },[])


    return (
        <>
            <div className="podcast-section-one bg-light">
                <React.Fragment>
                    <ModalVideo channel='youtube' autoplay isOpen={videoOpen} videoId={videoOpen} onClose={() => setVideoOpen('')} />
                </React.Fragment>

                <div className="container-fluid">

                    <div className="col-12 section-header">
                        <Fade direction="down">
                            <h3><span>Top Podcasts</span> You Don't Want To Miss</h3>
                        </Fade>
                    </div>


                    <div className="col-12 items" style={{padding:0}} id="result-tabs">
                        {!result?.data && 
                            <MyLoader/>
                        }
                        <Fade cascade damping={0.2}>
                        {result?.data?.map((row: any,index1) => (
                            <div className="item" key={index1} onClick={() => setVideoOpen(row.video)}>
                                
                                <div className="play-icon"><img src="/assets/images/youtube-play.png" width="76" height="76" alt="Youtube PLay"/></div>
                                <Fade cascade damping={0.2}>
                                    <div className='play'>
                                    <Zoom>
                                        {/* <img src={"https://img.youtube.com/vi/"+row.video+"/maxresdefault.jpg"} alt={row.title}/> */}
                                        <BlurryLoadingImage preview={"https://img.youtube.com/vi/"+row.video+"/maxresdefault.jpg"} image={"https://img.youtube.com/vi/"+row.video+"/maxresdefault.jpg"} alt={row?.title}></BlurryLoadingImage>
                                    </Zoom>
                                    </div>
                                    <p className="title" dangerouslySetInnerHTML={{ __html: row.title }}></p>
                                </Fade>
                            </div>
                        ))}
                        </Fade>
                        
                       
                        
                    </div>

                        {totalPage > 1 && 
                            <div className="col-12 pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="Next"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={3}
                                pageCount={totalPage}
                                previousLabel="Previous"
                                renderOnZeroPageCount={null}
                                disabledClassName="disabled"
                                activeClassName="active"
                            />
                            </div>
                            }


                </div>
            </div>
        </>
    )
}
export default SectionOne