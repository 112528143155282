import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import SectionOne from './parts/section-one'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import data from "./data.json"
import { API_BASE_URL } from '@utils/constant'
import { Helmet } from "react-helmet"
import IdealPopup from '@pages/product/kwikchat/parts/ideal-popup'


const EventPage = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})

	async function fetchData() {
		fetch(API_BASE_URL + 'page/event').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, []);

	return (
		<>
			{/* <Helmet >
				<title>GoKwik Shopping Festival | The Ultimate Shopping Festival For D2C Brands</title>
				<meta name="description" content="GSF, India’s first D2C eCommerce shopping festival by GoKwik, aims to help eCommerce brands increase conversion by 2X and business GMV by 4X per day." />
			</Helmet> */}
			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'GoKwik Shopping Festival | The Ultimate Shopping Festival For D2C Brands'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "GSF, India’s first D2C eCommerce shopping festival by GoKwik, aims to help eCommerce brands increase conversion by 2X and business GMV by 4X per day."}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>
			{/* <BannerFive data={result?.page?.banner}></BannerFive> */}
			<SectionOne banner={result?.page?.banner} data={data?.section1} categories={result?.page?.categories}></SectionOne>
			<SuccesStory customClass=""></SuccesStory>
			<SuperChargedBusiness subscribe={true} subscribePage="Event" group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>
			<IdealPopup
				title={"Unlock VIP Access: Join Our Exclusive Newsletter!"}
				content={"Dive into eCommerce Excellence, Discover What's Latest At GoKwik!"}
				subscribePage="Event"
			/>
		</>
	)
}
export default EventPage