import React,{} from 'react'
//import './App.css'
import Routes from './routes'
import MainLayout from '@components/templates/MainLayout'
import { BrowserRouter as Router } from 'react-router-dom'
import ScrollToTop from './routes/scroolToTop'
import { BASE_PATH } from '@utils/constant'

import TagManager from 'react-gtm-module'
import ReactPixel from 'react-facebook-pixel';
import LinkedInTag from 'react-linkedin-insight';

function App() {

	TagManager.initialize({gtmId: 'GTM-KRLH2DQ'})
	ReactPixel.init('1470429743451713');
	LinkedInTag.init("3345940", 'dc', true);
	LinkedInTag.track("3345940");


	return (
		<div>
			<Router basename={BASE_PATH}>
				<ScrollToTop />
				<MainLayout>
					<Routes />
				</MainLayout>
			</Router>
		</div>
	)
}

export default App

