import React,{useEffect,useRef,useState} from 'react'
import './style.css'

import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import BannerOne from '@components/banner-one'
import { Helmet } from "react-helmet";

import { API_BASE_URL } from '@utils/constant'
import SectionDetails from './section-details'
import SectionOne from './checkout-privacy-policy'
const PrivacyPolicy = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})

	async function fetchData() {
		fetch(API_BASE_URL+'page/privacy-policy').then(
			function(response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function(data) {
					setResult(data);
				});
			}
		)
		.catch(function(err) {
			console.log('Fetch Error :-S', err);
		});
	}

	useEffect(() => {
        if(wasCalled.current) return;
        wasCalled.current = true;
        fetchData();
    }, []);

	return (
		<>
			
			{/* <Helmet >
				<title>GoKwik Data Policy | Data Protection With Multilevel Security</title>
				<meta name="description" content="GoKwik prioritises transparency, protection, and privacy. We're committed to safeguarding your data with multilevel security. Read our Data Policy here." />
			</Helmet> */}
			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'GoKwik Data Policy | Data Protection With Multilevel Security'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "GoKwik prioritises transparency, protection, and privacy. We're committed to safeguarding your data with multilevel security. Read our Data Policy here."}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>
			<SectionDetails data={result?.page}></SectionDetails>
			<SectionOne/>
		</>
	)
}
export default PrivacyPolicy