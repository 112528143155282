import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import SectionOne from './parts/section-one'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import BannerTwo from '@components/banner-two'
import { API_BASE_URL } from '@utils/constant'
import { Helmet } from 'react-helmet'


const Partners = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})

	async function fetchData() {
		fetch(API_BASE_URL + 'page/partners').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, []);

	return (
		<>
			{/* <Helmet >
				<title>GoKwik Partners | 50+ Powerful Partnerships</title>
				<meta name="description" content="GoKwik fosters 50+ partnerships across 10+ categories to help simplify the operations of its partner merchants and enable smooth business continuity." />
			</Helmet> */}
			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'GoKwik Partners | 50+ Powerful Partnerships'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : 'GoKwik fosters 50+ partnerships across 10+ categories to help simplify the operations of its partner merchants and enable smooth business continuity.'}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>
			<BannerTwo data={result?.page?.banner}></BannerTwo>
			<SectionOne partners={result?.page?.partners}></SectionOne>
			<SuccesStory customClass=""></SuccesStory>
			<SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>

		</>
	)
}
export default Partners