import React, { useEffect, useRef, useState } from 'react'
import './../style.css'

import SectionOne from './../common/section-one'
import SectionTwo from './../common/section-two'
import SectionFeatures from './parts/section-features'
import SectionFive from './parts/section-five'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import BannerVideo from '@components/banner-video'
import { API_BASE_URL } from '@utils/constant'
import { Helmet } from "react-helmet";

import IdealPopup from './parts/ideal-popup'

import data from './data.json'


const KwikChat = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})


	async function fetchData() {
		fetch(API_BASE_URL + 'page/kwikchat').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, []);

	return (
		<>
			{/* <Helmet >
				<title>KwikChat - One-Stop Whatsapp Solution | GoKwik</title>
				<meta name="description" content="Kwik Chat powered by GoKwik is the only WhatsApp Business API solution across the funnel that helps your brand achieve 20X ROI." />
			</Helmet> */}

			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'KwikEngage - One-Stop Whatsapp Solution | GoKwik'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "Kwik Chat powered by GoKwik is the only WhatsApp Business API solution across the funnel that helps your brand achieve 20X ROI."}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>

			<BannerVideo data={result?.page?.banner}></BannerVideo>

			<SectionOne group={result?.groups?.block1} blocks={result?.blocks?.block1}></SectionOne>

			<div className="product-section-two">
				<SectionTwo group={result?.groups?.block2} blocks={result?.blocks?.block2}></SectionTwo>
				<SectionFeatures blockss={data} group={result?.groups?.block3} blocks={result?.blocks?.block3}></SectionFeatures>

			</div>

			<SectionFive  />
			<SuccesStory customClass="" category={3}></SuccesStory>
			<SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>
			<IdealPopup
				title={"Unlock The Art & Science Behind Ads That Click-to-WhatsApp"}
				content={"Increase 20X ROAS on WhatsApp with KwikEngage"}
				redirectPath={"/playbook/the-art-science-behind-ads-that-clicktowhatsapp"}
			/>
		</>
	)
}
export default KwikChat