import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { Fade, Zoom } from "react-awesome-reveal";
import Section from "./section";
import $ from "jquery";

const SectionFeatures: React.FC<any> = (props) => {
  const { group, blocks } = props;

  return (
    <>
      <div className={"product-features "} id="product-features">
        <div className="container-fluid">
          <div className="col-12 section-header">
            <Fade direction="down">
              <h3 dangerouslySetInnerHTML={{ __html: group?.title }}></h3>
            </Fade>
            <p>Unique And Innovative Features Tailored To Drive Higher Engagement, Conversions, And Revenue For Your eCommerce Business</p>
          </div>

          <div className="col-12 wrapper flex">
            <div className="col-md-12">
              <div className="kwikchat-feature">
                <div className="row justify-content-center">
                    <div className="kw-adv">
                    <div className="content">
                        <div className="title">
                        <h4>
                            KwikPolls
                        </h4>
                        </div>
                        <h3 className="py-2">
                        Higher Engagement On Whatsapp Through Interactive Quizzes 
                        </h3>
                        <div className="descr">
                        <ul>
                            <li>Ride the wave of moment marketing by running polls with interactive buttons </li>
                            <li>
                            Ensure automated responses in the backend to deliver the right message to the right segment
                            </li>
                            <li>Achieve 3X higher engagement rate, capturing the interest of the target audiences</li>
                        
                        </ul>
                        </div>
                    </div>
                    <img src="/assets/images/product/kwikchat/kwikpolls.svg"/>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="kw-adv">
                    <div className="content">
                        <div className="title">
                        <h4>
                        Meta Messaging Automation
                        </h4>
                        </div>
                        <h3 className="py-2">
                        Automate Sending Messages Across Various Events Directly On Social Media Channels
                        </h3>
                        <div className="descr">
                        <ul>
                            <li>Send direct messages instantly about price and product when a shopper inquires in the comments </li>
                            <li>Set up automated responses for brand mentions in stories by shoppers
                            </li>
                            <li>Implement end-to-end direct message (DM) automation to address frequently asked question from interested shoppers</li>
                        
                        </ul>
                        </div>
                    </div>
                    <img src="/assets/images/product/kwikchat/automation.svg"/>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="kw-adv">
                    <div className="content">
                        <div className="title">
                        <h4>
                        Advanced Segmentation
                        </h4>
                        </div>
                        <h3 className="py-2">
                        Analyze Shopper Behavior On Website And Messages For Robust Targeting
                        </h3>
                        <div className="descr">
                        <ul>
                            <li>Create customized segmentation by identifying shopper actions on the website (e.g., landing pages, browsed specific products, etc.)</li>
                            <li>Build cohorts based on message analytics (e.g., WhatsApp messages seen, read, clicked, etc.)</li>
                            <li>Enhance retargeting across the website and messaging platforms for improved engagement</li>
                        
                        </ul>
                        </div>
                    </div>
                    <img src="/assets/images/product/kwikchat/Segmentation.svg"/>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="kw-adv">
                    <div className="content">
                        <div className="title">
                        <h4>
                        Do Not Disturb Activation
                        </h4>
                        </div>
                        <h3 className="py-2">
                        Implement Global DND Across All SMS Communications
                        </h3>
                        <div className="descr">
                        <ul>
                            <li>Activate global Do Not Disturb (DND) settings to comply with regulations</li>
                            <li>Automatically identify DND message cohorts to reduce message failures</li>
                            <li>Remove DND numbers from campaign lists automatically to boost delivery rates and save costs.</li>
                        
                        </ul>
                        </div>
                    </div>
                    <img src="/assets/images/product/kwikchat/Activation.svg"/>
                    </div>
                </div>


                {/* <div className="row">
                                <div className="col-md-7 left-side">
                                    <div className="css-xl4503">
                                        <div className="pt-4">
                                            <div className="title">
                                                <h3><span>Convert up to 40% more shoppers while reducing RTO</span></h3>
                                            </div>
                                            <h4 className="py-4">Implement a fast and easy checkout process with RTO safeguarding with shopify cash on delivery app </h4>
                                            <div className="descr">

                                                <ul>
                                                    <li>Discounts on all prepaid payment methods</li>
                                                    <li>Risk based RTO features during & after checkouts</li>
                                                    <li>Offer partial COD to reduce RTO</li>
                                                    <li>Offer bulk and prepaid discounts</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-5 right-side">
                                    <img src="/assets/images/kwik-checkout-cod/shopper-rto.svg" />
                                </div>

                            </div> */}
              </div>
            </div>

            {/* <div className="col-12 col-md-6">
                            <Section data={blocks}></Section>
                        </div>

                        <div className="col-12 col-md-6" id="screens">
                            <div className={"img-wrapper"}>
                                <div className="circle">
                                    <div className="inner-circle"></div>
                                </div>
                                <img src="/assets/images/product/kwikchat/frame.svg" alt={"Kwikchat Frame"}/>

                                {blocks?.map((row:any , index) => (
                                    <div className={(index===0)?"item-frame img-frame active":"item-frame img-frame"} id={"item"+index+"-frame"}>
                                        <img src={row.file} alt={row.title} />
                                    </div>
                                ))}
                            </div>

                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
export default SectionFeatures;
