import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import SectionOne from './parts/section-one'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import BannerFour from '@components/banner-four';
import { API_BASE_URL } from '@utils/constant';
import { Helmet } from 'react-helmet'
import BannerFive from '@components/banner-five'
import IdealPopup from '@pages/product/kwikchat/parts/ideal-popup'

const PlaybookPage = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})
	const [featuredPosts, setFeaturedPosts] = useState<any>([])
	const [searchTxt, setSearchTxt] = useState('');
	const [toggleSearch, toggleMainSearch] = useState(false);

	async function fetchData() {
		fetch(API_BASE_URL + 'page/playbook').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
					// getFeaturedPost(data?.page?.featured);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, []);

	function getFeaturedPost(data = []) {
		let temp = [];
		data.map((row: any) => {
			const t = {
				top_heading: "Playbooks",
				category: row?.category_name,
				date: row?.published_date,
				heading: row?.title,
				image: row?.featured_image,
				uri: "/playbook/" + row?.slug,
				link: "Read More"
			}
			temp.push(t);
		});
		setFeaturedPosts(temp);
	}

	function handleQuery(e) {
		// setSearchTxt(e.target.value)
	}

	function handleSubmit(e) {
		e.preventDefault();
		// console.log(console.log(e.target[0].value));
		setSearchTxt(e.target[0].value)
		toggleMainSearch(!toggleSearch);
		// setTimeout(() => {
		// 	setSearchTxt('');
		// }, 2000)
	}

	return (
		<>
			<Helmet >
				<title>GoKwik Guides | Playbooks, White Papers & Industry Reports</title>
				<meta name="description" content="In-depth guides & playbooks expanding on my essential eCommerce business concepts such as fast checkout, Return-to-Origin, how to leverage WhatsApp Commerce and more." />
			</Helmet>
			<BannerFive data={result?.page?.banner} handleQuery={handleQuery} handleSubmit={handleSubmit}></BannerFive>
			{/* <BannerFour data={featuredPosts}></BannerFour> */}
			<SectionOne categories={result?.page?.categories} toggleSearch={toggleSearch} searchTxt={searchTxt}></SectionOne>
			<SuccesStory customClass=""></SuccesStory>
			<SuperChargedBusiness subscribe={true} subscribePage="Playbook" group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>
			<IdealPopup
				title={"Unlock VIP Access: Join Our Exclusive Newsletter!"}
				content={"Dive into eCommerce Excellence, Discover What's Latest At GoKwik!"}
				subscribePage="Playbook"
			/>
		</>
	)
}
export default PlaybookPage