import React, { useEffect, useState } from "react";
import faqs from '../faq.json'
import { Fade, Zoom } from "react-awesome-reveal";
import {Tab,Tabs,Accordion} from 'react-bootstrap';



const SectionFour: React.FC<any> = ({ group, blocks })=> {
  

    return (
        <>
            <div className="product-faq-section">
                <div className="container-fluid wrapper">
                     
                    <div className="col-12 section-header">
                        <Fade direction="down">
                          
                            <h3>Frequently Asked Questions</h3>
                        </Fade>
                    </div>

                    <div className="col-12 faq-tab">
                                <Accordion defaultActiveKey="0">
                                 {faqs?.map((row1: any,index1: never) => (
                                    <Accordion.Item eventKey={index1}>
                                        <Accordion.Header>{row1.question}</Accordion.Header>
                                        <Accordion.Body dangerouslySetInnerHTML={{ __html: row1?.answer }}>
                                        
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                                </Accordion>
                    </div>

                </div>
            </div>
            </>
    )
}
export default SectionFour