const SectionTwo = () => {
  return (
    <>
      <div className="glory-section-two">
        <div className="container-fluid">

          <div className="col-12 inner">
            <h2 className="">
              Recognising Growth Every Step Of The Way
            </h2>
            <p>Putting a spotlight on merchants in our network who unlock key GMV Milestones as they grow with us.</p>
          </div>
          <div className="col-md-12">
            <div className="sec-width">
              <div className="row align-items-center">
                <div className="col-md-6 right-img">
                  <img src="/assets/images/glory/dynamic.svg" />
                </div>
                <div className="col-md-6 left-side">

                  <div className="">

                    <p>
                      Brands that have shown stellar growth & hit INR 25
                      Lakh GMV in the month of evaluation. We celebrate their grit &
                      determination to power on to greater heights.
                    </p>

                  </div>

                </div>
              </div>
            </div>
            <div className="sec-width mt-4">
              <div className="row align-items-center">
                <div className="col-md-6 right-img">
                  <img src="/assets/images/glory/terrific.svg" />
                </div>
                <div className="col-md-6 left-side">

                  <div className="">

                    <p>
                      Brands that have become true inspiration in their
                      growth journey & hit INR 2 Crore GMV in the month of evaluation. We are honoured to be part of this remarkable feat
                      that will become a guiding force for others in the
                      industry.
                    </p>

                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="col-12 inner">
            <h2 className="section-heading">The Eligibility Criteria</h2>
          </div>
          <div className="col-md-12">
            <div className="eligibility-criteria">
              <div className="row eligibility-section">
                <div className="back-left-bg">
                  <img src="/assets/images/glory/left-bg.svg" alt="" />
                </div>
                <div className="item">
                  <div className="img-wrapper">
                    <img
                      src="/assets/images/glory/Growth.svg"
                      alt="Upto 3X GMV Boost"
                    />
                  </div>
                  <div className="rightbox">
                    <div className="css-0">
                      <h4 className="">Demonstrated Growth</h4>
                    </div>
                    <div className="css-0">
                      <p className="">
                        Brands that have achieved a GMV of over INR 25
                        Lakhs or INR 2 Crore by the end of the evaluation month.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="back-right-bg">
                  <img src="/assets/images/glory/right-bg.svg" alt="" />
                </div>
                <div className="item">
                  <div className="img-wrapper">
                    <img
                      src="/assets/images/glory/Presence.svg"
                      alt="Upto 3X GMV Boost"
                    />
                  </div>
                  <div className="rightbox">
                    <div className="css-0">
                      <h4 className="">Established Presence</h4>
                    </div>
                    <div className="css-0">
                      <p className="">
                        Brands that have been live with GoKwik till 2 months
                        prior to the month of evaluation.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    </>
  );
};
export default SectionTwo;
