import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import ModalVideo from 'react-modal-video'
import './style.css'
import BlurryLoadingImage from "@components/lazyimage";

const BannerVideo: React.FC<any> = ({data}) => {
    const [videoOpen, setVideoOpen] = useState('')
    
    return (
        <>
            <React.Fragment>
                <ModalVideo channel='youtube' autoplay isOpen={videoOpen} videoId={videoOpen} onClose={() => setVideoOpen('')} />
            </React.Fragment>
            <div className="banner-home banner-video kwikengage-banner">
                <div className="container-fluid">

                <Fade cascade damping={0.5}>
                    <div className="row heading">
                            <div className="col-12">
                            <Fade cascade damping={0.5}>
                                <h1 dangerouslySetInnerHTML={{ __html: data?.title }}></h1>
                                <p dangerouslySetInnerHTML={{__html:data?.description}}></p>
                            </Fade>
                            </div>
                    </div>

                    {/* {data && 
                    <div className="row video-box" onClick={() => setVideoOpen(data?.cta)}>
                        <div className="play-icon"><img src="/assets/images/youtube-play.png" width="76" height="76"/></div>
                        <Zoom className="thumbnail">
                            <BlurryLoadingImage preview={"https://img.youtube.com/vi/"+data?.cta+"/maxresdefault.jpg"} image={"https://img.youtube.com/vi/"+data?.cta+"/maxresdefault.jpg"} alt={data?.title}></BlurryLoadingImage>
                        </Zoom>
                    </div>
                    } */}
                </Fade>

                </div>
            </div>
        </>
    )
}
export default BannerVideo