import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import SectionOne from './parts/section-one'
import SectionTwo from './parts/section-two'
import SectionThree from './parts/section-three'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'

import BannerTwo from '@components/banner-two'
import data from "./data.json"
import { API_BASE_URL } from '@utils/constant'
import { Helmet } from "react-helmet";


const Brands = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})

	async function fetchData() {
		fetch(API_BASE_URL + 'page/brands').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, []);

	return (
		<>
			{/* <Helmet >
				<title>GoKwik Partnered eCommerce Brands</title>
				<meta name="description" content="GoKwik follow a merchant first philosophy. This's why it's trusted by 1200+ brands across15+ categories like fashion, health, electronics, and apparel industry." />
			</Helmet> */}
			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'GoKwik Partnered eCommerce Brands'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "GoKwik follow a merchant first philosophy. This's why it's trusted by 1200+ brands across15+ categories like fashion, health, electronics, and apparel industry."}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>
			<BannerTwo data={result?.page?.banner}></BannerTwo>
			<SectionOne data={result?.blocks?.block1}></SectionOne>
			<SectionTwo block={result?.blocks?.block2} categories={result?.page?.categories}></SectionTwo>
			<SectionThree block={result?.blocks?.block3} testimonials={result?.page?.testimonials}></SectionThree>
			<SuccesStory customClass="brand-marginTop160"></SuccesStory>
			<SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>

		</>
	)
}
export default Brands