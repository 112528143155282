import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";


const SectionFour: React.FC<any> = ({ group, blocks })=> {
  

    return (
        <>
            <div className="product-section-four">
                <div className="container-fluid">

                    <div className="col-12 section-header">
                        {/* <Fade direction="down">
                            <h3 dangerouslySetInnerHTML={{ __html: group?.title }}></h3>
                        </Fade> */}
                    </div>
                   

                    <div className="col-12 items">
                        
                    {blocks?.map((row: any, index) => (
                            <div className="item">
                                <div className="img-wrapper">
                                    <Zoom>
                                    <img src={row.file} alt={row.title}/>
                                    </Zoom>
                                </div>
                                <div className="rightbox">
                                    <Fade cascade damping={0.5}>
                                        <h4>{row.title}</h4>
                                    </Fade>
                                </div>
                            </div>
                    ))} 

                        
                    </div>
                </div>
            </div>
            </>
    )
}
export default SectionFour