import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";
import {Tab,Tabs,Accordion} from 'react-bootstrap';



const SectionThree: React.FC<any> = (props)=> {
    const {data} = props;
    
    return (
        <>
            <div className="contact-section-three">
                <div className="container-fluid wrapper">

                    <div className="col-12 section-header">
                        <Fade direction="down">
                            <h3>FAQs</h3>
                        </Fade>
                    </div>

                    <div className="col-12 faq-tab">
                        <Tabs defaultActiveKey="0" className="mb-3">
                        {data?.map((row: any,index) => (
                            <Tab eventKey={index} title={row.category}>
                                <Accordion defaultActiveKey="0">
                                 {row.data?.map((row1: any,index1) => (
                                    <Accordion.Item eventKey={index1}>
                                        <Accordion.Header>{row1.question}</Accordion.Header>
                                        <Accordion.Body dangerouslySetInnerHTML={{ __html: row1?.answer }}>
                                        
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                                </Accordion>
                            </Tab>
                        ))}
                        </Tabs>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SectionThree