import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import Slider from "react-slick";
import BlurryLoadingImage from "@components/lazyimage";



const SectionThree: React.FC<any> = (props)=> {
    const {data,group,blocks,achievements} = props;


    return (
        <>
            <div className="shoppers-section-three">
                <div className="container-fluid">
                    <div className="col-12 inner">
                           <h2 className="section-heading">With the largest pool of 100 Mn+ verified shoppers</h2>
                    </div>
                </div>
                <div className="col-12 slider">
                    <div className="box-slider">
                        <div className="item">
                            <div className="content">
                                <h4>40%</h4>
                                <p>CONVERSION BOOST</p>
                            </div>
                            <div className="content">
                                <h4>25%</h4>
                                <p>PREPAID CONVERSION BOOST</p>
                            </div>
                            <div className="content">
                                <h4>40%</h4>
                                <p>RTO REDUCTION</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SectionThree