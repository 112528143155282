import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import {Tabs,Tab} from 'react-bootstrap';
import ScrollSpy from "react-ui-scrollspy";
import {CopyToClipboard} from 'react-copy-to-clipboard';



const SectionOne: React.FC<any> = ({data,article})=> {
    const [copyValue,setCopyValue] = useState()
    const [copied,setCopied] = useState(false)

    //console.log("artilce : ",article);

    const parentScrollContainerRef = useRef<HTMLDivElement | null>(null);

    const onPress = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      e.preventDefault();
      const target = window.document.getElementById(
        e.currentTarget.href.split("#")[1]
      );
      if (target) {
        var headerOffset = 130;
        var elementPosition = target.getBoundingClientRect().top;
        var offsetPosition = elementPosition - headerOffset;
  
        window.scrollBy({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    };

    if(!article){
        return (<></>)
    }
    
    return (
        <>
            <div className="row col-12">
                <nav className="api-leftmenu col-3" id="navbar1">
                    <h3>Prerequisites</h3>
                    <ul className="nav nav-pills flex-column">
                        {article?.data?.map((row:any,index) => (
                            <li className="nav-item" key={index}>
                                <a data-to-scrollspy-id={"step"+(index+1)} onClick={(e) => onPress(e)} href={"#step"+(index+1)} className="ss-item">
                                        {row.menu_title}
                                </a>
                            </li>
                        ))}
                    </ul>

                </nav>

                <div className="api-middle col-9 col-md-8"  ref={parentScrollContainerRef}>
                <ScrollSpy scrollThrottle={20} useBoxMethod offsetBottom={0} offsetTop={0} activeClass="active">
                        <h4>API DOCS</h4>
                        <h1 dangerouslySetInnerHTML={{__html:article?.title}}></h1>

                        {article?.content && 
                            <div dangerouslySetInnerHTML={{__html:article.content}}></div>
                        }

                        {article?.data?.map((row: any,index) => (
                            <section id={"step"+(index+1)} key={index}>
                               
                                <h2 dangerouslySetInnerHTML={{__html:'<span>'+row.step+': </span>'+row.title}}></h2>
                                <div dangerouslySetInnerHTML={{__html:row.content}}></div>

                                {row.curl_example && 
                                    <div className="single-code-block">
                                        <div className="block-head">
                                            <h6>Curl Example: </h6>

                                            <CopyToClipboard text={row.curl_example}
                                                onCopy={() => setCopied(true)}>
                                                <span className="copy-btn">Copy</span>
                                            </CopyToClipboard>
                                            
                                        </div>
                                        <pre>
                                            <code id="curlExample" dangerouslySetInnerHTML={{__html:row.curl_example}}></code>
                                        </pre>
                                    </div>
                                }

                                <h3>{row?.group_title}</h3>

                                {row?.data &&
                                    <div className="codeblock">
                                
                                    <Tabs defaultActiveKey="0" className="mt-3 codeblock-tabs">
                                    {row?.data?.map((row1: any,index1) => (
                                        <Tab key={index1} eventKey={index1} title={row1.key}>
                                            <CopyToClipboard text={row1.value}
                                                onCopy={() => setCopied(true)}>
                                                <span className="copy-btn">Copy</span>
                                            </CopyToClipboard>
                                            <pre>
                                                <code dangerouslySetInnerHTML={{__html:row1.value}}></code>
                                            </pre>
                                        </Tab>
                                    ))}
                                    </Tabs>
                                </div>
                                }

                                {row.after_content && 
                                    <div dangerouslySetInnerHTML={{__html:row.after_content}}></div>
                                }
                            </section>
                        ))}

                        

                        

                       
                    </ScrollSpy>
                </div>
                
            </div>
        </>
    )
}
export default SectionOne