import React, { useEffect, useRef, useState } from 'react'
import './../style.css'
import SectionOne from './parts/section-one'
import SectionFeatures from './parts/section-features'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import { API_BASE_URL } from '@utils/constant'
import data from './data.json'
// import BannerHome from '@components/banner-home'
import SectionMerchantExp from './parts/section-merchant-exp'
import ShopifyForm from './parts/section-form'
import ShopifyBanner from './parts/banner'
import { Helmet } from 'react-helmet'


const Shopify = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})
	/*const [bannerData, setBannerData] = useState<any>({})
	const [isLoadfadeSection, toggleFadeSection] = useState(false);*/

	const scrollToForm = () => {
		const reftp = document.getElementById("shopify-contact-form");
		reftp.scrollIntoView({ behavior: "smooth" });
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
		/*setTimeout(() => {
			toggleFadeSection(true);
		}, 3000);*/
	}, []);


	async function fetchData() {
		fetch(API_BASE_URL + 'page/home').then(
			function (response) {
				if (response.status !== 200) {
					//console.log('Looks like there was a problem. Status Code: ' +
					//response.status);
					return;
				}
				response.json().then(function (data) {
					//console.log(data);
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, []);

	return (
		<>
			{/* <Helmet >
				<title>India’s Best Checkout & RTO Solution On Shopify | GoKwik</title>
				<meta name="description" content="Shopify Checkout & RTO Protection Suite offering fastest one-click checkout solution with RTO Intelligence to uplift conversions, reduce RTO, and unlock growth" />
			</Helmet> */}
			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'India’s Best Checkout & RTO Solution On Shopify | GoKwik'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "Shopify Checkout & RTO Protection Suite offering fastest one-click checkout solution with RTO Intelligence to uplift conversions, reduce RTO, and unlock growth"}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>
			<ShopifyBanner data={data.banner} scrollToForm={scrollToForm} />


			<div className="product-section-one">
				<SectionMerchantExp group={data.merchant.group} blocks={data.merchant.blocks} scrollToForm={scrollToForm}></SectionMerchantExp>

				<SectionOne group={data.block1.group} blocks={data.block1.blocks}></SectionOne>

			</div>
			<div className="shopify-boost-conversion">
				<SectionFeatures group={data.block2.group} blocks={data.block2.blocks}></SectionFeatures>
				<ShopifyForm />
			</div>

			<SuccesStory customClass="" category={6}></SuccesStory>
			<SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>
		</>
	)
}
export default Shopify