import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import SectionOne from './parts/section-one'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import BannerFive from '@components/banner-five'
import data from "./data.json"
import { API_BASE_URL } from '@utils/constant'
import { title } from 'process'
import { Helmet } from "react-helmet"


const APIDocs = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})

	async function fetchData() {
		fetch(API_BASE_URL + 'page/apidoc').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, []);

	return (
		<>
			{/* <Helmet >
				<title>GoKwik API Documentation | GoKwik Docs</title>
				<meta name="description" content="Welcome to GoKwik API Docs. Find help about GoKwik products and and APIs to help you start using our products quickly." />
			</Helmet> */}
			<Helmet>
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'GoKwik API Documentation | GoKwik Docs'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : "Welcome to GoKwik API Docs. Find help about GoKwik products and and APIs to help you start using our products quickly."}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>
			{/* <BannerFive data={{ title: "Coming Soon ..." }}></BannerFive> */}
			<BannerFive data={result?.page?.banner}></BannerFive>

			<SectionOne apidocs={result?.page?.apidocs}></SectionOne>
			<SuccesStory customClass=""></SuccesStory>
			<SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>

		</>
	)
}
export default APIDocs