import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Fade, Slide,Zoom } from "react-awesome-reveal";
import Slider from "react-slick";
import SectionThree from "./section-three";
import $ from 'jquery';
import BlurryLoadingImage from "@components/lazyimage";





const SectionTwo: React.FC<any> = (props)=> {
    const {id,data,group,blocks,achievements} = props;

    function changeSlider(sid,current,nextt){
        const prev = $('#'+sid+' .slick-slide[data-index="'+(nextt-1)+'"] .box-slider'); 
        const next = $('#'+sid+' .slick-slide[data-index="'+(nextt+1)+'"] .box-slider');
        $('#'+sid+' .slick-slide .box-slider').removeClass('transform');
        prev.addClass("transform");
        next.addClass("transform");
    }
    
    const settings = {
        autoplay: true,
        autoplaySpeed: 5000,
        centerMode: true,
        infinite: true,
        centerPadding: "0px",
        slidesToShow: 5,
        slidesToScroll: 1,
        speed: 500,
        //afterChange: (current) => changeSlider(current),
        beforeChange: (current,next) => changeSlider(id,current,next),
        responsive: [
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 3.2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,
                    dots: false,
                }
            },
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 2.2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,
                    dots: false,
                }
            },
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    infinite: true,
                    dots: false,
                }
            },
        ]
      };

      
    useEffect(() => {
        changeSlider(id,0,0)
    },[blocks]);


    return (
        <>
                <div className="container-fluid">
                    <div className="col-12 inner">
                            <Fade direction="down">
                                <h2 className="section-heading" dangerouslySetInnerHTML={{__html:group?.title}}></h2>
                                <p className="section-desc">{group?.description}</p>
                            </Fade>
                    </div>
                </div>
            
                <div className="slider2" id={id}>
            
                <Slider {...settings}>

                    {blocks?.map((row: any, index) => (
                        <div className="box-slider">
                            <div className="item">
                                <Zoom>
                                    <BlurryLoadingImage preview={row?.file} image={row?.file} alt={row?.title}></BlurryLoadingImage>
                                    {/* <img src={row.file} alt={row.title}/> */}
                                </Zoom>
                                
                                <p>{row.title}</p>
                            </div>
                        </div>
                    ))}  
                </Slider>
                </div>
        </>
    )
}
export default SectionTwo