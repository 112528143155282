import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";
import BlurryLoadingImage from "@components/lazyimage";

const ShopifyBanner: React.FC<any> = ({ data, scrollToForm }) => {

    return (
        <>
            <div className="banner-home banner-two">
                <div className="container-fluid">

                    <Slide direction="left" delay={10} triggerOnce>

                        <div className="row display-flex">
                            <Slide direction="left" delay={100} className="col-md-6" triggerOnce>
                                <div className="col-12 left">
                                    <Fade cascade damping={0.5}>
                                        {data?.sub_title &&
                                            <p className="small">{data?.sub_title}</p>
                                        }
                                        <img src={data?.banner_image} alt="Shopify Banner" />
                                        <p className="home-p shopbannerdesp">{data?.description}</p>
                                        <Link to="#" onClick={scrollToForm}>
                                            <button className="button-cta">I Am Interested</button>
                                        </Link>
                                    </Fade>
                                </div>
                            </Slide>
                        </div>

                    </Slide>
                </div>

                {data &&
                    <Fade direction='down' delay={1000} className="col-md-6 fixed-right-img">
                        <BlurryLoadingImage preview={data?.file} image={data?.file} alt={data?.title}></BlurryLoadingImage>
                        {/* <img src={data?.file} width="100%" alt="" style={{ width: "100%" }} />  */}
                    </Fade>
                }
            </div>
        </>
    )
}
export default ShopifyBanner