import React from 'react'
const SectionFour: React.FC<any> = ({ scrollToForm }: any) => {
    return (
        <>   
            <div className="oneclick-shoppers-inner">
                <div className="col-12 ">
                    <h2 className="section-heading">Boost Conversions, Prepaid Wallet Share & Overall GMV With KwikCheckout</h2>
                    <a className='pointer'>
                        <button onClick={scrollToForm} className="button-cta animate">Get A Free Demo</button>
                    </a>
                </div>
            </div>
             <div className="oneclick-shoppers-section">
                <div className="container-fluid">
                
                <div className="col-12 slider">
                    <div className="box-slider">
                        <div className="item">
                            <div className="content">
                                <h4>40%</h4>
                                <p>CHECKOUT CONVERSION BOOST</p>
                            </div>
                            <div className="content">
                                <h4>40%</h4>
                                <p>RTO REDUCTION</p>
                            </div>
                            <div className="content">
                                <h4>25%</h4>
                                <p>PREPAID CONVERSION BOOST</p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </>
    )
}
export default SectionFour