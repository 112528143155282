import React, { useEffect, useState } from "react";
import ImageGallery from "react-image-gallery";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { API_BASE_URL } from "@utils/constant";

const images = [
    {
        original: "/assets/images/event/gallery/1.jpeg",
        thumbnail: "/assets/images/event/gallery/1.jpeg",
        description:"Image Description 1"
    },
    {
        original: "/assets/images/event/gallery/2.jpeg",
        thumbnail: "/assets/images/event/gallery/2.jpeg",
        description:"Image Description 2"
    },
    {
        original: "/assets/images/event/gallery/3.jpeg",
        thumbnail: "/assets/images/event/gallery/3.jpeg",
        description:"Image Description 3"
    },
    {
        original: "/assets/images/event/gallery/4.jpeg",
        thumbnail: "/assets/images/event/gallery/4.jpeg",
        description:"Image Description 4"
    },
    {
        original: "/assets/images/event/gallery/5.jpeg",
        thumbnail: "/assets/images/event/gallery/5.jpeg",
        description:"Image Description 5"
    },
    {
        original: "/assets/images/event/gallery/6.jpeg",
        thumbnail: "/assets/images/event/gallery/6.jpeg",
        description:"Image Description 6"
    },
    {
        original: "/assets/images/event/gallery/7.jpeg",
        thumbnail: "/assets/images/event/gallery/7.jpeg",
        description:"Image Description 7"
    }
];

export class EventGallery extends React.Component {
    render() {
        return <ImageGallery items={images} showBullets={true} showIndex={true} infinite={true}/>;
    }
}


export function EventGalleryModal(props) {
    const galleryId = props.galleryId;
    const handleClose = () => props.setShow(false);
    const handleShow = () => props.setShow(true);
    const [results,setResult] = useState<any>([]);

    async function fetchData(cat=0,p=1,q="") {

        let uri = API_BASE_URL+'event/gallery/'+galleryId;
        
		fetch(uri).then(
			function(response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function(data) {
					setResult(data);
				});
			}
		)
		.catch(function(err) {
			//console.log('Fetch Error :-S', err);
		});
	}

    useEffect(()=>{
        console.log("fetch data : ",galleryId)
        fetchData();
    },[galleryId])
    
    return (
        <>
            <Modal size="lg" 
            aria-labelledby="contained-modal-title-vcenter"
            centered show={props.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    
                </Modal.Header>
                <Modal.Dialog>
                    <ImageGallery items={results} showBullets={true} showIndex={true} infinite={true}/>
                </Modal.Dialog>
            </Modal>
            
        </>
    );
}