import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Fade, Slide,Zoom } from "react-awesome-reveal";


const SectionOne: React.FC<any> = (props)=> {
    const {data} = props;


    return (
        <>
            <div className="career-section-one">
                <div className="container-fluid">
                <Fade direction='down'>
                    <div className="col-12 items">

                        {data?.map((row: any, index) => (
                            <div className="item">
                                    <div className="leftbox">
                                    <Zoom>
                                        <img src={row.file} alt={row.title}/>
                                    </Zoom>
                                    </div>
                                    <div className="rightbox">
                                        <Fade cascade damping={0.5}>
                                            <h4>{row.title}</h4>
                                            <span>{row.content}</span>
                                        </Fade>
                                    </div>
                            </div>
                        ))}  
                        
                    </div>
                </Fade>
                </div>
            </div>
        </>
    )
}
export default SectionOne