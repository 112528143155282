import React from "react";
import { Fade} from "react-awesome-reveal";


const SectionTwelve: React.FC<any> = () => {

    return (
        <>

            <div className="merchants-section-twelve">
                   {/* <img src="/assets/images/kwikcheckout/checkout-conversion.svg" /> */}

                <div className="kwik-adv">
                    <div className="kwik-content">
                        <h4>Supercharge your D2C business</h4>
                        <p>with one-click checkout solution actually converts </p>
                    </div>
                    <a href="/contact" target="_blank" data-faitracker-form-bind="true">Get Your FREE Demo</a>
                </div>

            </div>
        </>
    )
}
export default SectionTwelve