import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { scrollinit } from "@utils/helper";
import ResponsiveYoutube from "../youtube";
import parse from 'html-react-parser';

import {
	FacebookShareButton,
	InstapaperShareButton,
	LinkedinShareButton,
	TwitterShareButton
  } from "react-share";


const replace = (domNode) => {
    //console.log("don : ",domNode)
    if (domNode.name === "youtube") {
      return (
        <ResponsiveYoutube id={domNode.attributes[0]?.value}></ResponsiveYoutube>
      );
    }
  };


const SectionThree: React.FC<any> = (props)=> {
    const {data} = props;

    let content1;
    const share_url = window.location.href;

    if(data?.content1 != undefined){
       
        const reg = /\[youtube id=.*\]/
        const temp = data.content1.match(reg);
        if(temp != null){
            const new_youtube = temp[0].replace("[", "<").replace("]", ">");
            data.content1 = data.content1.replace(temp[0],new_youtube);
        }
        content1 = parse(data.content1, { replace })
    }

    return (
        <>
            <div className="success-story-details-two">
                <div className="container-fluid">

                    <div className="row col-12 container">
                        <div className="col-12 col-md-9 container success-story-content">
                        {content1 && content1}


                        <div className="col-12 success-story-share">
                            <p>Enjoying this article? Share it with the world!</p>
                            <ul className="social">
                                <li>
                                    <FacebookShareButton 
                                    url={share_url}
                                    title={data?.title}>
                                    <i className="fa-brands fa-square-facebook"></i>
                                    </FacebookShareButton>
                                </li>

                                {/* <li>
                                    <InstapaperShareButton
                                        url={share_url}
                                        title={data?.title}
                                    >
                                        <i className="fa-brands fa-instagram"></i>
                                    </InstapaperShareButton>
                                </li> */}

                                <li>
                                    <LinkedinShareButton
                                        url={share_url}
                                        title={data?.title}
                                    >
                                        <i className="fa-brands fa-linkedin"></i>
                                    </LinkedinShareButton>
                                </li>

                                <li>
                                    <TwitterShareButton
                                        url={share_url}
                                        title={data?.title}
                                    >
                                        <i className="fa-brands fa-x-twitter"></i>
                                    </TwitterShareButton>
                                </li>
                            </ul>
                        </div>
                        
                        </div>





                        <div className="col-12 col-md-3">
                           
                        </div>

                    </div>


                </div>
            </div>
        </>
    )
}
export default SectionThree