import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Fade, Zoom } from "react-awesome-reveal";
import KAISection from "./kai-section";
import {Tab,Tabs,Accordion} from 'react-bootstrap';
import data from '../data.json'
import faqs from '../faq.json'

const SectionWorks: React.FC<any> = (props)=> {
    const {group,blocks} = props;
   
    return (
        <>

            
            <div className="product-faq-section">
                <div className="container-fluid wrapper">
                     
                    <div className="col-12 section-header">
                        <Fade direction="down">
                          
                            <h3>Frequently Asked Questions</h3>
                        </Fade>
                    </div>

                    <div className="col-12 faq-tab">
                                <Accordion defaultActiveKey="0">
                                 {faqs?.map((row1: any,index1: never) => (
                                    <Accordion.Item eventKey={index1}>
                                        <Accordion.Header>{row1.question}</Accordion.Header>
                                        <Accordion.Body dangerouslySetInnerHTML={{ __html: row1?.answer }}>
                                        
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                                </Accordion>
                    </div>

                </div>
            </div>

            {/* <div className="product-cod-kai">
                <div className="container-fluid">
                     
                    <div className="col-12 section-header">
                        <Fade direction="down">
                            <div dangerouslySetInnerHTML={{__html:group?.description}}></div>
                           
                        </Fade>
                    </div>

                   

                    <div className="col-12 wrapper">
                        <div className="col-12 section-header">
                            <Fade direction="down">
                                <h3 dangerouslySetInnerHTML={{ __html: group?.title }}></h3>
                            </Fade>
                        </div>

                        <div className="col-12 items flex">
                            <div className="col-12 col-md-6">
                                <KAISection data={blocks}></KAISection>
                            </div>

                        <div className="col-12 col-md-6" id="kai-screens">
                            <div className={"img-wrapper"}>

                                <img src="/assets/images/product/Frame.svg" alt={"Frame"} className="frame"/>
                               
                                {blocks?.map((row:any , index) => (
                                    <div className={(index === 0)?"img-frame kai-img-frame active":"img-frame kai-img-frame"} id={"kaiitem"+index+"-frame"}>
                                        <img src={row.file} alt={row.title} />
                                    </div>
                                ))}
                            </div>

                        </div>

                        </div>
                        
                    </div> 
                </div>
            </div> */}
            </>
    )
}
export default SectionWorks