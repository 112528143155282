import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";


const SectionTwo: React.FC<any> = ({ group, blocks })=> {
 
    return (
        <>
           
                <div className="container-fluid">

                    <div className="col-12 section-header">
                        <Fade direction="down">
                            <h3 dangerouslySetInnerHTML={{ __html: group?.title }}></h3>
                        </Fade>
                    </div>
                    <div className="col-12 text-center mb-4">
                    <img src="/assets/images/product/kwikchat/advantage-tree.svg"/>
                    </div>

                    <div className="row row-cols-1 row-cols-md-2 mx-4 items secound">
                        <Fade cascade damping={0.3}>
                        {blocks?.map((row: any, index) => (
                            <div className="col">
                                <div className="item">
                                    <div className="img-wrapper">
                                        <img src={row.file} alt={row.title}/>
                                    </div>
                                    <div className="rightbox">
                                        <Fade cascade damping={0.5}>
                                            <h4>{row.title}</h4>
                                            <p>{row.content}</p>
                                        </Fade>
                                    </div>
                                </div>
                            </div>
                        ))} 
                        </Fade>
                    </div>
                    



                </div>
           
            </>
    )
}
export default SectionTwo