import React, { useEffect, useRef, useState } from 'react'
import './style.css'
import SectionOne from './parts/section-one'
//import SectionOne from './parts/section-one copy'
import SuperChargedBusiness from '@components/supercharged-business'
import BannerFive from '@components/banner-five'
import data from "./data.json"
import { API_BASE_URL } from '@utils/constant'
import $ from 'jquery';
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'

const APIDocDetails = () => {
	const wasCalled = useRef(false);
	const { slug } = useParams()
	const [result, setResult] = useState<any>({})
	const [article, setArticle] = useState<any>(null)

	async function fetchData() {
		fetch(API_BASE_URL + 'page/contact').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	async function fetchArticle() {
		fetch(API_BASE_URL + 'api-docs/' + slug).then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setArticle(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	// useEffect(() => {
	// 	document.getElementById("header").classList.add("header-fixed");
	// 	return()=>{
	// 		document.getElementById("header").classList.remove("header-fixed");
	// 	}
	// }, []);

	/*useEffect(() => {
		$(window).scroll(function () {
			const top = $("body").offset().top;
			const bottomTop = $(".supercharge-business").offset().top;

			const scrollTop = $(this).scrollTop();

			if (scrollTop > 10 && scrollTop < bottomTop - 600) {
				$('body').addClass('fixed').slideDown();
			} else {
				$('body').removeClass('fixed').slideDown();
			}
		});
		return () => {
			$(window).off('scroll')
		}
	}, []);*/

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
		fetchArticle();
	}, []);

	return (
		<>
			{
				(article && article.meta_title) &&
				<Helmet>
					{article.meta_title && <title>
						{article.meta_title}
					</title>
					}
					{article.meta_description && <meta name="description" content={article.meta_description} />}
					{article.meta_keyword && <meta name="keywords" content={article.meta_keyword}></meta>}
				</Helmet>
			}
			<div className="api-container">
				<SectionOne data={data} article={article}></SectionOne>
			</div>
			<SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>
		</>
	)
}
export default APIDocDetails