import React, { useEffect, useRef, useState } from 'react'
import './style.css'

import SectionOne from './parts/section-one'
import SectionTwo from './parts/section-two'
import SectionFour from './parts/section-four'
import SectionThree from './parts/section-three'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import BannerOne from '@components/banner-one'
import data from "./data.json"
import { API_BASE_URL } from '@utils/constant'
import { Helmet } from "react-helmet";


const About = () => {
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})

	async function fetchData() {
		fetch(API_BASE_URL + 'page/about').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, []);

	return (
		<>
			<Helmet >
				{/* <title>About GoKwik & The Team Behind It</title>
				<meta name="description" content="GoKwik is a leading eCommerce enabler that builds effective solution for eCommerce brands to help them increase conversions, reduce RTO, and unlock 10X growth." /> */}
				<title>
					{result?.page?.meta_title ? result?.page?.meta_title : 'About GoKwik & The Team Behind It'}
				</title>
				<meta name="description" content={`${result?.page?.meta_description ? result?.page?.meta_description : 'GoKwik is a leading eCommerce enabler that builds effective solution for eCommerce brands to help them increase conversions, reduce RTO, and unlock 10X growth.'}`} />
				{
					result?.page?.meta_keyword &&
					<meta name="keywords" content={result?.page?.meta_keyword}></meta>
				}
			</Helmet>
			<BannerOne data={result?.page?.banner}></BannerOne>
			<SectionOne data={result?.blocks?.block1}></SectionOne>
			<SectionTwo group={result?.groups?.block2} blocks={result?.blocks} achievements={result?.page?.achievements}></SectionTwo>

			<SectionFour group={result?.groups?.block4} blocks={result?.blocks?.block4} teams={result?.page?.teams}></SectionFour>
			<SuccesStory customClass=""></SuccesStory>
			<SuperChargedBusiness group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>
		</>
	)
}
export default About