import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";

const SectionOne: React.FC<any> = (props)=> {
    const {data} = props;

    return (
        <>
            <div className="page404-section-one banner-home banner-five">
                <div className="container-fluid">


                        <div className="row display-flex">
                           
                                <div className="col-12">
                                    <h1>
                                        <span>404 Error</span>
                                        <br/>Page not found!
                                    </h1>
                                </div>
                        </div>

                </div>
            </div>
        </>
    )
}
export default SectionOne