import React, { useEffect, useRef, useState } from 'react'
import './../style.css'
import SectionDetails from './section-details'
import SuccesStory from '@components/succes-story'
import SuperChargedBusiness from '@components/supercharged-business'
import data from "./../data.json"
import { API_BASE_URL } from '@utils/constant'
import { useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet'




const BlogPageDetails = () => {
	const { slug } = useParams()
	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>({})
	const [article, setArticle] = useState<any>({})

	async function fetchData() {
		fetch(API_BASE_URL + 'page/blog').then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					//console.log(data);
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	async function fetchArticle() {
		fetch(API_BASE_URL + 'blog/' + slug).then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setArticle(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}


	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
		fetchArticle();
	}, []);

	return (
		<>
			{
				(article && article.meta_title) &&
				<Helmet>
					{article.meta_title && <title>
						{article.meta_title}
					</title>
					}
					{article.meta_description && <meta name="description" content={article.meta_description} />}
					{article.meta_keyword && <meta name="keywords" content={article.meta_keyword}></meta>}
				</Helmet>
			}
			<SectionDetails data={article}></SectionDetails>
			<SuccesStory customClass=""></SuccesStory>
			<SuperChargedBusiness subscribe={true} subscribePage={`Blog: ${article?.title}`} group={result?.groups?.block8} blocks={result?.blocks?.block8}></SuperChargedBusiness>

		</>
	)
}
export default BlogPageDetails