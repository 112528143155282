import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";
import "./style.css"
import BlurryLoadingImage from "@components/lazyimage";

const BannerTwo: React.FC<any> = ({data}) => {


    return (
        <>
            <div className="banner-home banner-two">
                <div className="container-fluid">

                    <Slide direction="left" delay={10} triggerOnce>

                        <div className="row display-flex">
                            <Slide direction="left" delay={100} className="col-md-6" triggerOnce>
                                <div className="col-12 left">
                                    <Fade cascade damping={0.5}>
                                        {data?.sub_title && 
                                            <p className="small">{data?.sub_title}</p>
                                        }
                                        <h1 dangerouslySetInnerHTML={{ __html: data?.title }}></h1>
                                        <p className="home-p">{data?.description}</p>
                                        {data?.uri && 
                                            <Link to={data?.uri}>
                                                <button className="button-cta">{data?.link}</button>
                                            </Link>
                                        }
                                    </Fade>
                                </div>
                            </Slide>
                        </div>

                    </Slide>
                </div>
                
                {data && 
                <Fade direction='down' delay={1000} className="col-md-6 fixed-right-img">
                    <BlurryLoadingImage preview={data?.file} image={data?.file} alt={data?.title}></BlurryLoadingImage>
                    {/* <img src={data?.file} width="100%" alt="" style={{ width: "100%" }} />  */}
                </Fade>
                }
            </div>
        </>
    )
}
export default BannerTwo