import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";


const SectionSide: React.FC<any> = ({ group, blocks, sectionClass })=> {
  

    return (
        <>
               <div className="container-fluid">



                    <div className="product-stats grievance-adv">
                        <Fade direction='down'>
                            <div className="col-12 items">
                        
                            <Fade cascade damping={0.3}>
                                    <div className="rightsideb">
                                {blocks?.map((row: any, index) => (
                                        <Fade cascade damping={0.5}>
                                            <a href={row.id}>
                                                <p>{row.link}</p>
                                            </a>
                                        </Fade>
                                ))} 
                                    </div>
                            </Fade>
                            
                            </div>
                        </Fade>
                    </div>
                    <a href="#start-reading"><div className="table-button"><p className="text-d-grey fs12 fw400 grievance-heading padding-b10">Back to Top</p></div></a>

                    </div>
            
            </>
    )
}
export default SectionSide