import React, { useEffect, useState } from 'react'
import Header from '@components/shared/header/Header'
import Footer from '@components/shared/footer/Footer'
import { useLocation } from 'react-router-dom';
//import { LineWave } from 'react-loader-spinner'

type MainLayoutProps = {
	children: React.ReactNode;
}

const MainLayout = (props) => {
	const { children } = props
	const [isLoadfadeSection, toggleFadeSection] = useState(false);
	const location = useLocation();


	useEffect(() => {
		window.process = {
			...window.process,
		};
		toggleFadeSection(false);
		setTimeout(() => {
			toggleFadeSection(true)
		}, 3000);
	}, [location]);

	return (
		<>
			{
				(location.pathname !== "/kwikcheckout" && location.pathname !== "/oneclickcheckout") && <Header location="{pathname:'/'}" />
			}
			<>
				{/* <div className='main'> */}
				{children}
				{/* </div> */}
			</>
			{
				isLoadfadeSection &&
				(location.pathname !== "/oneclickcheckout") && <Footer />
			}

		</>
	)
}
export default MainLayout