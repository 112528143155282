import React, { useEffect, useState } from "react";
import Reveal from "react-awesome-reveal";
import Slider from "react-slick";
import { Fade, Slide } from "react-awesome-reveal";
import { Link } from "react-router-dom";
import "./style.css"
import BlurryLoadingImage from "@components/lazyimage";
import AnimationBanner from "./animation-banner";
import ThankYou from "./thank-you";
import KwikChat from "./kwikchat";
import Hundred from "./hundred";
import { stripHtml } from "@utils/helper";


const BannerHome: React.FC<any> = ({ data, arrow = true, animateBanner = false }) => {

    const [reAnimate, setReAnimate] = useState(false);

    const [startSlide, setStartSlide] = useState(false);

    const [delay, setDelay] = useState(1500)
    setTimeout(function () {
        setDelay(0);
        setStartSlide(true)
    }, 0);


    function changeSlider(current) {
        setReAnimate(!reAnimate);
    }

    const settings = {
        autoplay: true,
        autoplaySpeed: 5000,
        fade: false,
        slide: 'div',
        cssEase: 'linear',
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0,
        rtl: false,
        afterChange: (current) => changeSlider(current),
    }

    return (
        <>
            <div className="banner-home">
                <div className="container-fluid">
                    {arrow &&
                        <div className="banner-arrow">
                            <img src="/assets/images/arrow.png" className="arrow" alt="Arrow" width="300" height="320" />
                        </div>
                    }

                    {
                        startSlide && <Slide direction="left" delay={delay} triggerOnce>
                            <Slider {...settings}>


                                {data?.map((row: any, index) => (

                                    <div key={row.id} className={`row display-flex ${row.title === "KwikCheckout App With Smart COD Suite" ? "kwickcheckoutappbanner" : ""}`}>
                                        <div className="col-sm-12 col-md-6 left">
                                            {/* <Fade cascade damping={0.5}> */}
                                            {row.sub_title &&
                                                <p className="small">{row.sub_title}</p>
                                            }
                                            {
                                                row.title === "KwikCheckout App With Smart COD Suite" ? <h1 className="smart-codh1" style={{ fontSize: '32px !important' }} dangerouslySetInnerHTML={{ __html: row.title }}></h1>
                                                    : <h1 dangerouslySetInnerHTML={{ __html: row.title }}></h1>
                                            }
                                            {/* <h1 dangerouslySetInnerHTML={{ __html: row.title }}></h1> */}
                                            <p className="home-p">{row.description}</p>

                                            <Link to={row?.cta}>
                                                <button className="button-cta">{row.cta_text}</button>
                                            </Link>
                                            {/* </Fade> */}

                                        </div>


                                        {(delay === 0 && animateBanner && (index === 0)) ? (
                                            <div className="col-sm-12 col-md-6">
                                                {index === 0 &&
                                                    <AnimationBanner reAnimate={reAnimate}></AnimationBanner>
                                                }
                                                {/* {index===1 && 
                                            <ThankYou reAnimate={reAnimate}></ThankYou>
                                        }
                                        {index===2 && 
                                            
                                            <Hundred></Hundred>
                                        } */}
                                            </div>
                                        ) : (
                                            <Fade direction="down" delay={delay + 500} triggerOnce className="col-sm-12 col-md-6">
                                                {
                                                    row?.cta === "/kwikcheckoutapp" ?
                                                        <Link to={row?.cta}>
                                                            <img src={row.file} alt={stripHtml(row.title)} />
                                                        </Link>
                                                        :
                                                        <img src={row.file} alt={stripHtml(row.title)} />
                                                }
                                                {/* <img src={row.file} alt={stripHtml(row.title)} /> */}
                                            </Fade>
                                        )}
                                    </div>

                                ))}

                            </Slider>
                        </Slide>
                    }


                </div>
            </div>
        </>
    )
}
export default BannerHome