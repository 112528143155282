import React from "react";
import { Fade} from "react-awesome-reveal";


const SectionEleven: React.FC<any> = () => {

    return (
        <>

            <div className="merchants-section-six">
                <div className="container-fluid">
                    <div className="col-12 inner">
                        <Fade direction="down">
                            <h2 className="merchants-heading">Here's what our merchants have to say about <span>KwikCheckout</span></h2>
                            
                        </Fade>
                    </div>
                </div>


                <div className="merchants">
                    <div className="col-12 pt-3">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                <div className="about-box">

                                    <div className="box-img" >
                                        <img src="/assets/images/kwikcheckout/nee.png" width="100" />
                                    </div>

                                    <div className="flex-between">
                                        <div className="profile-desp">
                                            <p>Thanks to GoKwik's KwikCheckout, we were successfully able to increase our prepraid conversion share by 30%. Not only this, our RTO was reduced from 22.44% to 9.93% in just 4 months!</p>
                                        </div>
                                         <div className="jhon">
                                                <h5>Taran Chhabra</h5>
                                                <h5>Co-founder,</h5>
                                                <h5>Neeman's</h5>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                <div className="about-box">

                                    <div className="box-img" >
                                        <img src="/assets/images/kwikcheckout/saadaa.png" width="150" />
                                    </div>

                                    <div className="flex-between">
                                        <div className="profile-desp">
                                            <p>With GoKwik's KwikCheckout onboard, the whole numbers have inverted. While earlier there was 70% COD and 30% prepaid orders, now we are doing 65% prepaid and 35% COD orders. Our RTO rate has also reduced significantly and we saw 3X growth in COD GMV.</p>
                                        </div>
                                         <div className="jhon">
                                                <h5>Akshay Shivpuri</h5>
                                                <h5>Co-founder,</h5>
                                                <h5>SAADAA</h5>
                                            </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                                <div className="about-box">

                                    <div className="box-img" >
                                        <img src="/assets/images/kwikcheckout/pilgrim.png" width="150" />
                                    </div>

                                    <div className="flex-between">
                                        <div className="profile-desp">
                                            <p>Thanks to KwikCheckout, our Pilgrim’s Birthday Flash Sale went smoothly. We clocked in about 75000+ orders in 5 days, almost double the numbers as compared to our April Flash Sale. Thanks to the GoKwik team</p>
                                        </div>
                                         <div className="jhon">
                                                <h5>Pritish Vartak,</h5>
                                                <h5>AGM, Growth Marketing,</h5>
                                                <h5>Pilgrim</h5>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="kwik-adv">
                    <div className="kwik-content">
                        <h4>Supercharge your Shopify D2C business</h4>
                        <p>with one-click checkout solution that actually converts</p>
                    </div>
                    <a href="/contact" target="_blank" data-faitracker-form-bind="true">Get Your FREE Demo</a>
                </div>

            </div>
        </>
    )
}
export default SectionEleven