import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";


const SectionOne: React.FC<any> = ({ group, blocks })=> {
  
    return (
        <>
            <div className="product-section-one">
                <div className="container-fluid">

                    <div className="col-12 section-header">
                        <Fade direction="down">
                            <h3 dangerouslySetInnerHTML={{ __html: group?.title }}></h3>
                            <p>{group?.description}</p>
                        </Fade>
                    </div>
                   

                    <div className="product-stats">
                        <Fade direction='down'>
                        <div className="col-12 items">
                       
                        <Fade cascade damping={0.3}>
                        {blocks?.map((row: any, index) => (
                            <div className="item">
                                <div className="leftbox">
                                    <img src={row.file} alt={row.title}/>
                                </div>
                                <div className="rightbox">
                                    <Fade cascade damping={0.5}>
                                        <h4>{row.title}</h4>
                                        <span>{row.content}</span>
                                    </Fade>
                                </div>
                            </div>
                        ))}  
                        </Fade>
                        
                        </div>
                        </Fade>
                    </div>


                </div>
            </div>
            </>
    )
}
export default SectionOne