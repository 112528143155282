import React,{useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { Fade, Slide,Zoom } from "react-awesome-reveal";
import Slider from "react-slick";
import SectionThree from "./section-three";
import $ from 'jquery';
import BlurryLoadingImage from "@components/lazyimage";





const SectionTwo: React.FC<any> = ()=> {
    return (
        <>
            <div className="kcheckout-client">
                <div className="container-fluid">
                    <div className="css-n5xi40">
                        <div className="row">
                            <div className="col-md-12 client_box">
                                <div className="css-n5xi40 heading">
                                    <h5>One-click checkout solution</h5>
                                    <h2>Trusted by India's best-selling D2C brands</h2>
                                </div>
                                <div className="grid">
                                    <div className="css-xl4503" >
                                        <div className="">
                                            <img src="/assets/images/kwikcheckout/plam.svg"/>
                                        </div>
                                    </div>
                                    <div className="css-y5zeoq">
                                        <div className="">
                                            <img src="/assets/images/kwikcheckout/boat.svg"/>
                                        </div>
                                    </div>
                                    <div className="css-xl4503">
                                        <div className="">
                                            <img src="/assets/images/kwikcheckout/good-glamm.svg"/>
                                        </div>
                                    </div>
                                    <div className="css-y5zeoq">
                                        <div className="">
                                            <img src="/assets/images/kwikcheckout/fire-boltt.svg"/>
                                        </div>
                                    </div>
                                    <div className="css-xl4503">
                                        <div className="">
                                            <img src="/assets/images/kwikcheckout/neemans.svg"/>
                                        </div>
                                    </div>
                                    <div className="css-xl4503">
                                        <div className="">
                                            <img src="/assets/images/kwikcheckout/noise.svg"/>
                                        </div>
                                    </div>
                                    <div className="css-xl4503">
                                        <div className="">
                                            <img src="/assets/images/kwikcheckout/saadaa.svg"/>
                                        </div>
                                    </div>
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
               
        </>
    )
}
export default SectionTwo