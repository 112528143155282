import React, { useState, Component, Fragment, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { BrowserRouter as Router, Route, Link, useLocation, useMatch, useMatches, useLoaderData } from "react-router-dom";
import './Header.css'
import data from './data.json'
import { API_BASE_URL } from "@utils/constant";

const Header = props => {
	const { location } = props;
	const [navbarVisible, setNavbarVisible] = useState(false);
	const [expanded, setExpanded] = useState(false);

	const mobileToggle = () => {
		setNavbarVisible(!navbarVisible);
	}

	const mobileSubMenClose = () => {
		setNavbarVisible(false);
		setExpanded(false);
	}

	const wasCalled = useRef(false);
	const [result, setResult] = useState<any>(data)

	async function fetchData(cat = 0, p = 1, q = "") {

		let uri = API_BASE_URL + 'menu/header'
		if (q != "") {
			const query = new URLSearchParams({ q: q }).toString();
			uri = uri + "?" + query
		}

		fetch(uri).then(
			function (response) {
				if (response.status !== 200) {
					return;
				}
				response.json().then(function (data) {
					setResult(data);
				});
			}
		)
			.catch(function (err) {
				console.log('Fetch Error :-S', err);
			});
	}

	useEffect(() => {
		if (wasCalled.current) return;
		wasCalled.current = true;
		fetchData();
	}, [result]);

	useEffect(() => {
		window.addEventListener('scroll', isSticky);
		return () => {
			window.removeEventListener('scroll', isSticky);
		};
	}, []);


	/* Method that will fix header after a specific scrollable */
	const isSticky = (e) => {
		const header = document.querySelector('.header');
		const scrollTop = window.scrollY;
		scrollTop >= 250 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
	};


	return (
		<>

			<header id="header" className="header d-flex align-items-center ">
				<div className="container-fluid d-flex align-items-center justify-content-between">

					<Link to="/" className="logo d-flex align-items-center me-auto me-lg-0">
						<img src="/assets/images/logo@2x.png" width="137" height="40" alt="GoKwik" />
					</Link>

					<nav id="navbar" className={(navbarVisible ? "navbar show" : "navbar")}>

						<ul>
							{result?.map((row: any, index) => (
								(row.submenu) ? (
									<li key={index} className="dropdown" onMouseOver={() => setExpanded(true)} onMouseOut={() => setExpanded(false)} >
										<Link to={window.location.pathname} className="active">{row.title}</Link>
										<ul className={(expanded) ? 'show' : ''} style={{ maxHeight: "auto" }}>
											{row.submenu?.map((row1: any, index1) => (
												<li key={index1}>
													<Link onClick={mobileSubMenClose} to={row1.cta} className="active">
														<div className="icon sprite" style={{ background: "url('" + row1.icon + "')" }}>
														</div>
														<h4>{row1.title}</h4>
													</Link>
												</li>
											))}
										</ul>
									</li>
								) : (
									<li key={index}><Link onClick={mobileSubMenClose} to={row.cta} className={`${row.cta === "/contact" ? "contact-active" : ""}`}>{row.title}</Link></li>
								)

							))}
						</ul>
					</nav>

					{navbarVisible ? (
						<i onClick={mobileToggle} className="mobile-nav-toggle  mobile-nav-hide fa-solid fa-xmark "></i>
					) : (
						<i onClick={mobileToggle} className="mobile-nav-toggle  mobile-nav-show  fa-solid fa-bars-staggered"></i>
					)}



				</div>
			</header >



		</>
	);
};

export default Header
