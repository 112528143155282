import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Zoom } from "react-awesome-reveal";


const SectionTwo: React.FC<any> = ({ group, blocks })=> {
   
    return (
        <>
            <div className="contact-section-two">
                <div className="container-fluid">

                    <div className="col-12 section-header">
                        <Fade direction="down">
                            <h3 dangerouslySetInnerHTML={{ __html: group?.title }}></h3>
                        </Fade>
                    </div>

                    <div className="col-12 items">
                    <Fade direction='down'>
                    {blocks?.map((row:any,index) => (
                        <div className="item">
                            <Zoom>
                                <img src={row.file} alt={row.title}/>
                            </Zoom>
                            <Fade cascade damping={0.3}>
                            <p className="title" dangerouslySetInnerHTML={{ __html: row.title }}></p>
                            <p className="description" dangerouslySetInnerHTML={{ __html: row.content }}></p>
                            <Link to={row?.cta}>{row?.cta_text}</Link>
                            </Fade>
                        </div>
                    ))}
                    </Fade>
                    </div>


                </div>
            </div>
        </>
    )
}
export default SectionTwo