import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";
import {Tab,Tabs,Accordion} from 'react-bootstrap';
import data from '../faq.json'




const SectionFive: React.FC<any> = ({ faqRef })=> {
    return (
        <>
            <div className="contact-section-three growth-faq" ref={faqRef}>
                <div className="container-fluid wrapper">

                    <div className="col-12 section-header">
                        <Fade direction="down">
                            <h3>Frequently Asked Questions</h3>
                        </Fade>
                    </div>

                    <div className="col-12 faq-tab">
                                <Accordion defaultActiveKey="0">
                                 {data?.map((row1: any,index1: never) => (
                                    <Accordion.Item eventKey={index1}>
                                        <Accordion.Header>{row1.question}</Accordion.Header>
                                        <Accordion.Body dangerouslySetInnerHTML={{ __html: row1?.answer }}>
                                        
                                        </Accordion.Body>
                                    </Accordion.Item>
                                ))}
                                </Accordion>
                    </div>
                </div>
            </div>
        </>
    )
}
export default SectionFive