import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import { Link } from "react-router-dom";
import { Fade, Slide } from "react-awesome-reveal";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { scrollinit } from "@utils/helper";
import parse from 'html-react-parser';
import { renderToString } from "react-dom/server";



const SectionOne: React.FC<any> = (props)=> {
    const {data} = props;
    
   
   
    return (
        <>
            <div className="blog-section-details check-privcy" style={{marginBottom:"-40px"}}>
                <div className="container-fluid">
                    <div className="col-12 blog-content" style={{paddingTop:"10px"}}>
                        
                        <h1 >Kwik COD - Terms and Conditions</h1>
                    
                       <div>
                            <p> Discover the flexibility of our KwikCOD app with transparent pricing plans tailored to your business needs. Whether you’re just starting or scaling up, we’ve got you covered with competitive rates and dedicated support.</p>
                            <h5>KwikCod App - Pricing Terms & Conditions</h5>
                            <h5>1. Basic/Shopify Plans</h5>
                            <p><b>Cash on Delivery (COD) Pricing:</b></p>
                            <ul>
                                <li><b>First 3 Months:</b> During the initial three months, you can process an unlimited number of COD orders at no extra cost. This allows you to test and optimize your COD strategy without worrying about additional fees.</li>
                                <li><b>After 3 Months:</b><ul>
                                    <li><b>Up to 1,000 Free COD Orders Per Month:</b> Post the first three months, you will continue to receive up to 1,000 COD orders per month free of charge.</li>
                                    <li><b>Additional COD Orders:</b> If your monthly COD orders exceed 1,000, a fee of 0.5% per COD order will be applicable. This fee is calculated based on the order value and will be charged automatically.</li>
                                </ul> </li>
                            </ul>
                            <p><b>Prepaid Pricing:</b></p>
                            <ul>
                                <li>For Checkout + Prepaid services, we offer customized pricing to suit your specific business needs. A member of our dedicated sales team will reach out to you to discuss and finalize the pricing structure that best aligns with your requirements.</li>
                                    
                            </ul>
                            <h5>2. Advanced/Plus Plans</h5>
                            <p><b>Cash on Delivery (COD) Pricing:</b></p>
                            <ul>
                                <li><b>First Month:</b> For the first month, enjoy unlimited free COD orders to support your business during its critical growth phase.</li>
                                <li><b>After 1 Month:</b><ul>
                                    <li>After the initial month, our sales team will connect with you to offer a pricing plan tailored to your business volume and needs, ensuring you continue to benefit from our services as you scale.</li>
                                   
                                </ul> </li>
                            </ul>
                            <p><b>Prepaid Pricing:</b></p>
                            <ul>
                                <li>Similar to the Basic/Shopify plans, our sales team will provide you with customized pricing for Checkout + Prepaid services, taking into account your business's unique needs.</li>
                                    
                            </ul>
                       </div>
                       <p className="borders">For further details or any inquiries regarding the Kwik COD app and its pricing plans, please do not hesitate to reach out to our support team at <a href="mailto:mybusiness@gokwik.co">mybusiness@gokwik.co.</a> We are here to assist you in making the most out of our services.</p>
                      
                        
                    </div>
                </div>
            </div>
        </>
    )
}
export default SectionOne